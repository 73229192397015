
<div class="modal-header">
    <h4 class="modal-title"><b>Eventos del empleado en el periodo</b></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    
    <ls-table  [dataTable]="tableData"></ls-table>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
</div>
