<!--begin::Brand-->
<div class="brand flex-column-auto" id="kt_brand" [ngClass]="brandClasses" style="text-align: center;">
  <!--begin::Logo-->
  <a routerLink="/" class="brand-logo" style="display: inline-block;">
    <img alt="Logo" style="width: 60%; max-width: 250px;" [attr.src]="headerLogo" />
  </a>
  <!--end::Logo-->

  <!-- <ng-container *ngIf="asideSelfMinimizeToggle">
    <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
      <span
        [inlineSVG]="
          './assets/media/svg/icons/Navigation/Angle-double-left.svg'
        "
        cacheSVG="true"
        class="svg-icon svg-icon-xl"
      ></span>
    </button>
  </ng-container> -->
</div>
<!--end::Brand-->

<!--begin::Aside Menu-->
<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
  <ng-container *ngIf="disableAsideSelfDisplay">
    <!--begin::Header Logo-->
    <a routerLink="/">
      <img alt="Logo" [attr.src]="headerLogo" />
    </a>
    <!--end::Header Logo-->
  </ng-container>
  <!--begin::Menu Container-->
  <div
    id="kt_aside_menu"
    class="aside-menu"
    [ngClass]="asideMenuCSSClasses"
    
    data-menu-vertical="1"
    [attr.data-menu-scroll]="asideMenuScroll"
    [attr.data-menu-dropdown]="asideMenuDropdown"
    data-menu-dropdown-timeout="500"
  >
    <!-- begin::Menu Nav -->
    <ul class="menu-nav" [ngClass]="ulCSSClasses">
      <!-- begin::1 Level -->
      <li 
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
        *ngIf="userService.getProfilesModule(['EMPLOYEE','GROUP_WORK'])"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/ica/employees.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Empleados</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Empleados</span>
              </span>
            </li>
            <!-- begin::2 Level -->
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
              *ngIf="userService.getProfilesModule(['EMPLOYEE'])"
            >
              <a
                class="menu-link"
                routerLink="/employees/employees"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Empleados</span>
              </a>
            </li>
            <li
            class="menu-item"
            routerLinkActive="menu-item-active"
            aria-haspopup="true"
            data-menu-toggle="hover"
            *ngIf="userService.getProfilesModule(['GROUP_WORK'])"
          >
            <a
              class="menu-link"
              routerLink="/employees/work_groups"
              routerLinkActive="active"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Grupos de trabajo</span>
            </a>
          </li>
            <!-- end::2 Level -->
            <!-- begin::2 Level -->
            <!-- <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/error/error-2"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Error 2</span>
              </a>
            </li> -->
            <!-- end::2 Level -->
          </ul>
        </div>
      </li>
      <!-- end::1 Level -->
      <!-- begin::1 Level -->
      <li
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
        *ngIf="userService.getProfilesModule(['APPROVE_ASSISTANCE','JUSTIFICATION','SHIFTS'])"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/ica/calendar.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Asistencia</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Horarios</span>
              </span>
            </li>
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
              *ngIf="userService.getProfilesModule(['APPROVE_ASSISTANCE'])"
            >
              <a
                class="menu-link"
                routerLink="/attendance/approve_attendance"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Aprobar asistencia</span>
              </a>
            </li>
            <!-- begin::2 Level -->
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
              *ngIf="userService.getProfilesModule(['SHIFTS'])"
             style="pointer-events: none; "
            >
              <a
                class="menu-link"
                routerLink="/attendance/schedule"
                routerLinkActive="active"
               
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span style="color: grey;" class="menu-text">Turnos</span>
              </a>
            </li>
            <!-- end::2 Level -->
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Justificantes</span>
              </span>
            </li>
            <!-- begin::2 Level -->
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
              *ngIf="userService.getProfilesModule(['JUSTIFICATION'])"
            >
              <a
                class="menu-link"
                routerLink="/attendance/exculpatory"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Justificantes</span>
              </a>
            </li>
            <!-- begin::2 Level -->
            <!-- <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/error/error-2"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Error 2</span>
              </a>
            </li> -->
            <!-- end::2 Level -->
          </ul>
        </div>
      </li>
      <li
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
        *ngIf="userService.getProfilesModule(['REQUEST_ADDITIONAL','REQUEST_TIME'])"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/ica/alert.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Solicitudes</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Solicitudes</span>
              </span>
            </li>
            <!-- begin::2 Level -->
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
              *ngIf="userService.getProfilesModule(['REQUEST_TIME'])"
            >
              <a
                class="menu-link"
                routerLink="/requests/time_request"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Solicitud de tiempo</span>
              </a>
            </li>
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
              *ngIf="userService.getProfilesModule(['REQUEST_ADDITIONAL'])"
            >
              <a
                class="menu-link"
                routerLink="/requests/aditional_request"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Solicitud adicional</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li
        class="menu-item"
        aria-haspopup="true"
        routerLinkActive="menu-item-active"
        *ngIf="userService.getProfilesModule(['TIME_SHEET'])"
      >
        <a class="menu-link" routerLink="/time_sheets/time_sheets" routerLinkActive="active">
          <span
            [inlineSVG]="'./assets/media/svg/ica/clock.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Hojas de tiempo</span>
        </a>
      </li>
      <li
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
        *ngIf="userService.getProfilesModule(['HISTORY_ASSISTANCE','HISTORY_AUTHORIZATIONS','HISTORY_EMPLOYEE','HISTORY_GROUP','HISTORY_TABULATOR'])"
        style="pointer-events: none; "
        >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/ica/list.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span style="color: grey;" class="menu-text">Historial</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" 
            aria-haspopup="true"

            >
              <span class="menu-link">
                <span class="menu-text">De empleado</span>
              </span>
            </li>
            <!-- begin::2 Level -->
            <!-- Begin employee record -->
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
              *ngIf="userService.getProfilesModule(['HISTORY_EMPLOYEE'])"
            >
              <a
                class="menu-link"
                routerLink="/record/employee_record"
                routerLinkActive="active"

              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">De empleado</span>
              </a>
            </li>
            <!-- End employee record -->
            <!--Begin Asist-->
          <li
            class="menu-item"
            routerLinkActive="menu-item-active"
            aria-haspopup="true"
            data-menu-toggle="hover"
            *ngIf="userService.getProfilesModule(['HISTORY_ASSISTANCE'])"
          >
            <a
              class="menu-link"
              routerLink="/record/assist_record"
              routerLinkActive="active"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">De asistencia</span>
            </a>
          </li>
          <!-- End Asist -->
          <!-- begin Auth -->
          <li
          class="menu-item"
          routerLinkActive="menu-item-active"
          aria-haspopup="true"
          data-menu-toggle="hover"
          *ngIf="userService.getProfilesModule(['HISTORY_AUTHORIZATIONS'])"
        >
          <a
            class="menu-link"
            routerLink="/record/auth_record"
            routerLinkActive="active"
          >
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">De autorizaciones</span>
          </a>
        </li>
          <!-- End Auth -->
          <!-- begin work group record -->
          <li
          class="menu-item"
          routerLinkActive="menu-item-active"
          aria-haspopup="true"
          data-menu-toggle="hover"
          *ngIf="userService.getProfilesModule(['HISTORY_GROUP'])"
        >
          <a
            class="menu-link"
            routerLink="/record/workgroup_record"
            routerLinkActive="active"
          >
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">De grupos de trabajo</span>
          </a>
        </li>
          <!-- End work group record -->
          <!--Begin Tab Record-->
          <li
          class="menu-item"
          routerLinkActive="menu-item-active"
          aria-haspopup="true"
          data-menu-toggle="hover"
          *ngIf="userService.getProfilesModule(['HISTORY_TABULATOR'])"
        >
          <a
            class="menu-link"
            routerLink="/record/tab_record"
            routerLinkActive="active"
          >
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">De tabulador</span>
          </a>
        </li>    
          <!--End Tab Record-->

            <!-- <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"

            >
              <a
                class="menu-link"
                routerLink="/error/error-2"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Error 2</span>
              </a>
            </li> -->
            <!-- end::2 Level -->
          </ul>
        </div>
      </li>

      <li
      class="menu-item menu-item-submenu"
      aria-haspopup="true"
      data-menu-toggle="hover"
      routerLinkActive="menu-item-open"
      *ngIf="userService.getProfilesModule(['ACCESS','CALENDR','AGREEMENT','EVENTS_VECTORS','PROFILE','PROJECT','USER'])"
    >
      <a
        class="menu-link menu-toggle"
        routerLinkActive="active"
        href="/settings"
      >
        <span
          [inlineSVG]="'./assets/media/svg/ica/gear.svg'"
          cacheSVG="true"
          class="svg-icon menu-icon"
        ></span>
        <span class="menu-text">Configuraciones</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">
          <li class="menu-item menu-item-parent" aria-haspopup="true">
            <span class="menu-link">
              <span class="menu-text">Configuraciones</span>
            </span>
          </li>
          <!-- begin::2 Level -->
         
          <!-- end::2 Level -->
          <li
            class="menu-item"
            routerLinkActive="menu-item-active"
            aria-haspopup="true"
            data-menu-toggle="hover"
            *ngIf="userService.getProfilesModule(['USER'])"
          >
            <a
              class="menu-link"
              routerLink="/settings/users"
              routerLinkActive="active"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Usuarios</span>
            </a>
          </li>
          <!-- begin::2 Level -->
          <li
            class="menu-item"
            routerLinkActive="menu-item-active"
            aria-haspopup="true"
            data-menu-toggle="hover"
            *ngIf="userService.getProfilesModule(['PROFILE'])"
          >
            <a
              class="menu-link"
              routerLink="/settings/profiles"
              routerLinkActive="active"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Perfiles</span>
            </a>
          </li>
          <!-- end::2 Level -->
          <!-- begin::2 Level -->
          <li
          class="menu-item"
          routerLinkActive="menu-item-active"
          aria-haspopup="true"
          data-menu-toggle="hover"
          *ngIf="userService.getProfilesModule(['PROJECT'])"
        >
          <a
            class="menu-link"
            routerLink="/settings/projects"
            routerLinkActive="active"
          >
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Proyectos</span>
          </a>
        </li>
        <!--Begin Convenios-->
        <li class="menu-item" routerLinkActive="menu-item-active"
         aria-haspopup="true"
          data-menu-toggle="hover"
          *ngIf="userService.getProfilesModule(['AGREEMENT'])"
          >
          <a class="menu-link" routerLink="/settings/agreement" routerLinkActive="active" >
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Convenios</span>
          </a>
        </li>
        <!--End Convenios-->
        <!--Begin Access-->
        <li
        class="menu-item"
        routerLinkActive="menu-item-active"
        aria-haspopup="true"
        data-menu-toggle="hover"
        *ngIf="userService.getProfilesModule(['ACCESS'])"
      >
        <a
          class="menu-link"
          routerLink="/settings/access"
          routerLinkActive="active"
        >
          <i class="menu-bullet menu-bullet-dot">
            <span></span>
          </i>
          <span class="menu-text">Accesos</span>
        </a>
      </li>
        <!--End Access-->
        <li
        class="menu-item"
        routerLinkActive="menu-item-active"
        aria-haspopup="true"
        data-menu-toggle="hover"
        *ngIf="userService.getProfilesModule(['CALENDR'])"
      >
        <a
          class="menu-link"
          routerLink="/settings/calendar"
          routerLinkActive="active"
        >
          <i class="menu-bullet menu-bullet-dot">
            <span></span>
          </i>
          <span class="menu-text">Calendario</span>
        </a>
      </li>
        <!--Begin Incidencias Y Vectores-->
        <li
        class="menu-item"
        routerLinkActive="menu-item-active"
        aria-haspopup="true"
        data-menu-toggle="hover"
        *ngIf="userService.getProfilesModule(['EVENTS_VECTORS'])"
      >
        <a
          class="menu-link"
          routerLink="/settings/inci_vec"
          routerLinkActive="active"
        >
          <i class="menu-bullet menu-bullet-dot">
            <span></span>
          </i>
          <span class="menu-text">Incidencias y Vectores</span>
        </a>
      </li>
        <!--End Incidencias Y Vectores-->
          <!-- end::2 Level -->

          <!-- Config Tabulador -->
          <li
            class="menu-item"
            routerLinkActive="menu-item-active"
            aria-haspopup="true"
            data-menu-toggle="hover"
            *ngIf="userService.getProfilesModule(['COLLECTIVE_AGREEMENT'])"
          >
            <a
              class="menu-link"
              routerLink="/settings/tabs"
              routerLinkActive="active"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Contrato Colectivo</span>
            </a>
          </li>
          <!-- end::2 Level -->
          <!-- begin::2 Level -->
         
          <!-- end::2 Level -->
          <!-- begin::2 Level -->
          
          <!-- end::2 Level -->
        </ul>
      </div>
    </li>
    <li
        class="menu-item menu-item-submenu"
        aria-haspopup="true"
        data-menu-toggle="hover"
        routerLinkActive="menu-item-open"
        *ngIf="userService.getProfilesModule(['ADMINISTRATION','INVENTORY'])"
      >
        <a
          class="menu-link menu-toggle"
          routerLinkActive="active"
          href="/error"
        >
          <span
            [inlineSVG]="'./assets/media/svg/ica/device.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Dispositivos</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <i class="menu-arrow"></i>
          <ul class="menu-subnav">
            <li class="menu-item menu-item-parent" aria-haspopup="true">
              <span class="menu-link">
                <span class="menu-text">Dispositivos</span>
              </span>
            </li>
            <!-- begin::2 Level -->
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
              *ngIf="userService.getProfilesModule(['INVENTORY'])"
            >
              <a
                class="menu-link"
                routerLink="/devices/inventory"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inventario</span>
              </a>
            </li>
            <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
              *ngIf="userService.getProfilesModule(['ADMINISTRATION'])"
            >
              <a
                class="menu-link"
                routerLink="/devices/admin"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Administración</span>
              </a>
            </li>
            <!-- end::2 Level -->
            <!-- begin::2 Level -->
            <!-- <li
              class="menu-item"
              routerLinkActive="menu-item-active"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                class="menu-link"
                routerLink="/error/error-2"
                routerLinkActive="active"
              >
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Error 2</span>
              </a>
            </li> -->
            <!-- end::2 Level -->
          </ul>
        </div>
      </li>


    </ul>
    <!-- end::Menu Nav -->
  </div>
  <!--end::Menu Container-->
</div>
<!--end::Aside Menu-->



