import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UsersService } from "../../../services/settings/users/users.service";
@Component({
  selector: 'ls-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit {
  // @Input() data: any;
  totales: number[] = [];
  indexStatic: number = 0;
  searchActual: string = '';
  @Input('dataTable')
  set dataTable(dataTable: any) {
    this.data = dataTable;
    //this.getInfo(dataTable);
    this.search(false);
  }
  @Output() actionsEvent = new EventEmitter<any>();
  @Output() actionsInputEvent = new EventEmitter<any>();

  @Input('searchVal')
  set searchVal(searchVal: string) {
    
    this.searchActual = searchVal;
    this.search(true);
  }

  @Input('updateData')
  set updateData(changeObject: any) {
    
    if(changeObject){
      this.updateDataValue(changeObject);
    }
  }

  @HostListener('window: resize', ['$event'])
  onResize(event){
    this.getInfo(this.data);
  }

  @Output() selectedItems = new EventEmitter<any>();
 
  data: any;
  selectRow:any;
  myStyle: SafeHtml;
  contentTable: any ='';
  widthItem: any = "0%";
  widthContainer: any = "0%";
  actualPage = 1;
  itemsPerPage = 10;
  buttonsPagination= [];
  tableData = [];
  loading = true;
  limit;
  totalPages;
  filterData: any;
  notFound = false;
  selectedItemsArray: any = [];
  dataAux:any;
  ban:any = 0;
  constructor(private _sanitizer: DomSanitizer,
              private _cdr: ChangeDetectorRef, public userService:UsersService) { 
                
  }


  updateTotales(){
 
    this.totales = [];
    
    if(this.data != undefined && this.data.data && this.data.data.length > 0){

      for(let i = 0;i < this.data.headers.length; i++){
        if(this.data.headers[i].visible == false){
          continue;
        }
        this.totales.push(0);
        if(this.data.headers[i].sum){
          
          this.data.data.forEach(element => {
              if(element[this.data.headers[i].key]){
                this.totales[this.totales.length-1] += +element[this.data.headers[i].key];
              }
          });
          this.totales[this.totales.length-1].toFixed(2);
        }
      }
      
      
      /*this.data.headers = data.headers.filter(item => item.visible == undefined || item.visible)
      this.filterData = data.data;
      this.createTable();
      this.createPages();*/
    }
  }


  getInfo(data){

    this.selectedItemsArray = [];
 
    this.totales = [];
    
    if(data != undefined && data.data && data.data.length > 0){
      this.indexStatic = 0;

      for(let i = 0;i < data.headers.length;i++){
        if(data.headers[i].static){
          this.indexStatic = i+1;
          break;
        }
      }

      this.updateTotales();

      /*for(let i = 0;i < data.headers.length; i++){
        
        if(data.headers[i].visible == false){
          continue;
        }
        this.totales.push(0);
        if(data.headers[i].sum){
          
          data.data.forEach(element => {
              if(element[data.headers[i].key]){
                this.totales[this.totales.length-1] += +element[data.headers[i].key];
              }
          });
          this.totales[this.totales.length-1].toFixed(2);
        }
      }*/
      
      
      this.data.headers = data.headers.filter(item => item.visible == undefined || item.visible)
      this.filterData = data.data;
      this.createTable();
      this.createPages();
    }
  }
  
  ngOnInit() {
    
  }

  createTable(){
    
    let totalTemp: number = 0;
    let totalColumsD: number = 0; 
    this.data.headers.forEach((element,index) => {
      if(element.width && index >= this.indexStatic ){
        totalTemp+=element.width;
        totalColumsD++;
      }
      
    });
      
      if(this.data.headers.length > this.data.limitCol){
        let tmpVal = Math.floor((100-totalTemp)/(this.data.headers.length-totalColumsD));
        this.widthItem = tmpVal.toString()+"%";
        
        let tmpVal2 = ((this.data.headers.length-totalColumsD)*20)+(totalTemp*3);
        this.widthContainer = tmpVal2.toString()+"%";
      }
      else{
        let tmpVal3 = (100-totalTemp)/(this.data.headers.length-totalColumsD);
        this.widthItem = tmpVal3.toString()+"%";
        this.widthContainer = "100%";
      }
    
  }

  tagBG(options, value){
    let result = options.filter(option => option.value === value);
    return result[0]?.colorBg;
  }

  tagText(options, value){
    let result = options.filter(option => option.value === value);
    return result[0]?.colorText;
  }

  tagValue(options, key, item){
    const value = item[key];
    let result = options.filter(option => option.value === value);
    if(result && result[0]){
      if(result[0].key){
        return item[result[0].key];
      }
      return result[0].text;
    }
    return '';
  }
  getHeights(data, actions){
    this.loading = true;
    this._cdr.detectChanges();
    document.getElementById('maskLoading').style.display = "block";
    if(actions && data && data.length){
      var header = document.getElementById("headersTable");
      if(header){
        if(document.getElementById('actionsHeader')){
          document.getElementById('actionsHeader').style.height = header.offsetHeight+"px";
        }

        if(document.getElementById('headersTableStatic')){
          document.getElementById('headersTableStatic').style.height = header.offsetHeight+"px";
        }
        //let totalheight = header.offsetHeight;
        for(let i = 0; i < data.length; i++){
          let rowElem = document.getElementById("row_"+i);
          let tmpElem = Number(rowElem.offsetHeight);
          document.getElementById('action'+this.actualPage+'_'+i).style.height = (rowElem.offsetHeight)+"px";
          document.getElementById("row_"+i).style.height = (rowElem.offsetHeight)+"px";
          if(document.getElementById("rowStatic_"+i)){
            document.getElementById("rowStatic_"+i).style.height = (rowElem.offsetHeight)+"px";
          }
          //totalheight += rowElem.offsetHeight;
        }
      }
      //document.getElementById('subContainerTable').style.height = totalheight+"px";

    }
    this.loading = false;
    document.getElementById('maskLoading').style.display = "none";
    this._cdr.detectChanges();
  }

  openDrop(index){
    let tmpVal;
    if(!this.tableData[this.actualPage-1][index].open){
      tmpVal = true;
    }
    else{
      tmpVal=false;
    }
    for(let i = 0; i<this.tableData.length; i++){
      for(let j = 0; j < this.tableData[i].length; j++){
        this.tableData[i][j].open = false;
      }
    }
    this.tableData[this.actualPage-1][index].open = tmpVal;
  }

  getTotalItems(val){
    if(val == 'Todos'){
      this.itemsPerPage = this.data.data.length;
    }
    else{
      this.itemsPerPage = val;
    }
    this.createPages();
    setTimeout( ()=>{this.getHeights(this.tableData[this.actualPage-1],this.data.actions)},1000);
  }

  eventActions(action, data, index){
    
    let dataEmit={action, data};
    this.tableData[this.actualPage-1][index].open = false;
    this.actionsEvent.next(dataEmit);
  }

  eventActionsInput(header, row,event){
    row.edit = true;
    let dataEmit={header, row, value:event.target.value};
    this.actionsInputEvent.next(dataEmit);
  }

  onFocusEvent(row: any){
    this.selectRow = row;
 }

  disabledInput(header,row): boolean {
    let disabled = false;
    
    if(header.disabled && header.disabled.key && row[header.disabled.key] == header.disabled.value){
      disabled = true;
    }

    return disabled;
  }



  updateDataValue(changeObject: any){
    changeObject.row[changeObject.header.key] += 1;
    this._cdr.detectChanges();
    changeObject.row[changeObject.header.key] -= 1;
    this._cdr.detectChanges();
    
    this.updateTotales();
  }

  createPages(){
    document.getElementById('maskLoading').style.display = "block";
    this.loading = true;
    let tmpArray = [];
    let page = [];
    let auxIndex = 1;
    for(let i = 0; i < this.filterData.length; i++){
      page.push(this.filterData[i]);
      if(auxIndex == this.itemsPerPage){
        tmpArray.push(page);
        auxIndex = 0;
        page=[];
      }
      else if(i == this.filterData.length-1){
        tmpArray.push(page);
      }
      auxIndex = auxIndex+1;
    }
    this.tableData = tmpArray;
    if(this.actualPage > this.tableData.length){
      this.actualPage = this.tableData.length;
    }
    //this.actualPage = 1;
    this.createButtons();
    setTimeout( ()=>{this.getHeights(this.tableData[this.actualPage-1],this.data.actions)},1000);
  }

  createButtons(){
    this.buttonsPagination= [];
    let tmpVal = this.filterData.length/this.itemsPerPage;
    if(this.filterData.length%this.itemsPerPage > 0){
      tmpVal = tmpVal+1;
    }
    tmpVal = Math.trunc(tmpVal);
    this.limit = this.actualPage+5;
    this.totalPages = tmpVal;
    let auxIndex
    if(this.actualPage == this.totalPages){
      if(this.totalPages%5 == 0){
        auxIndex = this.totalPages-5;
      }
      else{
        auxIndex = this.totalPages-(this.totalPages%5);
      }
    }
    else{
      auxIndex = this.actualPage;
    }
    if(auxIndex == 0){
      auxIndex = 1;
    }
    for(let i = auxIndex; i < this.limit; i++){
      if(i > tmpVal){
        break;
      }
      this.buttonsPagination.push(i);
    }
  }

  nextPage(){
    this.actualPage = this.actualPage+1;
    if(this.actualPage%5 == 0){
      this.createButtons();
    }
    this.getHeights(this.tableData[this.actualPage-1],this.data.actions);
  }

  prevPage(){
    
    this.actualPage = this.actualPage-1;
    this.createButtons();
    //Saqué el metodo del if para que pinte la posicion actual
    /*if((this.actualPage-1)%5 == 0){
      this.createButtons();
    }*/
    this.getHeights(this.tableData[this.actualPage-1],this.data.actions);
    this._cdr.detectChanges();
  }

  goToPage(val){
    this.actualPage = val;
    if(this.actualPage%5 == 0){
      this.createButtons();
    }
    this.getHeights(this.tableData[this.actualPage-1],this.data.actions);
  }

  firstPage(){
    this.actualPage = 1;
    this.createButtons();
    this.getHeights(this.tableData[this.actualPage-1],this.data.actions);
    this._cdr.detectChanges();
  }

  lastPage(){
    this.actualPage = this.totalPages;
    this.createButtons();
    this.getHeights(this.tableData[this.actualPage-1],this.data.actions);
    this._cdr.detectChanges();
  }

  search(inicializar: boolean){
    if(this.data){
      var ob=this.data.data;
      var res = ob.filter(o => 
        Object.keys(o).some(k => 
          (!this.hasTag(k) && o[k] != null && o[k].toString().toLowerCase().includes(this.searchActual.toLowerCase())) 
          && (this.data.headers.map(i => i.imgOptions ? i.imgOptions.keyText: i.key).includes(k))
          || (this.hasTag(k) && this.valueTagOptions(k,o[k],this.searchActual))
        )
      );
      this.filterData = res;
      if(inicializar){
        this.actualPage = 1;
      }
      
     
      if(this.filterData.length == 0 || this.filterData == ""){
        this.notFound = true;
        document.getElementById('maskLoading').style.display = "none";
        this.getInfo(this.data);
      }
      else{
        this.notFound = false;
        let tmp = Object.assign([], this.data);
        tmp.data = this.filterData;
        this.getInfo(tmp);
      }
    }
  }

  hasTag(key:string){
    let header: any;
    for(let i = 0;i < this.data.headers.length;i++){
      if(this.data.headers[i]['key']==key){
        header = this.data.headers[i];
        break;
      }
    }

    if(!header || !header.tag){
      return false;
    }
    return true;
  }

  valueTagOptions(key:string,value: string, valueSearch: string){
    
    let header: any;
    for(let i = 0;i < this.data.headers.length;i++){
      if(this.data.headers[i]['key']==key){
        header = this.data.headers[i];
        break;
      }
    }

    if(!header || !header.tag){
      return false;
    }

    let valueTag = '';
    for(let i = 0;i < header.tagOptions.length;i++){
      if(header.tagOptions[i]['value'] == value){
        valueTag = header.tagOptions[i]['text'];
      }
    }

    if(!valueTag){
      return false;
    }

    return valueTag.toString().toLowerCase().includes(valueSearch.toLowerCase());

  }

  iconsArray(value){
    let array = {
      over: 0,
      array: []
    };
    for(let i = 0; i < value; i++){
      if(i == 5){
        break;
      }
      array.array.push(i);
    }
    if(value > 5){
      array.over = value-5;
    }
    return array;
  }

  selectAll(value, pointer){
    this.selectedItemsArray = [];
    for(let i = 0; i < this.tableData[this.actualPage-1].length; i++){
      let check = document.getElementById('check'+i+pointer) as HTMLInputElement;
      check.checked = value;
      if(value){
        this.selectedItemsArray.push( this.tableData[this.actualPage-1][i]);
      }
    }
    this.selectedItems.next(this.selectedItemsArray);
  }

  checkThis(item, value, point1, point2){
   
    let check = document.getElementById('checkHeader'+point2) as HTMLInputElement;
    if(check.checked == true && value == false){
      check.checked = false;
    }
    let exist;
    if(item.timeSheetId)
    {
      exist = this.selectedItemsArray.findIndex(x => x.timeSheetId == item.timeSheetId);
    }
    else if(item.justificationId)
    {
      exist = this.selectedItemsArray.findIndex(x => x.justificationId == item.justificationId);
    }
    else if(item.position_id)
    {
      exist = this.selectedItemsArray.findIndex(x => x.position_id == item.position_id);
      
    }
   
    if(value == true){
      if(exist < 0){
        this.selectedItemsArray.push(item);
      }
    }
    else{
      if(exist >= 0){
        this.selectedItemsArray.splice(exist,1);
      }
    }
    this.selectedItems.next(this.selectedItemsArray);
  }
}
