import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CalendarService } from '../../../../services/settings/calendar/calendar.service';
import { CompaniesService } from '../../../../services/companies/companies.service';
import { ScheduleService } from '../../../../services/schedule/schedule.service';
import { SharedVarServiceService } from '../../../../services/SharedVarService/shared-var-service.service';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from "src/app/services/settings/users/users.service";
import { EmployeesService } from '../../../../services/employees/employees.service';
import { PeriodService } from 'src/app/services/settings/period/period.service';
import { EventsModalComponent } from '../events-modal/events-modal.component';

@Component({
  selector: 'app-approve-attendance',
  templateUrl: './approve-attendance.component.html',
  styleUrls: ['./approve-attendance.component.scss']
})
export class ApproveAttendanceComponent implements OnInit {

  searchVal: any = "";
  daysList: any = [];
  dateSelect: any;
  attendance: any = [];
  projects: any = [];
  agreements: any = [];
  departments: any = [];
  filterData: any[] = [];
  selectedProject: any = "";
  selectedAgreement: any = "";
  selectedDepartment: any = "";
  searchProject: any = "";
  searchAgreement: any = "";
  searchDepartment: any = "";
  searchShift: any = "";
  shiftsList: any = "";
  periods: any = [];
  periodType: any = "Semanal";
  view = 1;
  selectedPeriodId: any;
  selectedEmployee: any;
  employeeInfo: any = {
    employeeName: "",
    projectName: "",
    departmentName: "",
    shiftName: "",
    schedule: []
  };
  infoDayList: any;
  actualYear = new Date().getFullYear();
  filterDepartments = [];
  filterAgreements: any = [];
  actualDate: string = moment(new Date()).format('YYYY-MM-DD');
  holidays: any[] = [];
  employeeNumber: string;

  constructor(
    private _scheduleService: ScheduleService,
    private _cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private sharedVar: SharedVarServiceService,
    private _companiesService: CompaniesService,
    private calensarService: CalendarService,
    public usersService: UsersService,
    private _cookieService: CookieService,
    private employyeService:EmployeesService,
    private periodService: PeriodService
  ) { }

  ngOnInit() {
    this.sharedVar.setValue("<i class='far fa-calendar-alt'></i> Asistencia • Aprobar asistencia");
    this.getDays();
  }

  async getDays() {
    
    this.periods = await this.periodService.getPeriodsByCurrenYear();

    let actualDate;
    let selectPeriod: any = {};
    
    if(this.periods && this.periods.length > 0){
      
        const periodOpeneds = this.periods.filter(period => period.periodStatus == 1);
        selectPeriod = periodOpeneds && periodOpeneds.length > 0 ? periodOpeneds[0] : this.periods[0];
        
        actualDate = new Date(selectPeriod.periodStartDate);
        actualDate.setDate(actualDate.getDate() + 1);
    }
    
    var fecha1 = moment(selectPeriod.periodStartDate);
    var fecha2 = moment(selectPeriod.periodEndDate);
    let diff = fecha2.diff(fecha1, 'days') + 1;

    this.holidays = await this.calensarService.getHolidaysFilters({ startDate: fecha1.format('YYYY-MM-DD'), endDate: fecha2.format('YYYY-MM-DD') });
    for (let i = 0; i < diff; i++) {
      
      let tmpDay: any = {};
      
      tmpDay = {
        day: actualDate.getDate(),
        month: this.getMonth(actualDate.getMonth()),
        weekDay: this.getWeekDay(actualDate.getDay())[0],
        year: actualDate.getFullYear(),
        periodDay: i,
        date: moment(actualDate).format('YYYY-MM-DD'),
        isHoliday: this.holidays.filter(element => { return moment(element.holidayDate).format('YYYY-MM-DD') == moment(actualDate).format('YYYY-MM-DD') }).length > 0,
        dayEn: this.getWeekDay(actualDate.getDay())[1]
      };
      this.daysList.push(tmpDay);
      actualDate.setDate(actualDate.getDate() + 1);
    }
    this.selectedPeriodId = selectPeriod.periodId;
    
    this.getInfo();
  }

  getMonth(value) {
    switch (value) {
      case 0:
        return 'Ene'
        break;
      case 1:
        return 'Feb'
        break;
      case 2:
        return 'Mar'
        break;
      case 3:
        return 'Abr'
        break;
      case 4:
        return 'Mayo'
        break;
      case 5:
        return 'Jun'
        break;
      case 6:
        return 'Jul'
        break;
      case 7:
        return 'Ago'
        break;
      case 8:
        return 'Sep'
        break;
      case 9:
        return 'Oct'
        break;
      case 10:
        return 'Nov'
        break;
      case 11:
        return 'Dic'
        break;
    }
  }

  getWeekDay(value) {
    switch (value) {
      case 1:
        return ['Lun', 'monday'];
        break;
      case 2:
        return ['Mar', 'tuesday'];
        break;
      case 3:
        return ['Mié', 'wednesday'];
        break;
      case 4:
        return ['Jue', 'thursday'];
        break;
      case 5:
        return ['Vie', 'friday'];
        break;
      case 6:
        return ['Sáb', 'saturday'];
        break;
      case 0:
        return ['Dom', 'sunday'];
        break;
    }
  }

  async searchPeriod(period) {
    this.selectedPeriodId = period;
    this.daysList = [];
    let actualDate;
    let selectPeriod: any = [];
    for (let i = 0; i < this.periods.length; i++) {
      if (this.periods[i].periodId == period) {
        selectPeriod = this.periods[i];
        actualDate = new Date(this.periods[i].periodStartDate);
        actualDate.setDate(actualDate.getDate() + 1);
      }
    }
    // let actualDate = new Date();
    var fecha1 = moment(selectPeriod.periodStartDate);
    var fecha2 = moment(selectPeriod.periodEndDate);
    let diff = fecha2.diff(fecha1, 'days') + 1;

    this.holidays = [];//await this.calensarService.getHolidaysFilters({ startDate: fecha1.format('YYYY-MM-DD'), endDate: fecha2.format('YYYY-MM-DD') });

    for (let i = 0; i < diff; i++) {
      let tmpDay: any = {};
      tmpDay = {
        day: actualDate.getDate(),
        month: this.getMonth(actualDate.getMonth()),
        weekDay: this.getWeekDay(actualDate.getDay())[0],
        date: moment(actualDate).format('YYYY-MM-DD'),
        year: actualDate.getFullYear(),
        periodDay: i,
        isHoliday: this.holidays.filter(element => { return moment(element.holidayDate).format('YYYY-MM-DD') == moment(actualDate).format('YYYY-MM-DD') }).length > 0,
        dayEn: this.getWeekDay(actualDate.getDay())[1]
      };
      this.daysList.push(tmpDay);
      actualDate.setDate(actualDate.getDate() + 1);
    }
    this.selectedPeriodId = period;
    
    this.getInfo();
  }

  cleanDate() {
    this.dateSelect = "";
    this.daysList = [];
    this.getDays();
    this._cdr.detectChanges();
  }

  async getInfo() {
    this.projects = await this._companiesService.getProjects();
    this.agreements = await this._companiesService.getAgreements();
    this.departments = await this._companiesService.getDepartments();
    
    await this.createFormat();
  }
  
  async createFormat() {
    this.filterData = [];
    this.filterData = Object.assign([], this.projects);
    for (let i = 0; i < this.filterData.length; i++) {
      const dataProject = await this._scheduleService.getProjectsIsApproved(this.selectedPeriodId, this.filterData[i].projectId);
      
      this.filterData[i].isApproved = dataProject && dataProject.length > 0 ? dataProject[0].totalNotApproved == 0 : true;
      /*let tmpAgreements = this.agreements.filter(x => x.projectId == this.filterData[i].projectId);
      this.filterData[i].agreements = tmpAgreements;
      for (let j = 0; j < this.filterData[i].agreements.length; j++) {
        let tmpDepartments = this.departments.filter(y => y.agreementId == this.filterData[i].agreements[j].agreementId);
        this.filterData[i].agreements[j].departments = tmpDepartments;
        for (let k = 0; k < this.filterData[i].agreements[j].departments.length; k++) {
          this.filterData[i].hasAgreements = true;
          await this.searchAttendance(this.filterData[i], this.filterData[i].agreements[j].departments[k]);
        }
      }*/
    }
    
    this._cdr.detectChanges();
  }

  async openProject(index) {
    
    if(!this.filterData[index].load){
      this.filterData[index].load = true;
      this.filterData[index].agreements = this.agreements.filter(x => x.projectId == this.filterData[index].projectId);

      for (let j = 0; j < this.filterData[index].agreements.length; j++) {
        let tmpDepartments = this.departments.filter(y => y.agreementId == this.filterData[index].agreements[j].agreementId);
        this.filterData[index].agreements[j].departments = tmpDepartments;
        const dataDepartments = await this._scheduleService.getTotalEmployeesByDepartment(this.selectedPeriodId, this.filterData[index].projectId, this.filterData[index].agreements[j].agreementId);
        for (let k = 0; k < this.filterData[index].agreements[j].departments.length; k++) {
          const data = dataDepartments.filter(y => y.departmentId == this.filterData[index].agreements[j].departments[k].departmentId);
          if(data && data.length > 0){
            this.filterData[index].agreements[j].departments[k].totalEmployee = data[0].totalEmployee/7;
            this.filterData[index].agreements[j].departments[k].isApproved = data[0].totalNotApproved == 0;
          }else{
            this.filterData[index].agreements[j].departments[k].totalEmployee = 0;
            this.filterData[index].agreements[j].departments[k].isApproved = true;
          }
          
        }
      }

    }
    this.filterData[index].open = !this.filterData[index].open;
    this._cdr.detectChanges();
  }


  hasMatch(employeeName: string) {
    if(this.searchVal){
      return employeeName.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.searchVal.toUpperCase());
    }
    return true;
  }

  getAgreements(project) {
    this.searchProject = project;
    this.searchAgreement = "";
    this.searchDepartment = "";
    this.filterDepartments = [];
    if (project != '') {
      this.filterAgreements = this.agreements.filter(x => x.projectId == project);
    }
    else {
      this.filterAgreements = [];
    }
  }

  getDepartments(agreement) {
    this.searchAgreement = agreement;
    if (agreement != '') {
      this.filterDepartments = this.departments.filter(x => x.agreementId == agreement);
    }
    else {
      this.filterDepartments = [];
    }
  }

  formatHour(hour) {
    let tmpHour = hour.split(":");
    let hourF;
    let ampm;
    if (hour != "--:--") {
      if (tmpHour[0] >= 12) {
        ampm = "pm";
        if(tmpHour[0]!='12')
        {
          hourF = tmpHour[0] - 12;
        }
        else
        {
          hourF = tmpHour[0]
        }
        
      }
      else {
        ampm = "am";
        hourF = tmpHour[0];
      }
      return hourF + ":" + tmpHour[1] + " " + ampm;
    }
    else {
      return "--:--";
    }
  }

  async searchAttendance(project: any, department: any) {
    
    if(!department.load) {
      department.load = true;
      department.employees = await this._scheduleService.getAttendanceByPeriod(this.selectedPeriodId, project.projectId, department.agreementId, department.departmentId);
    
      if (!department.employees) {
        
        
        department.employees = [];
      }
    }
    

    department.open = !department.open;
    this._cdr.detectChanges();
  }

  createVectores(employee) {

    employee.vectores = [];
    this.daysList.forEach(day => {

      const isWork = employee.shift[day.periodDay];

      if (day.date > this.actualDate) {
        employee.vectores.push({ vector: "", justification: "", title: "Sin lectura", times: "-- : --" });
      }
      
      else if (!employee.shiftId) {
        employee.vectores.push({ vector: "F", justification: "", title: "Sin Turno Asignado", times: "-- : --" });
      }
      else if (employee.attendances && employee.attendances.length > 0) {
        
        const result = employee.attendances.filter(attendance => { return attendance.date == day.date });
        if (result && result.length > 0) {
          
          const times = (result[0].start && result[0].start != "-" ? moment(result[0].start).format('HH:mm') : '--') + ' : ' + (result[0].end && result[0].end != "-" ? moment(result[0].end).format('HH:mm') : '--');
          const justification = result[0].justification != "-" ? result[0].justification : "";

          let vector = result[0].vector;

          if(!isWork){
            if(result[0].vector == 'F'){
              vector = "_";
            }
          }else if(day.isHoliday){
            if(result[0].vector == 'F'){
              vector = "O";
            }
          }

          employee.vectores.push({ vector: vector, justification: justification, title: "Entrada - Salida", times: times });

        } else {
          employee.vectores.push({ vector: "L", justification: "", title: "Sin Relación Laboral", times: "-- : --" });
          //employee.vectores.push({ vector: isWork && !day.isHoliday ? "F" : "_", justification: "", title: isWork ? "Entrada - Salida" : "No Laboral", times: "-- : --" });
        }
      } else {
        employee.vectores.push({ vector: "L", justification: "", title: "Sin Relación Laboral", times: "-- : --" });
        //employee.vectores.push({ vector: isWork && !day.isHoliday ? "F" : "_", justification: "", title: isWork ? "Entrada - Salida" : "No Laboral", times: "-- : --" });
      }
    });

  }

  async viewEmployee(employeeId,employeeNumber?:string) {
    this.employeeNumber = employeeNumber;
    this.view = 2;
    this.infoDayList = Object.assign([], this.daysList);
    
    this.selectedEmployee = await this._scheduleService.getAttendanceEmployee(employeeId, this.selectedPeriodId);
    //let shiftEmployee = await this.employyeService.getEmployeeShiftByPeriod(employeeNumber,this.selectedPeriodId);
    if (this.selectedEmployee.length > 0) {
      let lastPosicion = this.selectedEmployee.length-1;
      let tmpInfo = {
        employeeNumber: this.selectedEmployee[lastPosicion].employeeNumber,
        employeeName: this.selectedEmployee[lastPosicion].employeeName,
        projectName: this.selectedEmployee[lastPosicion].projectName,
        departmentName: this.selectedEmployee[lastPosicion].departmentName,
        shiftName: this.selectedEmployee[lastPosicion].shiftId.shiftName,
        schedule: []
      };
      for (let i = 0; i < this.selectedEmployee[lastPosicion].shiftId.schedule.length; i++) {
        let days = "";
        if (this.selectedEmployee[lastPosicion].shiftId.schedule[i].tagActive == 1) {
          if (this.selectedEmployee[lastPosicion].shiftId.schedule[i].monday) {
            days += "Lu, ";
          }
          if (this.selectedEmployee[lastPosicion].shiftId.schedule[i].tuesday) {
            days += "Ma, ";
          }
          if (this.selectedEmployee[lastPosicion].shiftId.schedule[i].wednesday) {
            days += "Mi, ";
          }
          if (this.selectedEmployee[lastPosicion].shiftId.schedule[i].thursday) {
            days += "Ju, ";
          }
          if (this.selectedEmployee[lastPosicion].shiftId.schedule[i].friday) {
            days += "Vi, ";
          }
          if (this.selectedEmployee[lastPosicion].shiftId.schedule[i].saturday) {
            days += "Sa, ";
          }
          if (this.selectedEmployee[lastPosicion].shiftId.schedule[i].sunday) {
            days += "Do";
          }
          let tmpShchedule = {
            startHour: this.selectedEmployee[lastPosicion].shiftId.schedule[i].startHour,
            endHour: this.selectedEmployee[lastPosicion].shiftId.schedule[i].endHour,
            days: days
          }
          tmpInfo.schedule.push(tmpShchedule);
        }
      }
      this.employeeInfo = tmpInfo;
    }
    this._cdr.detectChanges();
  }

  getSchedules(date) {
    let tmpInfo = this.selectedEmployee.filter(x => x.dateAttendance == date);
    return tmpInfo;
  }

  getFormatHour(dateTime, type, dateTime2?) {
    
    if (type == 1) {
      if (dateTime != null) {
        let tmpDate = dateTime.split("T");
        return tmpDate[1].substr(0,5);
        //return this.formatHour(tmpDate[1]);
      }
      else {
        return "--:--";
      }
    }
    else if (type == 2) {
      if (dateTime != null && dateTime2 != null) {
        let a: any = new Date(dateTime2);
        let b: any = new Date(dateTime);
        //La diferencia se da en milisegundos así que debes dividir entre 1000
        let c = ((a - b) / 1000);

        c = c / 3600; //ahora entre minutos
       
        
        return c.toFixed(2) + " hrs";
      }
      else {
        return "0 hrs";
      }
    }
  }

  validateAll() {
    if (this.searchProject == '' && this.filterData.length > 0) {
      let flag = 0;
      for (let i = 0; i < this.filterData.length; i++) {
        if (this.filterData[i].checked) {
          flag++;
        }
      }
      if (flag > 0) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      let flag = 0;
      for (let i = 0; i < this.filterData.length; i++) {
        if (this.filterData[i].projectId == this.searchProject && this.filterData[i].checked) {
          flag++;
        }
      }
      if (flag > 0) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  approveSingle(project) {
    Swal.fire({
      title: '¡Espere!',
      html: '¿Está seguro que quiere aprobar las asistencias del proyecto <b style="color: blue;!">' + project.projectIdIca + " - " + project.projectName + '</b>?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, aprobar',
      cancelButtonText: 'No, cancelar'
    }).then((result) => {
      if (result.value) {
        this.approveFunction(2, project.projectId);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
  }

  approveAll() {
    Swal.fire({
      title: '¡Espere!',
      html: '¿Está seguro que quiere aprobar las asistencias de <b style="color: blue;!">todos los proyectos seleccionados</b>?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, aprobar',
      cancelButtonText: 'No, cancelar'
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          imageUrl: "../../../../assets/media/misc/loader.gif",
          showConfirmButton: false
        })
        this.approveFunction(1);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    })
  }

  async approveFunction(type, project?) {
    let params = {
      projectId: [],
      tagStatus: 1,
      approvalDate: new Date(),
      approvalUser: this._cookieService.get('userID')
    }
    let projectsToProcess: number[] = [];
    if (type == 1) {
      if (this.searchProject == '') {
        for (let i = 0; i < this.filterData.length; i++) {
          if (this.filterData[i].checked) {
            projectsToProcess.push(this.filterData[i].projectId);
          }
        }
      }
      else {
        for (let i = 0; i < this.filterData.length; i++) {
          if (this.filterData[i].checked && this.filterData[i].projectId == this.searchProject) {
            projectsToProcess.push(this.filterData[i].projectId);
          }
        }
      }
    }
    else if (type == 2) {
      
      params.projectId = [project];
      let res = await this._scheduleService.approveAttendance(params, this.selectedPeriodId);

      if(res.data.success){
        Swal.fire({
          title: 'Éxito',
          text: 'Se generaron las Hojas de Tiempo correctamente',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
        this.getInfo();
      }else{
        Swal.fire({
          title: 'Ups',
          text: res.data.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      }

      return;
    }
    
    let hasError: boolean = false;
    let totalSucces = 0;
    let message: string = "No fue posible procesar las hojas de los siguientes proyectos: "
    for(let i = 0;i < projectsToProcess.length;i++){
      params.projectId = [projectsToProcess[i]];
      let res = await this._scheduleService.approveAttendance(params, this.selectedPeriodId);
      if (!res.data.success) {
        hasError = true;
        const p = this.filterData.filter(item => item.projectId == projectsToProcess[i]);
        message += "\n"+p[0].projectName+": "+res.data.message;
      }
      else {
        totalSucces++;
      }
    }

    if(hasError){
      Swal.fire({
        title: 'Ups ('+totalSucces+' de '+projectsToProcess.length+')',
        text: message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });
      
    }else{
      Swal.fire({
        title: 'Exito ('+totalSucces+' de '+projectsToProcess.length+')',
        text: 'Generaron las Hojas de Timepo correctamente',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });
      this.getInfo();
    }


    
    
  }

  checkApprove(project) {
    let totalDepartment: number = 0;
    let totalApproved: number = 0;
    for(let i = 0;project.agreements && i < project.agreements.length;i++){
      for(let j = 0;project.agreements[i].departments && j < project.agreements[i].departments.length;j++){
        
        if(project.agreements[i].departments[j].employees && project.agreements[i].departments[j].employees.length > 0){
          totalDepartment++;
          totalApproved++;
          for(let k = 0;project.agreements[i].departments[j].employees && k < project.agreements[i].departments[j].employees.length;k++){
            if(project.agreements[i].departments[j].employees[k].tagStatus == 0){
              totalApproved--;
            }
          }
        }
      }
    }
    
    return totalDepartment == 0 ? false : totalDepartment == totalApproved;
  }

  checkApproveDepartment(department) {
    for(let k = 0;department.employees && k < department.employees.length;k++){
      if(department.employees[k].tagStatus == 0){
        return false;
      }
    }
    return true;
  }

  checkEmployees(project) {
    for(let i = 0;project.agreements && i < project.agreements.length;i++){
      for(let j = 0;project.agreements[i].departments && j < project.agreements[i].departments.length;j++){
        if(project.agreements[i].departments[j].employees && project.agreements[i].departments[j].employees.length > 0){
          return true;
        }
      }
    }

    return false;
  }

  openEvents(){
    const modalRef = this.modalService.open(EventsModalComponent, {size:'xl'})
          modalRef.componentInstance.dataI = {
            periodId:this.selectedPeriodId,
            employeeNumber: this.employeeNumber
          };
          modalRef.result.then( res => {
            if (res != undefined) {
              
            }
          })
  }
}
