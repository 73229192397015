import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

import { IFilterNotifications } from 'src/app/interface/IFilterNotificatios';
@Injectable({
  providedIn: 'root'
})
export class NotificacionsService {

  constructor(private apiService: ApiService) { }
//filter:IFilterTimeSheet
  async getNotifications(filter:IFilterNotifications){
    const responseNotifications: any = await this.apiService.get("notification",filter);
      if(responseNotifications.statusCode === 200){
        return responseNotifications.data.items;
      }
  }

  
  async readNotification(id:any,status:any){
    const updateStatusResponse = await this.apiService.putNotifi(`notification/updateStatus`, id, status)
    return updateStatusResponse;
  }


  async getPeriodConfig(){
    const responsePeriod: any = await this.apiService.get("config-period");
      if(responsePeriod.statusCode === 200){
        return responsePeriod.data.items;
      }
  }

  async statusPeriod(id:any,body:any){
    const updatePeriodResponse = await this.apiService.put(`config-period`, id, body)
    return updatePeriodResponse;
  }
 
}
