import { Component, Input, OnInit,  ChangeDetectorRef } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShiftsService } from '../../../../services/shifts/shifts.service';
import { CompaniesService } from '../../../../services/companies/companies.service';
import { EmployeesService } from '../../../../services/employees/employees.service';
import Swal from 'sweetalert2';
import {FormGroup,FormControl,Validator,Validators,FormBuilder,FormArray,} from "@angular/forms";
import { makeStateKey } from '@angular/platform-browser';
import { PeriodService } from 'src/app/services/settings/period/period.service';
@Component({
  selector: 'app-events-modal',
  templateUrl: './events-modal.component.html',
  styleUrls: ['./events-modal.component.scss']
})
export class EventsModalComponent implements OnInit {
  @Input() dataI: any;

  constructor(
    public activeModal: NgbActiveModal,
    public _companiesService: CompaniesService,
    public _shiftsService: ShiftsService,
    private fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _periodService: PeriodService
  ) {
    this.formData();
   }

  companies: any = [];
  projects: any = [];
  agreements: any = [];
  departments: any = [];
  costDestination: any = [];
  shifts: any = [];
  leaders: any = [];
  subordinates: any = [];
  employees: any = [];
  tmpLeaders: any = [];
  tmpSubordinates: any = [];
  leadersSelected : any = [];
  subordinatesSelected:any = [ ];
  data= {
    workgroupName: '',
    companyId: '',
    projectId: '',
    agreementId: '',
    departmentId: '',
    shiftId: '',
    costDestinationId: '',
    leaders:[],
    subordinates: []
    
  };
  employeesWithWorkGroup:any=[];
  tableData: any;
  public formdata: FormGroup;

  async ngOnInit() {
    
    this.data = await this._periodService.getEventsByPeriodAndEmployee(this.dataI.employeeNumber,this.dataI.periodId);

    this.tableData = {
      limitCol: 7,
      //actions: true,
      /*actionsMenu: [
          {
              text: "Editar",
              icon: "fas fa-pencil-alt",
              action: 'edit',
              permission: 'CALENDR_UPDATE_DAY'
          },
          {
              text: "Procesar",
              icon: "fas fa-calendar-check",
              action: 'sync',
              permission: 'CALENDR_UPDATE_DAY'
          }
      ],*/
      headers: [
      {
        text: "Procesado",
        key: "valid",
        tag: true,
        const: true,
        tagOptions: [{
            value: 1,
            colorBg: '#17C827',
            colorText: 'black',
            text: 'Valido'
        },{
          value: 0,
          colorBg: 'gray',
          colorText: 'white',
          text: 'Descartado'
      }]
      },
      {
          text: "Evento",
          key: "eventDate"
      },
      {
        text: "Fecha",
        key: "eventDate",
        dateFormat: true,
        typeDateFormat: 'date'
      },
      {
        text: "Hora",
        key: "eventDate",
        dateFormat: true,
        typeDateFormat: 'time'
      },
      {
        text: "Día",
        key: "eventDate",
        dateFormat: true,
        typeDateFormat: 'dayLetter'
      },
      {
          text: "Acceso",
          key: "accessName"
      }, 
      {
        text: "Projecto",
        key: "projectIdIca"
      }/*, {
          text: "NO. DE PERIODO",
          key: "period"
      }, {
          text: "TIPO",
          key: "periodType",
          tag: true,
          const: true,
          tagOptions: [{
              value: 0,
              colorBg: 'rgba(0,0,0,0)',
              colorText: 'black',
              text: 'Semanal'
          }]
      }, {
          text: "ESTADO",
          key: "periodStatus",
          tag: true,
          const: true,
          tagOptions: [{
              value: 0,
              colorBg: '#17C827',
              colorText: 'black',
              text: 'Nuevo'
          }, {
              value: 1,
              colorBg: '#17C827',
              colorText: 'black',
              text: 'Abierto'
          }, {
              value: 2,
              colorBg: '#17C827',
              colorText: 'black',
              text: 'Cerrado'
          }]
      }*/],
      data: this.data
  };
      
  
  }

  
  formData() {
    return(
     this.formdata = this.fb.group({
      leaders: [null, Validators.required],
      subordinates: [null, Validators.required]
      
    }));
  }

}