import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendanceComponent } from './attendance.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ExculpatoryComponent } from "./exculpatory/exculpatory.component";
import { ApproveAttendanceComponent } from './approve-attendance/approve-attendance.component';

const routes: Routes = [{
    path: '',
    component: AttendanceComponent,
    children: [
        {
            path: 'approve_attendance',
            component: ApproveAttendanceComponent,
        }, {
            path: 'schedule',
            component: ScheduleComponent,
        }, {
            path: 'exculpatory',
            component: ExculpatoryComponent,
        }, {
            path: '',
            redirectTo: 'schedule',
            pathMatch: 'full'
        }, {
            path: '**',
            component: ApproveAttendanceComponent,
            pathMatch: 'full',
        },
    ],
}];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class AttendanceRoutingModule { }
