import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth.service';
import {UsersService} from '../../../services/settings/users/users.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router:Router, private userService:UsersService, private cookieService: CookieService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = this.authService.currentUserValue;
    
    const currentUser = this.cookieService.get('userID');
    if (currentUser) {
      if(state.url == '/employees/employees')
      {
        if(!this.userService.getProfilesModule(['EMPLOYEE']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/employees/work_groups')
      {
        if(!this.userService.getProfilesModule(['GROUP_WORK']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/attendance/approve_attendance')
      {
        if(!this.userService.getProfilesModule(['APPROVE_ASSISTANCE']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/attendance/schedule')
      {
        if(!this.userService.getProfilesModule(['SHIFTS_']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/attendance/exculpatory')
      {
        if(!this.userService.getProfilesModule(['JUSTIFICATION']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/requests/time_request')
      {
        if(!this.userService.getProfilesModule(['REQUEST_TIME']))
        {
          this.authService.back();
          return false;
        }
      }
      else if(state.url == '/requests/aditional_request')
      {
        if(!this.userService.getProfilesModule(['REQUEST_ADDITIONAL']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/time_sheets/time_sheets')
      {
        if(!this.userService.getProfilesModule(['TIME_SHEET']))
        {
          this.authService.back();
          return false;
        }
      }
      else if(state.url == '/record/employee_record')
      {
        if(!this.userService.getProfilesModule(['HISTORY_EMPLOYEE']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/record/assist_record')
      {
        if(!this.userService.getProfilesModule(['HISTORY_ASSISTANCE']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/record/auth_record')
      {
        if(!this.userService.getProfilesModule(['HISTORY_AUTHORIZATIONS']))
        {
          this.authService.back();
          return false;
        }
      }
      else if(state.url == '/record/workgroup_record')
      {
        if(!this.userService.getProfilesModule(['HISTORY_GROUP_WORK']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/record/tab_record')
      {
        if(!this.userService.getProfilesModule(['HISTORY_TABULATOR']))
        {
          this.authService.back();
         return false;
        }
      }
      
      else if(state.url == '/settings/users')
      {
        if(!this.userService.getProfilesModule(['USER']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/settings/profiles')
      {
        if(!this.userService.getProfilesModule(['PROFILE']))
        {
          this.authService.back();
          return false;
        }
      }
      else if(state.url == '/settings/projects')
      {
        if(!this.userService.getProfilesModule(['PROJECT']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/settings/agreement')
      {
        if(!this.userService.getProfilesModule(['AGREEMENT']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/settings/access')
      {
        if(!this.userService.getProfilesModule(['ACCESS']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/settings/calendar')
      {
        if(!this.userService.getProfilesModule(['CALENDR']))
        {
          this.authService.back();
         return false;
        }
      }
      else if(state.url == '/settings/inci_vec')
      {
        if(!this.userService.getProfilesModule(['EVENTS']))
        {
          this.authService.back();
          return false;
        }
      }
      else if(state.url == '/devices/inventory')
      {
        if(!this.userService.getProfilesModule(['INVENTORY']))
        {
          this.authService.back();
          return false;
        }
      }
      else if(state.url == '/devices/admin')
      {
        if(!this.userService.getProfilesModule(['ADMINISTRATION']))
        {
          this.authService.back();
         return false;
        }
      }
      

      // logged in so return true
      return true;
    }
    else{
      // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
    }
  }
}
