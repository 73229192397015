import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable()
export class ShiftsService {

  constructor(
    private apiService: ApiService
  ) { }

  async createShift(shift){
    const createShiftResponse = await this.apiService.post(`shifts`, shift)
    return createShiftResponse;
  }

  async getShifts(){
    const responseShifts: any = await this.apiService.get("shifts");
    
      if(responseShifts.statusCode === 200){
        return responseShifts.data.items;
      }
  }
  async getShiftsByProjectId(id:any){
    const responseShifts: any = await this.apiService.get(`shifts?projectId=${id}`);
      if(responseShifts.statusCode === 200){
        return responseShifts.data.items;
      }
  }

  async getShiftById(id: any){
    const responseShift: any = await this.apiService.get(`shifts/${id}`);
      if(responseShift.statusCode === 200){
        return responseShift.data;
      }
  }

  async updateShift(id:any, shiftData: any){
    const updateShiftResponse = await this.apiService.put(`shifts`, id, shiftData)
    return updateShiftResponse;
  }

  async deleteShift(id){
    const deleteShiftResponse = await this.apiService.delete(`shifts`, id)
    return deleteShiftResponse;
  }
}