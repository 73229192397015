import { ThrowStmt } from "@angular/compiler";
import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ShiftsService } from "../../../../services/shifts/shifts.service";
import { EmployeesService } from "../../../../services/employees/employees.service";
import Swal from "sweetalert2";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { Subject, Observable } from "rxjs";
import { CompaniesService } from "../../../../services/companies/companies.service";
import { DomSanitizer } from "@angular/platform-browser";
import * as alertify from "alertifyjs";
@Component({
  selector: "app-employees-modal",
  templateUrl: "./employees-modal.component.html",
  styleUrls: ["./employees-modal.component.scss"],
})
export class EmployeesModalComponent implements OnInit {
  @Input() data: any;
  title = "";
  employee: any;
  shifts: any;
  imgChange = false;
  fullNameEmployee: string;
  public webcamImage: WebcamImage = null;
  url: any;
  editPhoto = false;
  photosArray: any = [
    {
      photo: [],
      edit: false,
    },
    {
      photo: [],
      edit: false,
    },
    {
      photo: [],
      edit: false,
    },
    {
      photo: [],
      edit: false,
    },
    {
      photo: [],
      edit: false,
    },
    {
      photo: [],
      edit: false,
    },
  ];
  photoIndex = 0;
  costDestinationChange = false;
  shiftChange = false;
  costDestinations: any = [];
  shiftIdChange: any = "";
  costDestinationChangeId: any = "";
  chechBox: any = false;
  currentShiftId: any;
  constructor(
    public activeModal: NgbActiveModal,
    private _employeesService: EmployeesService,
    private _shiftsService: ShiftsService,
    private _companiesService: CompaniesService,
    private _sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.employee = await this._employeesService.getEmployeeById(
      this.data.data.employeeId
    );
    this.chechBox = this.employee.bangingPrivilege;
    if (this.employee.photos.length == 0) {
      for (let i = 0; i < 6; i++) {
        let tmpPhoto = {
          employeePhoto: "",
        };
        this.employee.photos.push(tmpPhoto);
      }
    }
    
    this.fullNameEmployee =
      this.employee.employeeName +
      " " +
      this.employee.firstName +
      " " +
      this.employee.lastName;
    this.shifts = await this._shiftsService.getShiftsByProjectId(
      this.employee.projectId
    );
    this.currentShiftId = await this._employeesService.getEmployeeShiftByPeriod(
      this.employee.employeeNumber
    );
    if (this.currentShiftId.shiftId.shiftId) {
      this.employee.shiftId = this.currentShiftId.shiftId.shiftId;
      this.employee.effectiveDateShift = this.currentShiftId.startDate;
      this.employee.effectiveDateShiftEnd = this.currentShiftId.endDate;
    }
    await this.getCostDestinations();
    if (this.data.action == "edit") {
      this.title = "Editar Empleado - " + this.employee.employeeName;
    } else if (this.data.action == "view") {
      this.title = "Ver Empleado - " + this.employee.employeeName;
    }
    this.changeCostDestination(this.employee.costDestinationId);
    this.changeShift(this.currentShiftId.shiftId.shiftId);
  }

  changeShift(value) {
    if (value != null) {
      this.shiftChange = true;
      this.shiftIdChange = value;
    }

    /*if(this.employee.shiftId != value){
            this.shiftChange = true;
            this.shiftIdChange = value;
            if(!value){
                this.employee.shiftId=null;
            }
            
        } else {
            this.shiftChange = false;
        }*/
  }

  getImg(event, index) {
    let imagePath;
    const files = event.target.files;

    if (files.length === 0) return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sólo pueden ser archivos de imágenes",
      });
      return;
    }

    const reader = new FileReader();
    imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.employee.photos[index].employeePhoto = reader.result;
    };
    this.employee.photos[index].change = true;
    this.employee.photos[index].inputPhoto = files[0];
    this.imgChange = true;
  }

  recordChange() {
    this.employee.bangingPrivilege = !this.employee.bangingPrivilege;
    this.chechBox = this.employee.bangingPrivilege;
  }

  editEmployee() {
    console.log(this.shiftIdChange);

    if (this.shiftChange && this.shiftIdChange != null) {
      //if(this.employee.effectiveDateShift!="0000-00-00" && this.employee.effectiveDateShift!="" && this.employee.effectiveDateShift!=null)
      // {
      /* 
              Validaciones de destino de costo Fin modificadas para dejar guardar la información del empleado en pruebas integrales

              
                /*if(this.employee.effectiveDateCostDestination!="0000-00-00" && this.employee.effectiveDateCostDestination!="" && this.employee.effectiveDateCostDestination!=null)
                {*/
      // if(this.employee.effectiveDateShiftEnd!="0000-00-00" && this.employee.effectiveDateShiftEnd!="" && this.employee.effectiveDateShiftEnd!=null && this.employee.effectiveDateShiftEnd>this.employee.effectiveDateShift)
      //{
      //if(this.employee.effectiveDateCostDestinationEnd!="0000-00-00" && this.employee.effectiveDateCostDestinationEnd!="" && this.employee.effectiveDateCostDestinationEnd!=null && this.employee.effectiveDateCostDestinationEnd>this.employee.effectiveDateCostDestination)
      //{
      Swal.fire({
        title: "Espere",
        text:
          "Está seguro que quiere editar la información del empleado " +
          this.employee.employeeName,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, actualizar",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          this.confirmEdit();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          return;
        }
      });
      //}
      /*else{
                        alertify.error("Selecciona una fecha valida para el campo Vigencia de destino de costo nuevo Fin,"
                         +"la fecha debe ser mayor a Vigencia de destino de costo nuevo");
                    }*/
      //}
      /*else
                {
                    alertify.error("Selecciona una fecha valida para el campo Vigencia de turno Fin," 
                    +"la fecha debe de ser mayor a Vigencia de turno.");
                }*/

      /*  }
               else
               {
                alertify.error("Selecciona una fecha valida para el campo Vigencia de destino de costo nuevo");
               }*/
      //}
      /*else
            {
                alertify.error("Selecciona una fecha valida para el campo Vigencia de turno");
            }*/
    } else {
      Swal.fire({
        title: "Espere",
        text:
          "Está seguro que quiere editar la información del empleado " +
          this.employee.employeeName,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, actualizar",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          this.confirmEdit();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          return;
        }
      });
    }
  }

  async confirmEdit() {
    let formData: FormData = new FormData();
    if (this.imgChange) {
      /* for( let i = 0; i < this.photosArray.length; i++ ){
                if( this.photosArray[i].edit == false ){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Si cambio alguna foto tiene que cambiar todas'
                    });
                    return;
                }
            }*/
      formData.append(
        "photo1",
        this.dataURLtoFile(
          this.photosArray[0].photo.imageAsDataUrl,
          "photo1.png"
        )
      );
      console.log(this.photosArray[0].photo.imageAsDataUrl);
      /* formData.append('photo2', this.dataURLtoFile(this.photosArray[1].photo.imageAsDataUrl,'photo2.png'));
            formData.append('photo3', this.dataURLtoFile(this.photosArray[2].photo.imageAsDataUrl,'photo3.png'));
            formData.append('photo4', this.dataURLtoFile(this.photosArray[3].photo.imageAsDataUrl,'photo4.png'));
            formData.append('photo5', this.dataURLtoFile(this.photosArray[4].photo.imageAsDataUrl,'photo5.png'));
            formData.append('photo6', this.dataURLtoFile(this.photosArray[5].photo.imageAsDataUrl,'photo6.png'));*/
    } else {
      let point = 1;
      // for(let i = 0; i < this.data.data.photos.length; i++){
      //   if(this.data.data.photos[i].tagActive == 1){
      //     formData.append('photo' + point, this.data.data.photos[i].employeePhoto);
      //     point++;
      //   }
      // }
    }
    let valueCheck =
      this.employee.bangingPrivilege === null
        ? false
        : this.employee.bangingPrivilege;
    formData.append("bangingPrivilege", valueCheck);

    if (this.shiftChange) {
      formData.append("shiftId", this.shiftIdChange);
      if (this.employee.effectiveDateShift != "0000-00-00") {
        formData.append("effectiveDateShift", this.employee.effectiveDateShift);
      } else {
        formData.append("effectiveDateShift", null);
      }
      //Para el campo de Vigencia de Turno Fin
      if (this.employee.effectiveDateShiftEnd != "0000-00-00") {
        formData.append(
          "effectiveDateShiftEnd",
          this.employee.effectiveDateShiftEnd
        );
      } else {
        formData.append("effectiveDateShiftEnd", null);
      }
    } else {
      console.log("this.employee", this.employee);
      console.log("this.employee.shiftId", this.employee.shiftId);
      if (this.employee.shiftId != null) {
        formData.append("shiftId", this.employee.shiftId);
      } else {
        formData.append("shiftId", null);
      }
    }

    if (this.costDestinationChange) {
      if (
        this.employee.effectiveDateCostDestination != "0000-00-00" &&
        this.employee.effectiveDateCostDestination != ""
      ) {
        formData.append(
          "effectiveDateCostDestination",
          this.employee.effectiveDateCostDestination
        );
      } else {
        formData.append("effectiveDateCostDestination", null);
      }
      //Esto es para destino de costo nuevo Fin
      if (
        this.employee.effectiveDateCostDestinationEnd != "0000-00-00" &&
        this.employee.effectiveDateCostDestinationEnd != ""
      ) {
        formData.append(
          "effectiveDateCostDestinationEnd",
          this.employee.effectiveDateCostDestinationEnd
        );
      } else {
        formData.append("effectiveDateCostDestinationEnd", null);
      }

      formData.append("costDestinationId", this.costDestinationChangeId);
    }
    
    let res = await this._employeesService.editEmployee(
      this.employee.employeeId,
      formData
    );
    if (res.status == 200 || res.statusCode == 200) {
      Swal.fire({
        icon: "success",
        title: "Se actualizó el empleado correctamente",
        text: "Éxito",
      });
      this.activeModal.close(true);
    } else if (res.status == 500 && res.error.message.includes('La foto')) {
      let splitMessage = res.error.message.split('(');
      console.log(splitMessage[1].replace(')',''));
      const imgEmployeeLink = splitMessage[1].replace(')','');
      let textError = splitMessage[0].split('-');

      Swal.fire({
        html:
          '<img src='+imgEmployeeLink + ' style="width: 50%;">'+
          '<br> <p ><strong style="color:#d81111";>'+ textError[0] +' - </strong>'+textError[1]+'</p>'  ,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ocurrió un error al actualizar al empleado inténtalo más tarde",
      });
    }
  }

  handleImage(webcamImage: WebcamImage) {
    this.imgChange = true;
    this.webcamImage = webcamImage;
    this.photosArray[this.photoIndex].photo = webcamImage;
    this.photosArray[this.photoIndex].edit = true;
    // console.log("esta es la url de la Camara" + this._sanitizer.bypassSecurityTrustResourceUrl(webcamImage.imageAsDataUrl));
    this.editPhoto = false;
  }

  public addFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
        let print = event.target.result.split("image/jpeg;base64,");
        // console.log("Esta es la URL de el Browser " + print[1]);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  openWebcam(index) {
    this.photoIndex = index;
    this.editPhoto = true;
  }

  async getCostDestinations() {
    this.costDestinations = await this._companiesService.getCostDestinationById(
      this.employee.agreementNumber
    );
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  changeCostDestination(value) {
    if (value != null) {
      this.costDestinationChange = true;
      this.costDestinationChangeId = value;
    }

    /*if(value != this.employee.costDestinationId){
            this.costDestinationChange = true;
            this.costDestinationChangeId = value;
        } else {
            this.costDestinationChange = false;
        }*/
  }
}
