<div class="subheader">
    <div class="subHeaderContainer" style="width: 200px;" *ngIf="view == 1">
        <input type="text" placeholder="Buscar" class="searchInput" [(ngModel)]="searchVal">
    </div>
     <div class="subHeaderContainer" style="width: 170px;" *ngIf="view == 1">
        <select style="width: 100%; height: 24px;" [(ngModel)]="selectedPeriodId" (change)="searchPeriod($event.target.value)">
            <option value="" style="font-weight: bold;">Periodos  ({{periodType}})</option>
            <option *ngFor="let period of periods" [ngStyle]="{'font-weight':period.periodType=== 0 ? 'bold' : 'normal' }" [value]="period.periodId">Periodo {{period.period}} - {{period.periodYear}}</option>
        </select>
    </div>
    <div class="subHeaderContainer" style="width: 170px;" *ngIf="view == 1">
        <select style="width: 100%; height: 24px;" (change)="getAgreements($event.target.value)">
            <option value="" [selected]="searchProject==''">Proyectos (Todos)</option>
            <option *ngFor="let project of projects"  [value]="project.projectId" [selected]="searchProject==project.projectId">{{project.projectIdIca}} - {{project.projectName}}</option>
        </select>
    </div>
    <div class="subHeaderContainer" style="width: 170px;" *ngIf="view == 1">
        <select style="width: 100%; height: 24px;" (change)="getDepartments($event.target.value)" [disabled]="filterAgreements.length == 0">
            <option value="" [selected]="searchAgreement==''">Convenios (Todos)</option>
            <option *ngFor="let agreement of filterAgreements" [value]="agreement.agreementId" [selected]="searchAgreement==agreement.agreementId">{{agreement.agreementNumber}} - {{agreement.agreementName}}</option>
        </select>
    </div> 
    <div class="subHeaderContainer" style="width: 170px;" *ngIf="view == 1">
        <select style="width: 100%; height: 24px;" (change)="searchDepartment = $event.target.value" [disabled]="filterDepartments.length == 0">
            <option value="" [selected]="searchDepartment==''">Departamentos (Todos)</option>
            <option *ngFor="let department of filterDepartments" [value]="department.departmentId" [selected]="searchDepartment==department.departmentId">{{department.agreementNumber}} - {{department.departmentIdIca}} - {{department.departmentName}}</option>
        </select>
    </div>
</div>
<div *ngIf="view == 1" class="subheader" style="padding-top: 103px; z-index: 1 !important;">
    <div class="tableHeader" style="width: 100%;">
        <div class="itemHeader" style="font-weight: bold; font-size: 18px;" [ngStyle]="{'width': daysList.length == 7 ? '23%' : '16%'}">
            <div class="subContHeader" style="padding: 20px; ">
                <div class="subContHeader2" style="text-align: left; ">
                    Empleado
                </div>
            </div>
        </div>
        <div class="itemHeader" [ngStyle]="{'width': daysList.length == 7 ? '11%' : '6%'}" *ngFor="let item of daysList">
            <div class="subContHeader">
                <div [ngClass]="{'dayNumClass' : daysList.length == 7, 'dayNumClass2' : daysList.length > 7 }">
                    {{item.day}}
                </div>
                <div style="width: 100%; height: 100%;">
                    <div style="width: 100%;display: table; font-weight: bold;" [ngStyle]="{'height': daysList.length == 7 ? '50%' : '25%'}">
                        <div [ngClass]="{'dayTextClass' : daysList.length == 7, 'dayTextClass2' : daysList.length > 7 }">
                            {{item.weekDay}}
                        </div>
                    </div>
                    <div style="width: 100%;" [ngStyle]="{'height': daysList.length == 7 ? '50%' : '25%'}">
                        <div [ngClass]="{'dayTextClass' : daysList.length == 7, 'dayTextClass2' : daysList.length > 7 }">
                            {{item.month}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
<div class="tableContainer" *ngIf="view == 1" style="padding-top: 100px;">
    
    <div style="width: 100%;">
        <div style="width: 100%; height: 40px; background-color: #264660; border-bottom: 1px solid white; padding-right: 10px; position: fixed; z-index: 1;  padding-left: 10px;">
            <button [ngClass]="{'approveAllButton' : validateAll(), 'approveAllButtonDisabled' : !validateAll() }" *ngIf="usersService.crudPermissions('APPROVE_ASSISTANCE_UPDATE')" [disabled]="!validateAll()" (click)="approveAll()">Aprobar todos los seleccionados</button>
        </div>
        <div style="width: 100%; padding-top: 40px;">
            <div *ngFor="let project of filterData; index as indexProject" style="width: 100%;" >
                <!--div *ngIf="project.hasAgreements"-->
                <div>
                    <div *ngIf="searchProject == '' || searchProject == project.projectId">
                        <div class="menuProject" (click)="openProject(indexProject)" >
                            <input type="checkbox" (click)="project.checked = !project.checked; $event.stopPropagation()" style="margin-right: 10px;" [checked]="project.checked" value="true" [disabled]="project.isApproved"><i class="fas fa-chevron-down" *ngIf="project.open"></i> <i class="fas fa-chevron-right" *ngIf="!project.open"></i>{{project.projectName}}
                            <button [ngClass]="{'approveButton' : !project.isApproved, approveButtonDisabled: project.isApproved }" [disabled]="project.isApproved" *ngIf="usersService.crudPermissions('APPROVE_ASSISTANCE_UPDATE')" (click)="project.isApproved;">Aprobar</button>
                            <div [ngClass]="{'tagApprove' : project.isApproved, 'tagNoApprove' : !project.isApproved }">
                                <b *ngIf="!project.isApproved">Sin generar</b>
                                <b *ngIf="project.isApproved">Generada</b>
                            </div>
                        </div>
                        <div style="width: 100%;" *ngIf="project.open">
                            <div style="width: 100%;" *ngFor="let agreement of project.agreements">
                                <div style="width: 100%;" *ngIf="searchAgreement == '' || agreement.agreementId == searchAgreement">
                                    <div style="width: 100%;" *ngFor="let department of agreement.departments" >
                                        <!-- <div id="{{department.departmentId}}" *ngIf="department.employees && department.employees.length > 0"> -->
                                        <div id="{{department.departmentId}}" >
                                        <div *ngIf="searchDepartment == department.departmentId || searchDepartment ==''">
                                            <div class="menuAprtment" (click)="searchAttendance(project,department)">
                                                <i class="fas fa-chevron-down" *ngIf="department.open"></i><i class="fas fa-chevron-right" *ngIf="!department.open"></i>
                                                {{department.agreementNumber}} - {{department.departmentName}}
                                                <i class="fas fa-user" style="padding-left: 5px;"></i>{{('('+(department.totalEmployee)+')') }}
                                                <div [ngClass]="{'tagApprove' : department.isApproved, 'tagNoApprove' : !department.isApproved }">
                                                    <b *ngIf="!department.isApproved">No aprobado</b>
                                                    <b *ngIf="department.isApproved">Aprobado</b>
                                                </div>
                                            </div>

                                            <div style="width: 100%;" *ngIf="department.open">
                                                <div *ngFor="let employee of department.employees">
                                                <div class="tableHeader" style="border-top: 1px solid gray;" *ngIf="hasMatch(employee.employeeName) || hasMatch(employee.employeeNumber)">
                                                    <div class="tableHeader">
                                                        <div class="itemHeader" style="font-weight: bold;" [ngStyle]="{'width': daysList.length == 7 ? '23%' : '16%', 'font-size': daysList.length == 7 ? '16px' : '12px'}">
                                                            <div (click)="employee.open = !employee.open" [ngClass]="{'btnViewEmployee' : !employee.open, 'btnViewEmployeeActive' : employee.open }">
                                                                ...
                                                            </div>
                                                            <div class="menuBtnView" *ngIf="employee.open">
                                                                <div style="width: 100%; padding: 5px;">
                                                                    Elige una opción
                                                                </div>
                                                                <div style="width: 100%; padding: 5px; cursor: pointer;" (click)="viewEmployee(employee.employeeId,employee.employeeNumber)"> 
                                                                    <i class="fa fa-eye" style="color: #3699FF;"></i> Ver
                                                                </div>
                                                                <div style="width: 100%; padding: 5px; cursor: pointer;" (click)="employeeNumber = employee.employeeNumber;openEvents()"> 
                                                                    <i class="fa fa-user-circle" style="color: #3699FF;"></i> Eventos
                                                                </div>
                                                                
                                                            </div>

                                                            <div class="subContHeader" style="padding: 20px;">
                                                                <div class="subContHeader2" style="text-align: left;">
                                                                    {{employee.employeeNumber}}-{{employee.employeeName}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="itemHeader" *ngFor="let vector of employee.attendances; index as i"  [ngStyle]="{'width': daysList.length == 7 ? '11%' : '6%'}" style="overflow: auto;">
                                                            <div *ngIf="vector.justification != '-'" class="tagJustification" style="z-index: 0;" [ngStyle]="{'background-color': vector.justification == 'X' ? '#777777' :
                                                            ( vector.justification == 'B' ? '#777777' : ( vector.justification == 'G' ? '#777777' :(vector.justification == 'A' ? '#e7ea27' :'#007fff') )) }">
                                                                {{vector.justification}}
                                                            </div>
                                                            <div class="tagAsistance" style="z-index: 0;" [ngStyle]="{'background-color': vector.vector == 'L' ? '#777777' : vector.vector != 'F' ? '#76EC76' : '#E85C5D' }">
                                                                {{vector.vector}}
                                                            </div>
                                                            
                                                            <div class="subContHeader">
                                                                <div class="subContHeader2" style="width: 100%; background-color: white;">
                                                                    <div style="width: 100%;">
                                                                        <div class="row" style="padding: 0px 0px 0px 0px !important; margin:  0px 0px 0px 0px !important;">
                                                                            <div class="col-5" style="padding: 0px 0px 0px 0px !important; margin:  0px 0px 0px 0px !important;">
                                                                                Entrada
                                                                            </div>
                                                                            <div class="col-2" style="padding: 0px 0px 0px 0px !important; margin:  0px 0px 0px 0px !important;">
                                                                                -
                                                                            </div>
                                                                            <div class="col-5" style="padding: 0px 0px 0px 0px !important; margin:  0px 0px 0px 0px !important;">
                                                                                Salida
                                                                            </div>
                                                                        </div>
                                                                        <div class="row" style="padding: 0px 0px 0px 0px !important; margin:  0px 0px 0px 0px !important;">
                                                                            <div class="col-5" style="padding: 0px 0px 0px 0px !important; margin:  0px 0px 0px 0px !important;">
                                                                                {{ vector.start| timeFecha }}
                                                                            </div>
                                                                            <div class="col-2" style="padding: 0px 0px 0px 0px !important; margin:  0px 0px 0px 0px !important;">
                                                                                :
                                                                            </div>
                                                                            <div class="col-5" style="padding: 0px 0px 0px 0px !important; margin:  0px 0px 0px 0px !important;">
                                                                                {{ vector.end | timeFecha }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menuProject">
            </div>
        </div>
    </div>
</div>
<div *ngIf="view == 2">
    <div style=" width: 100%;">
        <div class="row">
            <div class="col-6">
                <div class="btn-back" (click)="view = 1">
                    <i class="fas fa-chevron-left" style="color: #3699FF;"></i> Regresar
                </div>
            </div>
            <div class="col-6" style="text-align: end;">
                <div class="btn btn-primary" (click)="openEvents()">
                    <i class="fas fa-user-circle"></i> Eventos
                </div>
            </div>
        </div>
        
        <div style="width: 100%; background-color: white; margin-top: 20px;">
            <div style="width: 25%; padding: 20px; display: inline-block; vertical-align: top;">
                <div style="width: 100%; font-weight: bold;">
                    Empleado
                </div>
                <div style="width: 100%;">
                    {{employeeInfo.employeeNumber}}-{{employeeInfo.employeeName}}
                </div>
            </div>
            <div style="width: 25%; padding: 20px; display: inline-block; vertical-align: top;">
                <div style="width: 100%; font-weight: bold;">
                    Proyecto
                </div>
                <div style="width: 100%;">
                    {{employeeInfo.projectName}}
                </div>
            </div>
            <div style="width: 25%; padding: 20px; display: inline-block; vertical-align: top;">
                <div style="width: 100%; font-weight: bold;">
                    Departamento
                </div>
                <div style="width: 100%;">
                    {{employeeInfo.departmentName}}
                </div>
            </div>
            <div style="width: 25%; padding: 20px; display: inline-block; vertical-align: top;">
                <div style="width: 100%; font-weight: bold;">
                    Turno
                </div>
                <div style="width: 100%;">
                    {{employeeInfo.shiftName}}
                    <div style="width: 100%;" *ngFor="let schedule of employeeInfo.schedule">
                        <div style="width: 100%; font-weight: bold;">
                            {{schedule.days}} 
                        </div>
                        <div style="width: 100%;">
                            {{schedule.startHour}} - {{schedule.endHour}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%; background-color: white; margin-top: 20px;">
            <div *ngFor="let day of infoDayList">
                <div class="menuProject" (click)="day.open = !day.open">
                    <i class="fas fa-chevron-down" *ngIf="day.open"></i>
                    <i class="fas fa-chevron-right" *ngIf="!day.open"></i>
                    {{day.weekDay}}. {{day.day}} {{day.month}} {{actualYear}}
                </div>
                <div style="width: 100%; background-color: white;" *ngIf="day.open">
                    <div style="width: 100%; padding: 10px;" *ngFor="let schedule of getSchedules(day.date)">
                        <div style="width: 33%; padding: 10px; display: inline-block; font-weight: bold;">
                            Chequeo de campo
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block; font-weight: bold;">
                            
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block; font-weight: bold;">
                            
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block;">
                            {{getFormatHour(schedule.fieldCheckDate,1)}}
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block;">
                            
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block;">
                            
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block; font-weight: bold;">
                            Entrada
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block; font-weight: bold;">
                            Salida
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block; font-weight: bold;">
                            Duración
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block;">
                            {{getFormatHour(schedule.startDate,1)}}
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block;">
                            {{getFormatHour(schedule.endDate,1)}}
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block;">
                            {{getFormatHour(schedule.startDate,2,schedule.endDate)}}
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block; font-weight: bold;">
                            Inicio de comida
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block; font-weight: bold;">
                            Fin de comida
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block; font-weight: bold;">
                            Duración de comida
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block;">
                            {{getFormatHour(schedule.startMealtime,1)}}
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block;">
                            {{getFormatHour(schedule.endMealtime,1)}}
                        </div>
                        <div style="width: 33%; padding: 10px; display: inline-block;">
                            {{getFormatHour(schedule.startMealtime,2,schedule.endMealtime)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>