<div class="modal-header">
    <h4 class="modal-title"><b>{{title}}</b></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="employee">
        <div class="row">
            <div style="width: 100%;" *ngIf="editPhoto">
                <app-camera style="margin-left: auto; margin-right: auto;" (pictureTaken)="handleImage($event)"></app-camera>
            </div>
            <div class="row row-full-height" style="padding-left: 20px; padding-right: 20px;">
                <div class="col-sm-2 col-md-2 col-lg-2">
                    <div class="imgContainer">
                        <div class="imgButton" (click)="openWebcam(0)" *ngIf="data.action == 'edit'"><i class="fas fa-pencil-alt" style="color: #4389b8;"></i></div>
                        <img class="imgEmployee" [src]="employee.photos[0].employeePhoto" onerror="this.src='../../../../../assets/media/misc/no-image-default.png'" *ngIf="!photosArray[0].edit">
                        
                        <div style="width: 100%;" *ngIf="photosArray[0].edit">
                            <img class="imgEmployee " style="width: 100%;"  [src]="photosArray[0].photo.imageAsDataUrl" />
                        </div>
                        <label style="width: 100%; text-align: center; font-weight: bold; padding: 10px;">
                            Frente
                        </label>
                    </div>
                </div>
               <!-- <div class="col-sm-2 col-md-2 col-lg-2">
                    <div class="imgContainer">
                        <div class="imgButton" *ngIf="data.action == 'edit' && photosArray[1].edit" (click)="openWebcam(1)"><i class="fas fa-pencil-alt" style="color: #4389b8;"></i></div>
                        <img class="imgEmployee" [src]="employee.photos[1].employeePhoto" onerror="this.src='../../../../../assets/media/misc/no-image-default.png'" *ngIf="!photosArray[1].edit">
                        <div style="width: 100%;" *ngIf="photosArray[1].edit">
                            <img class="imgEmployee " style="width: 100%;"  [src]="photosArray[1].photo.imageAsDataUrl" />
                        </div>
                        <label style="width: 100%; text-align: center; font-weight: bold; padding: 10px;">
                            -30°
                        </label>
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2">
                    <div class="imgContainer">
                        <div class="imgButton" *ngIf="data.action == 'edit' && photosArray[2].edit " (click)="openWebcam(2)"><i class="fas fa-pencil-alt" style="color: #4389b8;"></i></div>
                        <img class="imgEmployee" [src]="employee.photos[2].employeePhoto" onerror="this.src='../../../../../assets/media/misc/no-image-default.png'" *ngIf="!photosArray[2].edit">
                        <div style="width: 100%;" *ngIf="photosArray[2].edit">
                            <img class="imgEmployee " style="width: 100%;"  [src]="photosArray[2].photo.imageAsDataUrl" />
                        </div>
                        <label style="width: 100%; text-align: center; font-weight: bold; padding: 10px;">
                            30°
                        </label>
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2">
                    <div class="imgContainer">
                        <div class="imgButton" (click)="openWebcam(3)" *ngIf="data.action == 'edit' && photosArray[3].edit"><i class="fas fa-pencil-alt" style="color: #4389b8;"></i></div>
                        <img class="imgEmployee" [src]="employee.photos[3].employeePhoto" onerror="this.src='../../../../../assets/media/misc/no-image-default.png'" *ngIf="!photosArray[3].edit">
                        
                        <div style="width: 100%;" *ngIf="photosArray[3].edit">
                            <img class="imgEmployee " style="width: 100%;"  [src]="photosArray[3].photo.imageAsDataUrl" />
                        </div>
                        <label style="width: 100%; text-align: center; font-weight: bold; padding: 10px;">
                            Frente (Cubrebocas)
                        </label>
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2">
                    <div class="imgContainer">
                        <div class="imgButton" *ngIf="data.action == 'edit' && photosArray[4].edit" (click)="openWebcam(4)"><i class="fas fa-pencil-alt" style="color: #4389b8;"></i></div>
                        <img class="imgEmployee" [src]="employee.photos[4].employeePhoto" onerror="this.src='../../../../../assets/media/misc/no-image-default.png'" *ngIf="!photosArray[4].edit">
                        <div style="width: 100%;" *ngIf="photosArray[4].edit">
                            <img class="imgEmployee " style="width: 100%;"  [src]="photosArray[4].photo.imageAsDataUrl" />
                        </div>
                        <label style="width: 100%; text-align: center; font-weight: bold; padding: 10px;">
                            -30° (Cubrebocas)
                        </label>
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2">
                    <div class="imgContainer">
                        <div class="imgButton" *ngIf="data.action == 'edit' && photosArray[4].edit" (click)="openWebcam(5)"><i class="fas fa-pencil-alt" style="color: #4389b8;"></i></div>
                        <img class="imgEmployee" [src]="employee.photos[5].employeePhoto" onerror="this.src='../../../../../assets/media/misc/no-image-default.png'" *ngIf="!photosArray[5].edit">
                        <div style="width: 100%;" *ngIf="photosArray[5].edit">
                            <img class="imgEmployee " style="width: 100%;"  [src]="photosArray[5].photo.imageAsDataUrl" />
                        </div>
                        <label style="width: 100%; text-align: center; font-weight: bold; padding: 10px;">
                            30° (Cubrebocas)
                        </label>
                    </div>
                </div>
                -->
                <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 5px;">
                    <label><b>Nombre del empleado</b></label>
                    <input type="text" disabled class="form-control" [value]="fullNameEmployee">
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 5px;">
                    <label><b>Número de empleado</b></label>
                    <input type="text" disabled class="form-control" [value]="employee.employeeNumber">
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Empresa</b></label>
                    <input type="text" disabled class="form-control" [value]="employee.companyName">
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Proyecto</b></label>
                    <input type="text" disabled class="form-control" [value]="employee.projectName">
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Convenio</b></label>
                    <input type="text" disabled class="form-control" [value]="employee.agreementName">
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Departamento</b></label>
                    <input type="text" disabled class="form-control" [value]="employee.departmentName">
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Puesto</b></label>
                    <input type="text" disabled class="form-control" [value]="employee.positionName">
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Periodo</b></label>
                    <input type="text" disabled class="form-control" [value]="employee.period">
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Rol</b></label>
                    <input type="text" disabled class="form-control" [value]="employee.rol">
                </div>
                <div class="col-6 mt-4" style="padding: 5px;">
                    <div ><input type="checkbox" id="chekExit" [disabled]="data.action != 'edit'" [checked]="chechBox" (click)="recordChange()" style="margin: 10px;"><label for="chekExit" style="cursor: pointer; display: inline;">Privilegios de toma de asistencia</label></div>
                </div>
               
                <div class="col-6" style="padding: 5px;">
                    <label><b>Lugar de trabajo</b></label>
                    <input type="text" disabled class="form-control" [value]="employee.workplaceName">
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Turno Vigente</b></label>
                    <select class="form-control"placeholder="Seleccione un turno" [disabled]="data.action == 'view' || data.action == 'edit'" [value]="employee.shiftId" (change)="changeShift($event.target.value)">
                        <option [selected]="!employee.shiftId" [value]="null">Selecciona un turno</option>
                        <option *ngFor="let shift of shifts" [value]="shift.shiftId" [selected]="shift.shiftId == employee.shiftId">{{shift.shiftName}}</option>
                    </select>
                    
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Destino de costo</b></label>
                    <!-- <input type="text" disabled class="form-control" [value]="employee.costDestinationName"> -->
                    <select class="form-control" (change)="changeCostDestination($event.target.value)"  [value]="employee.costDestinationId" [disabled]="data.action == 'view' || data.action == 'edit'">
                       
                        <option  *ngFor="let cost of costDestinations" [value]="cost.costDestinationId" [selected]="cost.costDestinationId == employee.costDestinationId">{{cost.costDestinationIdIca}} - {{cost.costDestinationName}}</option>
                    </select>
                </div>
                
                <div class="col-6" style="padding: 5px;">
                    <label><b>Vigencia de turno</b></label>
                    <input type="date" [value]="employee.effectiveDateShift" class="form-control" [disabled]="data.action == 'view' || data.action == 'edit'" (change)="employee.effectiveDateShift = $event.target.value">
                </div>
               <!-- effectiveDateCostDestinationEnd
                effectiveDateShiftEnd-->
                
                <div class="col-6" style="padding: 5px;">
                    <label><b>Vigencia de destino de costo nuevo</b></label>
                    <input type="date" [value]="employee.effectiveDateCostDestination" class="form-control" [disabled]="data.action == 'view' || data.action == 'edit'" (change)="employee.effectiveDateCostDestination = $event.target.value">
                </div>
                <div class="col-6" style="padding: 5px;">
                    <label><b>Vigencia de turno Fin</b></label>
                    <input type="date" [value]="employee.effectiveDateShiftEnd" class="form-control" [disabled]="data.action == 'view' || data.action == 'edit'" (change)="employee.effectiveDateShiftEnd = $event.target.value">
                </div>
               <!-- effectiveDateCostDestinationEnd
                effectiveDateShiftEnd-->
                
                <div class="col-6" style="padding: 5px;">
                    <label><b>Vigencia de destino de costo nuevo Fin</b></label>
                    <input type="date" [value]="employee.effectiveDateCostDestinationEnd" class="form-control" [disabled]="data.action == 'view' || data.action == 'edit'" (change)="employee.effectiveDateCostDestinationEnd = $event.target.value">
                </div>
            </div>
        </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
    <button type="button" *ngIf="data.action == 'edit'" class="btn btn-primary" (click)="editEmployee()">Guardar</button>
  </div>