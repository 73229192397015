import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutService } from '../../../../../core';
import { UserModel } from '../../../../../../modules/auth/_models/user.model';
import { AuthService } from '../../../../../../modules/auth/_services/auth.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-user-dropdown-inner',
  templateUrl: './user-dropdown-inner.component.html',
  styleUrls: ['./user-dropdown-inner.component.scss'],
})
export class UserDropdownInnerComponent implements OnInit {
  extrasUserDropdownStyle: 'light' | 'dark' = 'light';
  user$: Observable<UserModel>;
  
  constructor(private layout: LayoutService, private auth: AuthService, private CookieService: CookieService) {}

  ngOnInit(): void {
   /* this.extrasUserDropdownStyle = this.layout.getProp(
      'extras.user.dropdown.style'
    );*/
    
    this.extrasUserDropdownStyle = 'light';
    const user = new UserModel();
    
    user.id = Number(this.CookieService.get('userID'));
    user.fullname = this.CookieService.get('fullName');
    this.auth.currentUserValue = user;
  this.user$ = this.auth.currentUserSubject.asObservable();
     //this.user$ = this.CookieService.get('fullName');
  }

  logout() {
    this.auth.logout();
    document.location.reload();
  }
}
