import { Component,ChangeDetectorRef, OnInit } from '@angular/core';
import { LayoutService } from '../../../../../core';
import { Observable } from 'rxjs';
import { UserModel } from '../../../../../../modules/auth/_models/user.model';
import { AuthService } from '../../../../../../modules/auth/_services/auth.service';
import { NotificacionsService } from "../../../../../../services/notifications/notificacions.service";
import {  NgbModalConfig,  NgbModal,  NgbModalRef,} from "@ng-bootstrap/ng-bootstrap";
import { IFilterNotifications } from 'src/app/interface/IFilterNotificatios';
import * as $ from "jquery";
@Component({
  selector: 'app-user-offcanvas',
  templateUrl: './user-offcanvas.component.html',
  styleUrls: ['./user-offcanvas.component.scss'],
  providers: [NgbModal, NgbModalConfig, NotificacionsService],
})
export class UserOffcanvasComponent implements OnInit {
  extrasUserOffcanvasDirection = 'offcanvas-right';
  user$: Observable<UserModel>;
  dataNotifications:any;
  view:any = 1;
  notificationId:any;
  modal: NgbModalRef;
  checked:any= [true,false];
   imgUser:any;
   filter: IFilterNotifications = {};
   periodConfigStatus:any;
   periodStatus:number;
   time:any;
  constructor(private layout: LayoutService,private _cdr: ChangeDetectorRef, config: NgbModalConfig,
    public modalService: NgbModal, private auth: AuthService, private notiService:NotificacionsService) {
      config.backdrop = "static";
    config.keyboard = false;
    }

  ngOnInit(): void {
    //this.getNotifications();
    this.cronJobTask();
    this.getPeriodConfig();
    this.extrasUserOffcanvasDirection = `offcanvas-${this.layout.getProp(
      'extras.user.offcanvas.direction'
    )}`;
    this.user$ = this.auth.currentUserSubject.asObservable();
    
   
    
  }
  async getPeriodConfig()
  {
    this.periodConfigStatus = await this.notiService.getPeriodConfig();
    if(this.periodConfigStatus.length>0)
    {
      this.periodConfigStatus = this.periodConfigStatus[0].configType;
  
      if(this.periodConfigStatus===0)
      {
        this.checked[0] = true;
        this.checked[1]= false;
      }
      else
      {
        this.checked[0] = false;
        this.checked[1]= true;
      }
    }
   
  }
async getNotifications()
{
  this.filter.status = 0;
  this.dataNotifications = await this.notiService.getNotifications(this.filter);
 
  this.chngeView(this.view);
  this._cdr.detectChanges();
    console.log(this.dataNotifications);
}
readNotifi(id:any, modal:any, event:any)
{
  //readNotification()
  
  if(event.receivingUserId.userImage!="")
  {
     this.imgUser = event.receivingUserId.userImage
  }
  else{
    this.imgUser = "https://icatest.s3.us-east-2.amazonaws.com/employee/1/1613081628759_photo"
  }
  this.notificationId = {
    subject:event.messageNotification,
    description:event.messageNotification,
    userId:event.receivingUserId.userId,
    fullName:event.receivingUserId.firstName + " " + event.receivingUserId.lastName,
    position:'Desarrollador'
  }
  //document.getElementById("idPuestos").classList.add('border-primary','border-bottom','border-3');
  document.getElementById(id).classList.remove('notification');
  this.modal = this.modalService.open(modal, { centered: true, size:"md" });
  this.notiService.readNotification(id,1);
}

 changePeriod()
{

  let body:any;
  let radio:any;
  radio = + $('input[name="customRadioInline"]:checked').val();
  body={
    configType:radio
  }
  

  this.notiService.statusPeriod(1,body);

}

chngeView(value){
  this.view = value;
  /*if(value == 1){
    
  }
  else{
   
  }*/
}
  logout() {
    this.auth.logout();
    document.location.reload();
  }
  cronJobTask()
  {
    this.time = setInterval(()=>{
      this.getNotifications();
    },60*1000)
  }
}
