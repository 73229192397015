import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { ProfilesService } from "src/app/services/settings/profiles/profiles.service";
import { NgbModalConfig, NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validator, Validators, FormBuilder } from '@angular/forms';
import { JustificationsService } from "../../../../services/justifications/justifications.service";
import { SharedVarServiceService } from 'src/app/services/SharedVarService/shared-var-service.service';
import Swal from 'sweetalert2';
import { UsersService } from "../../../../services/settings/users/users.service";
@Component({
  selector: 'app-exculpatory',
  templateUrl: './exculpatory.component.html',
  styleUrls: ['./exculpatory.component.scss'],
  providers:[NgbModal,NgbModalConfig,ExculpatoryComponent]
})
export class ExculpatoryComponent implements OnInit {
    headertitle:string="Justificantes"
    searchVal = "";
    tableData: any;
    data:any ;
    countRows:any;
    tblExculpatory:boolean;
    selectedItems: any[];
    constructor(
        private _cdr: ChangeDetectorRef, 
        private profilesServices:ProfilesService, 
        private justificationService:JustificationsService,
        public usersService: UsersService,
        private sharedVar: SharedVarServiceService
    ) { }

    ngOnInit(): void {
        this.sharedVar.setValue("<i ></i> Asistencia • Justificantes");
        this.fillTable();

    }
    
    async fillTable() {
      
        this.data = await this.justificationService.getJustifications();
        console.log(this.data)
        this.countRows = this.data.length;
        this.tableData = {
            limitCol: 8,
            actions: false,
            headers:[{
                text: "", 
                key: "",
                width:5,
                check: true
                  },
                  {
                text: "EMPLEADO",
                key: "employeeName",
                width: 15
            }, {
                text: "PROYECTO",
                key: "project",
                width: 20
            }, {
                text: "INCIDENCIA",
                key: "incidence",
                width: 20
            }, {
                text: "VECTOR",
                key: "vector",
                width: 5
            }, {
                text: "FECHA DE JUSTIFICACIÓN",
                key: "date",
                width: 18
            },
            {
                text: "Estatus",
                key: "tagStatus",
                tag: true,
                const: true,
                width: 17,
                tagOptions:[{
                  value: 1,
                  colorBg:'#67BC71',
                  colorText: 'black',
                  text: 'Aplicado'
                },{
                  value: 0,
                  colorBg:'gray',
                  colorText: 'white',
                  text: 'Sin Aplicar'
                }]
              }
        ],
        data: this.data
      };
      this.tblExculpatory=true;
      this._cdr.detectChanges();
    }
 syncExculpatory()
{
    let res:any;
     Swal.fire({
        title: 'Espere',
        text: '¿Está seguro que quiere sincronizar la información de los Justificantes? ' ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, sincronizar',
        cancelButtonText: 'No, cancelar'
    }).then((result) => {
        if (result.value) {
            res = this.syncUpData();  
            console.log(res);
            
            
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            return;
        }
    });
    

}
async syncUpData()
{
    let res:any
    res = await this.justificationService.getSyncUpJustifications();
  if(res.statusCode== 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'Se actualizaron los justificantes correctamente',
                    text: 'Éxito'
                });
                
                this.tblExculpatory=false;
                this.fillTable();
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocurrió un error al sincronizar la información de justificantes'
                });
            }
            return res;
}
async sendExculpatory()
{
    
    let res:any;
        let banResult:boolean;
    if(this.selectedItems && this.selectedItems.length > 0)
    {

        console.log(this.selectedItems);
        
        for (let index = 0; index < this.selectedItems.length; index++) {
            res = await this.applyJustification(this.selectedItems[index].justificationId);
          
            if(res== 201)
            {
                banResult = true;
            }
            else{
                banResult = false;
            }

            
        }
       
    }
    this.showResult(banResult);
}

showResult(res:boolean)
{
    if(res)
    {
        Swal.fire({
            icon: 'success',
            title: 'Se aplicarón los justificantes correctamente',
            text: 'Éxito'
        });
        this.tblExculpatory=false;
        this.fillTable();
    }
    else
    {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error al aplicar los justificantes'
        });
        this.tblExculpatory=false;
        this.fillTable();
        
    }
}
async applyJustification(id:number)
{
   let res = await this.justificationService.applySyncUpJustifications(id);
   return res;
}
}
