<div class="modal-header">
    <h4 class="modal-title"><b>Editar integrante de grupo de trabajo</b></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block;">
                    <label><b>Nombre del integrante</b></label>
                    <input type="text" class="form-control">
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block;">
                    <label><b>Puesto</b></label>
                    <select class="form-control">
                        <option value="" selected>Seleccione un puesto</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Nombre del grupo de trabajo</b></label>
                    <select class="form-control">
                        <option value="" selected>Seleccione un proyecto</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Empresa</b></label>
                    <select class="form-control">
                        <option value="" selected>Seleccione un convenio</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Proyecto</b></label>
                    <select class="form-control">
                        <option value="" selected>Seleccione un departamento</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Convenio</b></label>
                    <select class="form-control">
                        <option value="" selected>Seleccione un destino de costo</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Departamento</b></label>
                    <select class="form-control">
                        <option value="" selected>Seleccione un destino de costo</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Destino de costo</b></label>
                    <select class="form-control">
                        <option value="" selected>Seleccione un destino de costo</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Turno</b></label>
                    <select class="form-control">
                        <option value="" selected>Seleccione un turno</option>
                    </select>
                </div>
            </div>
        </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
    <button type="button" class="btn btn-primary">Guardar</button>
  </div>
