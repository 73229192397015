import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CompaniesService } from '../../../../../services/companies/companies.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShiftsService } from '../../../../../services/shifts/shifts.service';
import Swal from 'sweetalert2';
import { ScheduleService } from '../../../../../services/schedule/schedule.service';

@Component({
  selector: 'app-add-schedule-modal',
  templateUrl: './add-schedule-modal.component.html',
  styleUrls: ['./add-schedule-modal.component.scss']
})
export class AddScheduleModalComponent implements OnInit {
@Input() data: any;
companies: any;
tmpShifts: any;
shifts: any = [];
  info: any = {};
selectedShift: any = "";
note: any = "";

  constructor(
    private companiesService: CompaniesService,
    private shiftsService: ShiftsService,
    public activeModal: NgbActiveModal,
    private _cdr: ChangeDetectorRef,
    private _scheduleService: ScheduleService
  ) { }

  async ngOnInit() {
    this.companies = await this.companiesService.getComapnies();
    this.tmpShifts = await this.shiftsService.getShifts();
    this.getInfo(this.companies);
    this.filterShifts();
  }

  getInfo(data) {
    console.log('data',  this.data)
    let tmpData = {
      companyId: data[0].companyId,
      companyName: data[0].companyName,
      projectId: this.data.project.projectId,
      projectName: this.data.project.projectName,
      agreementId: this.data.department.employees[0].agreementId || '',
      agreementName: this.data.department.employees[0].agreementName || '',
      departmentId: this.data.department.departmentId,
      departmentName: this.data.department.departmentName
    };
    this.info = tmpData;
  }

  filterShifts(){
    for(let i = 0; i < this.tmpShifts.length; i++){
      if(this.tmpShifts[i].projectId == this.data.project.projectId){
        this.shifts.push(this.tmpShifts[i]);
      }
    }
    console.log(this.shifts);
    this._cdr.detectChanges();
  }

  getShift(shift){
    this.selectedShift = shift;
  }

  getNotes(note){
    this.note = note;
  }

  saveShift(){
    Swal.fire({  
      title: 'Espere',  
      text: '¿Está seguro que desea agregar el turno?',  
      icon: 'warning',  
      showCancelButton: true,  
      confirmButtonText: 'Sí, agregar',  
      cancelButtonText: 'No, cancelar'  
    }).then((result) => {  
      if (result.value) {  
        this.confirm();
      } else if (result.dismiss === Swal.DismissReason.cancel) {  
        return;
      }  
    })  
  }

  async confirm(){
    let params = {
      companyId: this.info.companyId,
      projectId: this.info.projectId,
      agreementId: this.info.agreementId,
      departmentId: this.info.departmentId,
      shiftId: this.selectedShift,
      notes: this.note
    }
    console.log(params);
    let res = await this._scheduleService.createShiftSchedule(params);
    console.log(res)
    if(res['statusCode'] == 201){
      Swal.fire({  
        icon: 'success',  
        title: 'Se agregó el turno correctamente',
        text: 'Éxito'
      });
      this.activeModal.close(true);
    }
    else{
      Swal.fire({  
        icon: 'error',  
        title: 'Oops...',  
        text: 'Ocurrió un error al agregar al turno inténtalo más tarde' 
      });
    }
  }

}
