import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private apiService: ApiService
  ) { }

  async getschedules(){
    const responseEmployees: any = await this.apiService.get("schedule");
      if(responseEmployees.statusCode === 200){
        return responseEmployees.data.items;
      }
  }
  async createShiftSchedule(params){
    const createShiftResponse = await this.apiService.post(`schedule`, params)
    return createShiftResponse;
  }

  async getAttendance(period){
    const responseAttendance: any = await this.apiService.get(`attendance?periodId=${period}`);
    if(responseAttendance.statusCode === 200){
      return responseAttendance.data.items;
    }
  }

  async getAttendanceByPeriod(period,projectId,agreementId,departmentId){
    const responseAttendance: any = await this.apiService.get(`attendance/getAttendanceByPeriod?periodId=${period}
    &projectId=${projectId}&agreementId=${agreementId}&departmentId=${departmentId}`);
    if(responseAttendance.statusCode === 200){
      return responseAttendance.data.items;
    }
  }
  // async getTotalEmployeesByDepartment(period,projectId,agreementId,departmentId){
  //   const responseTotalEmp: any = await this.apiService.get(`attendance/getTotalEmployeeByDepartment?periodId=${period}
  //   &projectId=${projectId}&agreementId=${agreementId}&departmentId=${departmentId}`);
  //   if(responseTotalEmp.statusCode === 200){
  //     return responseTotalEmp.data;
  //   }
  // }

  async getTotalEmployeesByDepartment(period,projectId,agreementId){
    const responseTotalEmp: any = await this.apiService.get(`attendance/getTotalEmployeeByDepartment?periodId=${period}
    &projectId=${projectId}&agreementId=${agreementId}`);
    if(responseTotalEmp.statusCode === 200){
      return responseTotalEmp.data;
    }
  }

  async getProjectsIsApproved(period,projectId){
    const responseTotalEmp: any = await this.apiService.get(`attendance/getProjectsIsApproved?periodId=${period}
    &projectId=${projectId}`);
    if(responseTotalEmp.statusCode === 200){
      return responseTotalEmp.data;
    }
  }

  async getAttendanceEmployee(employeeId, periodId){
    const responseAttendance: any = await this.apiService.get(`attendance/${employeeId}/${periodId}`);
    if(responseAttendance.statusCode === 200){
      return responseAttendance.data.items;
    }
  }

  async approveAttendance(params, periodId){
    const responseAttendance: any = await this.apiService.put(`attendance`,periodId,params);
    return responseAttendance;
  }
  
}

