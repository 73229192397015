import { Component, Input, OnInit,  ChangeDetectorRef } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShiftsService } from '../../../../services/shifts/shifts.service';
import { CompaniesService } from '../../../../services/companies/companies.service';
import { EmployeesService } from '../../../../services/employees/employees.service';
import Swal from 'sweetalert2';
import {FormGroup,FormControl,Validator,Validators,FormBuilder,FormArray,} from "@angular/forms";
import { makeStateKey } from '@angular/platform-browser';
@Component({
  selector: 'app-work-groups-modal',
  templateUrl: './work-groups-modal.component.html',
  styleUrls: ['./work-groups-modal.component.scss']
})
export class WorkGroupsModalComponent implements OnInit {
  @Input() dataI: any;

  constructor(
    public activeModal: NgbActiveModal,
    public _companiesService: CompaniesService,
    public _shiftsService: ShiftsService,
    private fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    public _employeesService: EmployeesService
  ) {
    this.formData();
   }

  companies: any = [];
  projects: any = [];
  agreements: any = [];
  departments: any = [];
  costDestination: any = [];
  shifts: any = [];
  leaders: any = [];
  subordinates: any = [];
  employees: any = [];
  tmpLeaders: any = [];
  tmpSubordinates: any = [];
  leadersSelected : any = [];
  subordinatesSelected:any = [ ];
  data= {
    workgroupName: '',
    companyId: '',
    projectId: '',
    agreementId: '',
    departmentId: '',
    shiftId: '',
    costDestinationId: '',
    leaders:[],
    subordinates: []
    
  };
  employeesWithWorkGroup:any=[];
  public formdata: FormGroup;

  async ngOnInit() {
    console.log(this.dataI);
    if(this.dataI.action == 'edit'){
      
      this.data = await this._employeesService.getWorkGroupById(this.dataI.data.workgroupId);
      this.getProjects(this.data.companyId);
      this.getAgreements(this.data.projectId);
      this.getDepartments(this.data.agreementId);
      this.getCostDestination(this.data.departmentId);
      this.getShifts(this.data.projectId);
      this.getEmployees(this.data.projectId);
      this.fillDataLeaders(this.data);
    }
    this.companies = await this._companiesService.getComapnies();
  
  }

  fillDataLeaders(data:any)
  {
    
    for (let index = 0; index < data.leaders.length; index++) {
      this.leadersSelected.push(data.leaders[index].employeeId);
    }
    for (let index = 0; index < data.subordinates.length; index++) {
      this.subordinatesSelected.push(data.subordinates[index].employeeId);
      
    }

    let fgLeaders = this.formdata;
    fgLeaders.patchValue(
    {
      leaders:this.leadersSelected,
      subordinates:this.subordinatesSelected
    }

  );
  }
  formData() {
    return(
     this.formdata = this.fb.group({
      leaders: [null, Validators.required],
      subordinates: [null, Validators.required]
      
    }));
  }

  async getProjects(company){
    this.projects = [];
    this.agreements = [];
    this.departments = [];
    this.costDestination = [];
    this.shifts = [];
    this.employees = [];
    this.tmpLeaders = [];
    this.tmpSubordinates = [];
    this.data.companyId = company;
    if(company != ''){
      let tmpCompanies = await this._companiesService.getProjects();
      for(let i = 0; i < tmpCompanies.length; i++){
        if(tmpCompanies[i].companyId == company){
          this.projects.push(tmpCompanies[i]);
        }
      }
    }
  }

  async getAgreements(project){
    this.agreements = [];
    this.departments = [];
    this.costDestination = [];
    this.employees = [];
    this.tmpLeaders = [];
    this.tmpSubordinates = [];
    this.data.projectId = project;
    if(project != ''){
      let tmpAgreements = await this._companiesService.getAgreements();
      for(let i = 0; i < tmpAgreements.length; i++){
        if(tmpAgreements[i].projectId == project){
          this.agreements.push(tmpAgreements[i]);
        }
      }
    }
    this.getShifts(project);
  }

  async getDepartments(agreement){
    this.departments = [];
    this.costDestination = [];
    this.tmpLeaders = [];
    this.tmpSubordinates = [];
    this.data.agreementId = agreement;
    let tmpDepartments = await this._companiesService.getDepartments();
    for(let i = 0; i < tmpDepartments.length; i++){
      if(tmpDepartments[i].agreementId == agreement){
        this.departments.push(tmpDepartments[i]);
      }
    }
    console.log(this.departments);
  }

  async getCostDestination(department){
    this.costDestination = [];
    this.tmpLeaders = [];
    this.tmpSubordinates = [];
    this.data.departmentId = department;
    if(department != ''){
      let tmpCostDestination = await this._companiesService.getCostDestination();
      this.costDestination = tmpCostDestination;
    }
    this.getEmployees(department);
  }

  async getShifts(project){
    this.shifts = [];
    if(project != ''){
      let tmpShifts = await this._shiftsService.getShifts();
      for(let i = 0; i < tmpShifts.length; i++){
        if(tmpShifts[i].projectId == project){
          this.shifts.push(tmpShifts[i]);
        }
      }
    }
  }

  async getEmployees(department){
    this.employees = [];
    if(department != ''){
      this.employees = await this._employeesService.getEmployees();
      this.employees.reduce((employee)=>(employee.departmentId==department));
    }
    this.getTemps();
  }

  getTemps(){
    this.tmpLeaders = [];
    this.tmpSubordinates = [];
    if(this.subordinates.length == 0){
      this.tmpLeaders = this.employees;
     
    }
    if(this.leaders.length == 0){
      this.tmpSubordinates = this.employees;
    }

  }
  changeWorkGroup(data,index)
  {
    console.log(data);
    this.employeesWithWorkGroup.splice(index,1);
    
  }

  async getLeaders(leaders){
    
    this.employeesWithWorkGroup=[];
    let position = leaders.length-1;
    let reviewIdEmployee = leaders[position];
    
    //console.log(response);
//Buscar si hay un Id diferente a los existentes y verificar si tiene id de grupo de trabajo asignado, si no limpiar el array de
//empleados con grupo de trabajo 
//let remove = this.diasSemana.map(function(item){return item.item_id;}).indexOf(item.item_id);
  //this.diasSemana.splice(remove,1);
  
 // console.log(this.diasSemana);

    for (let indexL = 0; indexL < leaders.length; indexL++) {
      var idx = this.leadersSelected.indexOf(leaders[indexL]);
    if (idx == -1) {
      let response = await this._employeesService.getEmployeeById(leaders[indexL]);
      if(response.workgroupId!=null)
      {
        let object = 
        {
          employeeId:response.employeeId,
          workgroupId:response.workgroupId,
          employeeIdName: response.employeeId + "-" + response.employeeName
        }
        this.employeesWithWorkGroup.push(object);
      }
    }
      
    }
  
  


    
    
    this.leaders = [];
    for(let i = 0; i < leaders.length; i++){
      let tmpLeader = {
        employeeId: leaders[i]
      }
      this.leaders.push(tmpLeader);
    }
    this.tmpSubordinates = Object.assign([],this.employees);
    for(let j = 0; j < this.tmpSubordinates.length; j++){
      for(let k = 0; k < this.leaders.length; k++){
        if(this.tmpSubordinates[j].employeeId == this.leaders[k].employeeId){
          this.tmpSubordinates.splice(j,1);
        }
      }
    }
  }

  getSubordinates(sub){
    this.subordinates = [];
    for(let i = 0; i < sub.length; i++){
      let tmpSub = {
        employeeId: sub[i]
      };
      this.subordinates.push(tmpSub);
    }
    this.tmpLeaders = Object.assign([], this.employees);
    for(let j = 0; j < this.tmpLeaders.length; j++){
      for(let k = 0; k < this.subordinates.length; k++){
        if(this.tmpLeaders[j].employeeId == this.subordinates[k].employeeId){
          this.tmpLeaders.splice(j,1);
        }
      }
    }
  }

  saveWorkGroup(){
    
    this.data.leaders = Object.assign([], this.leaders);
    this.data.subordinates = Object.assign([], this.subordinates);
    let text;
    let textButton;
    if(this.dataI.action!="edit")
    {
      text = '¿Está seguro que desea crear el grupo de trabajo?';
      textButton = 'Sí, crear';
    }
    else{
      text = '¿Está seguro que desea editar el grupo de trabajo?'; 
      textButton = 'Sí, actualizar';
    }

    Swal.fire({  
      title: 'Espere',  
      text: text,  
      icon: 'warning',  
      showCancelButton: true,  
      confirmButtonText: textButton,  
      cancelButtonText: 'No, cancelar'  
    }).then((result) => {  
      if (result.value) {  
        this.confirm();
      } else if (result.dismiss === Swal.DismissReason.cancel) {  
        return;
      }  
    }) 
  
    
  }

  async confirm(){
    
    console.log(this.data);
    if(this.dataI.action!="edit")
    {
        
    let create = await this._employeesService.createWorkGroup(this.data);
    
    if(create.statusCode && create.statusCode == 201){
      Swal.fire({  
        title: 'Exito',  
        text: 'Se creo el grupo de trabajo correctamente',  
        icon: 'success',  
        showCancelButton: false,  
        confirmButtonText: 'Ok', 
      });
      this.activeModal.close(true);
    }
    else{
      Swal.fire({  
        title: 'Ups',  
        text: 'Ocurrió un error al guardar el grupo de trabajo',  
        icon: 'error',  
        showCancelButton: false,  
        confirmButtonText: 'Ok', 
      });
    }
    }
    if(this.dataI.action=="edit"){
      let dataEdit:any = Object.assign([],this.data);
      let workgroupId : any = dataEdit.workgroupId;
       let objetEdit : any ={
        agreementId: dataEdit.agreementId,
        companyId: dataEdit.companyId,
        costDestinationId: dataEdit.costDestinationId,
        departmentId: dataEdit.departmentId,
        leaders: null,
        projectId: dataEdit.projectId,
        shiftId: null,
        subordinates: null,
        tagActive: dataEdit.tagActive,
        tagDelete: dataEdit.tagDelete,
        workgroupName: dataEdit.workgroupName
       };
/*agreementId: 2
companyId: 1
costDestinationId: 6
departmentId: 22
leaders: [1047]
projectId: 1
shiftId: 2
subordinates: (2) [960, 965]
tagActive: 1
tagDelete: 0
workgroupName: "TestforCreateworkgroup"*/

    objetEdit.shiftId = +this.data.shiftId;
    let arrayLeaders: any =[];
    for (let index = 0; index < this.formdata.value.leaders.length; index++) {
       let objectLeaders = 
       {
          employeeId:this.formdata.value.leaders[index]
       }
       arrayLeaders.push(objectLeaders);
      
    }
    let arraySubordinates: any =[];
    for (let index = 0; index < this.formdata.value.subordinates.length; index++) {
       let objectSubordinates = 
       {
          employeeId:this.formdata.value.subordinates[index]
       }
       arraySubordinates.push(objectSubordinates);
      
    }
    objetEdit.leaders = arrayLeaders;
    objetEdit.subordinates = arraySubordinates;
    
     
      console.log(objetEdit);

      let editWG:any = await this._employeesService.updateWorkGroup(workgroupId,objetEdit);
    
    if(editWG.statusCode && editWG.statusCode == 200){
      Swal.fire({  
        title: 'Éxito',  
        text: 'Se actualizó el grupo de trabajo correctamente',  
        icon: 'success',  
        showCancelButton: false,  
        confirmButtonText: 'Ok', 
      });
      this.activeModal.close(true);
    }
    else{
      Swal.fire({  
        title: 'Ups',  
        text: 'Ocurrió un error al guardar el grupo de trabajo',  
        icon: 'error',  
        showCancelButton: false,  
        confirmButtonText: 'Ok', 
      });
    }
    }
  }

}