import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { SharedVarServiceService } from '../../../../services/SharedVarService/shared-var-service.service';
import { EmployeesService } from '../../../../services/employees/employees.service';
import { WorkGroupsModalComponent } from '../work-groups-modal/work-groups-modal.component';
import { WorkGroupEmployeeModalComponent } from '../work-group-employee-modal/work-group-employee-modal.component';
import Swal from 'sweetalert2';
import {  NgbModalConfig,  NgbModal,  NgbModalRef,} from "@ng-bootstrap/ng-bootstrap";
import {  FormGroup,  FormControl,  Validator,  Validators,  FormBuilder,  FormArray,} from "@angular/forms";
import { UsersService } from "../../../../services/settings/users/users.service";
import { Router } from "@angular/router";
@Component({
  selector: 'app-work-groups',
  templateUrl: './work-groups.component.html',
  styleUrls: ['./work-groups.component.scss'],
  providers: [NgbModal, NgbModalConfig]
})
export class WorkGroupsComponent implements OnInit {

  tableData: any;
  data: any= [];
  searchVal = "";
  title = "Grupos de trabajo";
  action: any = "";
  workGroupSelected: any;
  workGroupName:any;
  tableDataEmployees: any;
  members: any;
  tblEmployees:any=false;
  countRows:any;
  modalTitle:any="";
  workGroupId:any;
  indexPosition:any;
  public formdata: FormGroup;
  constructor(
    public modalService: NgbModal,
    private _employeesService: EmployeesService,
    private _cdr: ChangeDetectorRef,
    private sharedVar: SharedVarServiceService,
    public userService:UsersService,
    private router:Router
  ) {
    this.getWorkGroups();
    if(!userService.getProfilesModule(['GROUP_WORK']))
    {
     this.router.navigate([userService.routingPermissions()]);
    }
  }

  ngOnInit(): void {
    
  }

  async getWorkGroups(){
    
    this.data = [];
    this.data = await this._employeesService.getWorkGroups();
    for (let index = 0; index < this.data.length; index++) {
      this.data[index].indexPosition = index;
      
    }
    this.sharedVar.setValue("<i class='fa fa-user'></i> Empleados • Grupos de trabajo");
    this.action = "";
    console.log(this.data);
    this.countRows = this.data.length;
    this.tableData = {
      limitCol: 6,
      actions: true,
      actionsMenu: [{
        text: "Ver",
        icon: "fa fa-eye",
        action: 'view',
        permission:'GROUP_WORK_READ'
      },{
        text: "Editar",
        icon: "fas fa-pencil-alt",
        action: 'edit',
        permission:'GROUP_WORK_UPDATE'
      }],
      headers:[{
        text: "# DE GRUPO",
        key: "workgroupId",
        width:10
      },
      {
        text: "NOMBRE",
        key: "workgroupName"
      },{
        text: "PROYECTO",
        key: "projectName"
      },
      {
        text: "TURNO",
        key: "shiftName"
      },{
        text: "DESTINO DE COSTO",
        key: "costDestinationName",
        width:25
         },{
        text: "# INTEGRANTES",
        key: "members"
      }],
      data: this.data
    };
    console.log(this.data);
    this._cdr.detectChanges();
  }
  openModal(option){
    if(option == 1){
      let data = {
        action: 'create'
      };
      try {
      const modalRef = this.modalService.open(WorkGroupsModalComponent, {size:'xl'})
      modalRef.componentInstance.dataI = data;
        modalRef.result.then( res => {
          if (res != undefined) {
            this.getWorkGroups();
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
    else if(option == 2){
      let objectData:any={};
        
        
         objectData.action = 'edit';
          objectData.data = 
          {
            agreementId: this.data[this.indexPosition].agreementId,
            companyId: this.data[this.indexPosition].companyId,
            costDestinationId: this.data[this.indexPosition].costDestinationId,
            costDestinationName: this.data[this.indexPosition].costDestinationName,
            createdAt: this.data[this.indexPosition].createdAt,
            departmentId: this.data[this.indexPosition].departmentId,
            members: this.data[this.indexPosition].members,
            open: this.data[this.indexPosition].open,
            projectId: this.data[this.indexPosition].projectId,
            projectName: this.data[this.indexPosition].projectName,
            shiftId: this.data[this.indexPosition].shiftId,
            shiftName: this.data[this.indexPosition].shiftName,
            tagActive: this.data[this.indexPosition].tagActive,
            tagDelete: this.data[this.indexPosition].tagDelete,
            updatedAt: this.data[this.indexPosition].updatedAt,
            workgroupId: this.data[this.indexPosition].workgroupId,
            workgroupName: this.data[this.indexPosition].workgroupName
          };
      try {
        const modalRef = this.modalService.open(WorkGroupsModalComponent, {size:'xl'})
          modalRef.componentInstance.dataI = objectData;
          modalRef.result.then( res => {
            if (res != undefined) {
              this.getWorkGroups();
            }
          })
        } catch (error) {
          console.log(error)
        }
    }
  }
  async fillTableEmployees(data)
  {
    this.tblEmployees=false;
    this.members = [];
    let workGroupInfo = await this._employeesService.getWorkGroupById(data.data.workgroupId);
      this.workGroupSelected = Object.assign([],workGroupInfo); 
      this.workGroupName = workGroupInfo.workgroupName;
      for(let i = 0; i < this.workGroupSelected.leaders.length; i++){
        let tmpLeader = { 
          employeeId: this.workGroupSelected.leaders[i].employeeId,
          employeeName: this.workGroupSelected.leaders[i].employeeName,
          position: 'Líder',
          workgroupLeaderId: this.workGroupSelected.leaders[i].workgroupLeaderId,
          workgroupId: this.workGroupSelected.leaders[i].workgroupId
        };
        this.members.push(tmpLeader);
      }
      for(let i = 0; i < this.workGroupSelected.subordinates.length; i++){
        let tmpLeader = { 
          employeeId: this.workGroupSelected.subordinates[i].employeeId,
          employeeName: this.workGroupSelected.subordinates[i].employeeName,
          position: 'Miembro',
          workgroupSubordinateId: this.workGroupSelected.subordinates[i].workgroupSubordinateId,
          workgroupId: this.workGroupSelected.subordinates[i].workgroupId
        };
        this.members.push(tmpLeader);
      }
      this.countRows = this.members.length;
      
      this.tableDataEmployees = {
        limitCol: 2,
        actions: true,
        actionsMenu: [{
          text: "Eliminar",
          icon: "fas fa-trash-alt",
          action: 'delete',
          permission:'GROUP_WORK_UPDATE'
        }],
        headers:[{
          text: "NOMBRE",
          key: "employeeName"
        },
        {
          text: "PUESTO",
          key: "position"
        }],
        data: this.members
      };
      this.tblEmployees=true;
      this._cdr.detectChanges();
  }

  async show(data){
   
    console.log(data);
    this.workGroupId = data.data.workgroupId;
    this.indexPosition = data.data.indexPosition;
    if(data.action == 'view'){
      this.tblEmployees=false;
      this.members = [];
      this.action = data.action;
      this.sharedVar.setValue("<i class='fa fa-user'></i> Empleados • Grupos de trabajo - "+data.data.workgroupName);
      // this.members = this._employeesService.getWorkGroupMembers();
      //Construir Tabla de empleados
      this.fillTableEmployees(data);
    }
    else{
      try {
        const modalRef = this.modalService.open(WorkGroupsModalComponent, {size:'xl'})
          modalRef.componentInstance.dataI = data;
          modalRef.result.then( res => {
            if (res != undefined) {
              this.getWorkGroups();
            }
          })
        } catch (error) {
          console.log(error)
        }
    }
  }

  employeeWorkGroup(data){
    if(data.action == 'edit'){
      this.openModal(2);
    }
    if(data.action == 'delete'){
    
      Swal.fire({  
        title: 'Espere',  
        text: '¿Seguro que quieres eliminar al empleado del grupo de trabajo?',  
        icon: 'warning',  
        showCancelButton: true,  
        confirmButtonText: 'Si, Eliminar',  
        cancelButtonText: 'No, cancelar'  
      }).then((result) => {  
        if (result.value) {  
        
         this.deleteFromWorkGroup( data.data.workgroupId,data.data.employeeId,data);
      
         
        } else if (result.dismiss === Swal.DismissReason.cancel) {  
          return;
        }  
      }) 
    
    }
    console.log(data);
  }
  async deleteFromWorkGroup(workGroupId:any,employeeId:any,data)
  {
    let deleteEmployee:any = await this._employeesService.deleteWorkGroupUser(workGroupId,employeeId);
    
    if(deleteEmployee.statusCode && deleteEmployee.statusCode == 200){
      Swal.fire({  
        title: 'Éxito',  
        text: 'Se eliminó el empleado del grupo de trabajo correctamente',  
        icon: 'success',  
        showCancelButton: false,  
        confirmButtonText: 'Ok', 
      });
      this.fillTableEmployees(data);
    }
    else{
      Swal.fire({  
        title: 'Ups',  
        text: 'Ocurrió un error al eliminar el empleado del grupo de trabajo',  
        icon: 'error',  
        showCancelButton: false,  
        confirmButtonText: 'Ok', 
      });
    }
  }

}
