import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { SharedVarServiceService } from '../../../../services/SharedVarService/shared-var-service.service';
import { ScheduleService } from '../../../../services/schedule/schedule.service';
import { AddScheduleModalComponent } from './add-schedule-modal/add-schedule-modal.component';
import { CalendarService } from '../../../../services/settings/calendar/calendar.service';
import { UsersService } from "src/app/services/settings/users/users.service";
@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

    searchVal: any = "";
    daysList :any =[];
    data: any = [];
    searchProject: any = "";
    projects: any = [];
    dateSelect: any;
    filterData: any;
    total;
    schedules: any;
    shiftsList: any;
    departments: any = [];
    searchDepartment: any = "";
    searchShift: any = "";
    periods: any = [];

    constructor(
        private _scheduleService: ScheduleService,
        private _cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        public usersService: UsersService,
        private sharedVar: SharedVarServiceService
    ) {
        this.getDays();
    }

    ngOnInit() {
        this.sharedVar.setValue("<i class='far fa-calendar-alt'></i> Asistencia • Turnos");
        this.getInfo();
    }

    async getDays(){
        let actualDate = new Date();
        let dayWeek = actualDate.getDay();
        if( dayWeek > 0 ){
            let difDay = dayWeek-1;
            actualDate.setDate(actualDate.getDate()-difDay);
        }
        for( let i = 0; i < 7; i++ ){
            let tmpDay: any = {};
            tmpDay = {
                day: actualDate.getDate(),
                month: this.getMonth(actualDate.getMonth()),
                weekDay: this.getWeekDay(actualDate.getDay())[0],
                date: moment(actualDate).format('YYYY-MM-DD'),
                dayEn: this.getWeekDay(actualDate.getDay())[1]
            };
            this.daysList.push(tmpDay);
            actualDate.setDate(actualDate.getDate()+1);
        }
    }

    getMonth( value ){
        switch(value){
            case 0:
                return 'Ene'
                break;
            case 1:
                return 'Feb'
                break;
            case 2:
                return 'Mar'
                break;
            case 3:
                return 'Abr'
                break;
            case 4:
                return 'Mayo'
                break;
            case 5:
                return 'Jun'
                break;
            case 6:
                return 'Jul'
                break;
            case 7:
                return 'Ago'
                break;
            case 8:
                return 'Sep'
                break;
            case 9:
                return 'Oct'
                break;
            case 10:
                return 'Nov'
                break;
            case 11:
                return 'Dic'
                break;
        }
    }

    getWeekDay( value ){
        switch( value ){
            case 1:
                return ['Lun','monday'];
                break;
            case 2:
                return ['Mar', 'tuesday'];
                break;
            case 3:
                return ['Mié', 'wednesday'];
                break;
            case 4:
                return ['Jue', 'thursday'];
                break;
            case 5:
                return ['Vie', 'friday'];
                break;
            case 6:
                return ['Sáb', 'saturday'];
                break;
            case 0:
                return ['Dom', 'sunday'];
                break;
        }
    }

    searchDate(date){
        let actualDate = new Date(date);
        actualDate.setDate(actualDate.getDate()+1);
        let tmpDay: any = {};
        this.daysList = [];
        tmpDay = {
        day: actualDate.getDate(),
            month: this.getMonth(actualDate.getMonth()),
            weekDay: this.getWeekDay(actualDate.getDay())[0],
            date: moment(actualDate).format('YYYY-MM-DD'),
            dayEn: this.getWeekDay(actualDate.getDay())[1]
        };
        this.daysList.push(tmpDay);
        actualDate.setDate(actualDate.getDate()+1);
    }

    cleanDate(){
        this.dateSelect = "";
        this.daysList = [];
        this.getDays();
        this._cdr.detectChanges();
    }

    getSchedule( data ){
        this.data = [];
        this.filterData = [];
        let tempProject = {};
        let tmpInfo = {};
        let tmpDepartment = [];
        let tmpEmployee = [];
        this.projects = [];
        this.shiftsList = [];
        let tmpshift = {};

        if( data.length > 0 ){
            tempProject['projectId'] = data[0].projectId;
            tempProject['projectName'] = data[0].projectName;
            tmpInfo['projectId'] = data[0].projectId;
            tmpInfo['projectName'] = data[0].projectName;

            let emplo = {
                employeeId: data[0].employeeId,
                employeeName: data[0].employeeName,
                agreementId: data[0].agreementId,
                agreementName: data[0].agreementName,
                shift: data[0].shift
            };
            
            tmpshift = {
                shiftId: data[0].shift.shiftId,
                shiftName: data[0].shift.shiftName
            };

            this.shiftsList.push(tmpshift);
            tmpEmployee.push(emplo);
            let deptm = {
                departmentId: data[0].departmentId,
                departmentName: data[0].departmentName,
                employees: tmpEmployee
            };

            tmpDepartment.push(deptm);
            tmpInfo['departments'] = tmpDepartment;
            this.projects.push(tempProject);
            this.data.push(tmpInfo);

            for (let i = 1; i < data.length; i++ ){
                tempProject = {};
                let result = this.data.findIndex( ({ projectId }) => projectId === data[i].projectId );
                if(result > -1){
                let result2 = this.data[result].departments.findIndex( ({ departmentId }) => departmentId === data[i].departmentId );
                    if (result2 > -1) {

                        let emplo = {
                            employeeId: data[i].employeeId,
                            employeeName: data[i].employeeName,
                            agreementId: data[i].agreementId,
                            agreementName: data[i].agreementName,
                            shift: data[i].shift
                        };
                    this.data[result].departments[result2].employees.push(emplo);
                    if (data[i].shift !== null) {
                        let result3 = this.shiftsList.findIndex(({ shiftId }) => shiftId === data[i].shift.shiftId);
                        if(result3 < 0){
                            tmpshift = {
                                shiftId: data[i].shift.shiftId,
                                shiftName: data[i].shift.shiftName
                            };
                            this.shiftsList.push(tmpshift);
                        }
                    }
                    }
                }
            }
            this.filterData = this.data;
        }
        this._cdr.detectChanges();
    }

    searchEmployee(employee){
        let tmpArray = [];
        for(let i = 0; i < this.schedules.length; i++){
            if(this.schedules[i].employeeName.toLowerCase().includes(employee.toString().toLowerCase())){
                tmpArray.push(this.schedules[i]);
            }
        }
        this.getSchedule(tmpArray);
    }

    openModal(project, department){
        let data = {
            project,
            department,
        }
        try {
            const modalRef = this.modalService.open(AddScheduleModalComponent, {size:'xl'})
            modalRef.componentInstance.data = data;
            modalRef.result.then( res => {
                if (res != undefined) {
                this.getInfo();
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    async getInfo(){
        this.schedules = await this._scheduleService.getschedules();
        console.log(' this.schedules ', this.schedules );
        this.getSchedule(this.schedules);
    }

    getDepartments(project){
        this.searchProject = project;
        this.departments = [];
        if( project != '' ){
            let tmpDepartment = {};
            for(let i = 0; i < this.filterData.length; i++){
                if( this.filterData[i].projectId == project ){
                    for(let j = 0; j < this.filterData[i].departments.length; j++){
                        tmpDepartment = {
                        departmentId: this.filterData[i].departments[j].departmentId,
                        departmentName: this.filterData[i].departments[j].departmentName
                        };
                        this.departments.push(tmpDepartment);
                    }
                }
            }
        }
    }

}
