<!--begin::Container-->
<div
  [ngClass]="footerContainerCSSClasses"
  class="d-flex flex-column flex-md-row align-items-center justify-content-between"
>
  <!--begin::Copyright-->
  <div class="text-dark order-2 order-md-1">
    <span class="text-muted font-weight-bold mr-2"
      >{{ currentYear }} &copy;</span
    >
    <a
      href="http://keenthemes.com/metronic"
      target="_blank"
      class="text-dark-75 text-hover-primary"
      >Keenthemes</a
    >
  </div>
  <!--end::Copyright-->

  <!--begin::Nav-->
  <div class="nav nav-dark">
    <a
      href="http://keenthemes.com/metronic"
      target="_blank"
      class="nav-link pl-0 pr-5"
      >About</a
    >
    <a
      href="http://keenthemes.com/metronic"
      target="_blank"
      class="nav-link pl-0 pr-5"
      >Team</a
    >
    <a
      href="http://keenthemes.com/metronic"
      target="_blank"
      class="nav-link pl-0 pr-0"
      >Contact</a
    >
  </div>
  <!--end::Nav-->
</div>
<!--end::Container-->
