<div class="modal-header">
    <h4 class="modal-title"><b>title</b></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" >
       
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
    <button type="button"  class="btn btn-primary" >Guardar</button>
  </div>