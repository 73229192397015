import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Agreement } from '../../pages/views/settings/agreement/agreement.component';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(
    private apiService: ApiService
  ) { }

  async getComapnies(){
    const responseComapnies: any = await this.apiService.get("companies");
    if(responseComapnies.statusCode === 200){
      return responseComapnies.data.items;
    }
  }

  async getComapniesById(id: any){
    const responseComapnies: any = await this.apiService.get(`companies/${id}`);
    if(responseComapnies.statusCode === 200){
      return responseComapnies.data;
    }
  }

  async updateCompany(id: any, params: any){
    const responseComapnies: any = await this.apiService.putFile(`companies`, id, params);
      // if(responseEmployee.statusCode === 200){
        console.log(responseComapnies);
        return responseComapnies;
      // }
  }

  async getProjects(){
    const responseProjects: any = await this.apiService.get("projects");
    if(responseProjects.statusCode === 200){
      return responseProjects.data.items;
    }
  }

  async getAgreements(){
    const responseAgreements: any = await this.apiService.get("agreement");
    if(responseAgreements.statusCode === 200){
      return responseAgreements.data.items;
    }
  }
  
  async getDepartments(){
    const responseDepartments: any = await this.apiService.get("department");
    if(responseDepartments.statusCode === 200){
      return responseDepartments.data.items;
    }
  }

  async getCostDestination(){
    const responseCostDestination: any = await this.apiService.get("cost-destination");
    if(responseCostDestination.statusCode === 200){
      return responseCostDestination.data.items;
    }
  }
  async getCostDestinationById(agreementNumber?:any){
    const responseCostDestination: any = await this.apiService.get(`cost-destination?agreementNumber=${agreementNumber}`);
    if(responseCostDestination.statusCode === 200){
      return responseCostDestination.data.items;
    }
  }
}
