<div *ngIf="data != undefined">
    <!--div style="width: 100%;">
        <button class="btn btn-primary" *ngIf="userService.crudPermissions('TIME_SHEET_UPDATE')" style="float: right; border-radius: 0px;">Guardar Cambios</button>
       
        
    </div-->
    <div class="principalContainerTable" *ngIf="data.data && data.data.length > 0 && !notFound" [ngStyle]="{'visibility': (loading) ? 'hidden' : 'visible'}"  #table>
        <div class='subContainerTable'>
            <div class='containerInfo' id="containerInfoStatic" *ngIf="data.columsStatic" [ngStyle]="{'width': data.columsStaticWidth+'%'}">
                <div [ngStyle]="{'width': '100%', 'padding-left': '15px', 'padding-right': '0px'}">
                    <div class='headersTable' id='headersTableStatic'>
                        <div class='headerItem' [ngStyle]="{'width': header.width ? header.width+'%' : widthItem}" *ngFor="let header of data.headers | slice:0:indexStatic; index as i;">
                            <div *ngIf="!header.check">
                                {{header.text}}
                            </div>
                            <div *ngIf="header.check">
                                <input type="checkbox" (change)="selectAll($event.target.checked, i)" [id]="'checkHeader'+i">
                            </div>
                        </div>
                    </div>
                    <div class='rowTable' [id]="'rowStatic_'+j" *ngFor="let item of tableData[actualPage-1]; index as j;" [ngStyle]="{'background-color': selectRow && selectRow[data.principalKey] == item[data.principalKey] ? '#ccebff' : ((j%2 != 0) ? '#F5F5F5' : 'white'), 'border-bottom': data.totals && (tableData[actualPage-1].length - 1 == j) ? '0.5px solid gray':'0.5px none gray'}">
                        <div class="item" [ngStyle]="{'width': itemH.width ? itemH.width+'%' : widthItem}" *ngFor="let itemH of data.headers | slice:0:indexStatic; index as k;">
                            <div *ngIf="!itemH.tag && !itemH.img && !itemH.icons && !itemH.check && !itemH.date && !itemH.input">
                                <label style="display: inline-block;">{{item[itemH.key]}}</label>
                            </div>
                            <div *ngIf="itemH.tag && !itemH.img && !itemH.icons && !itemH.check && !itemH.date && !itemH.input">
                                <div class='tagItem' [ngStyle]="{'background-color': tagBG(itemH.tagOptions,item[itemH.key]), 'color': tagText(itemH.tagOptions,item[itemH.key])}">
                                    <div *ngIf="!itemH.const">{{item[itemH.key]}}</div>
                                    <div *ngIf="itemH.const">{{tagValue(itemH.tagOptions,itemH.key,item)}}</div>
                                </div>
                            </div>
                            <div *ngIf="!itemH.tag && itemH.img && !itemH.icons && !itemH.check && !itemH.date && !itemH.input">
                                <div *ngIf="itemH.arrayImg">
                                    <div *ngFor="let img of item[itemH.key]; index as x;">
                                        <img *ngIf="x == itemH.arrayPointer" style="width: 50px; display: inline-block;" [src]="img[itemH.arrayKey]">
                                    </div>
                                    <label *ngIf="itemH.imgOptions.text" style="display: inline-block;">{{item[itemH.imgOptions.keyText]}}</label>
                                </div>
                                <div *ngIf="!itemH.arrayImg">
                                    <img style="width: 50px; display: inline-block;" [src]="item[itemH.key]"><label *ngIf="itemH.imgOptions.text" style="display: inline-block;">{{item[itemH.imgOptions.keyText]}}</label>
                                </div>
                            </div>
                            <div *ngIf="!itemH.tag && !itemH.img && itemH.icons && !itemH.check && !itemH.date && !itemH.input">
                                <div *ngIf="itemH.iconOptions.multiple" >
                                    <i [class]="itemH.iconOptions.icon" [ngStyle]="{'color': (itemH.iconOptions.colorKey) ? item[itemH.iconOptions.color] : itemH.iconOptions.color, 'margin': '2px'}" *ngFor="let icon of iconsArray(item[itemH.iconOptions.valueKey]).array"></i>
                                    <div *ngIf="iconsArray(item[itemH.iconOptions.valueKey]).over > 0" style="display: contents; font-weight: bold;">
                                        + {{item[itemH.iconOptions.valueKey]-5}} <div style="display: contents;" *ngIf="itemH.iconOptions.displayText">  {{item[itemH.key]}}</div>
                                    </div>
                                </div>
                                <div *ngIf="!itemH.iconOptions.multiple">
                                    <i [class]="itemH.iconOptions.icon" [ngStyle]="{'color': itemH.iconOptions.colorKey ? item[itemH.iconOptions.color] : itemH.iconOptions.color}"></i><div style="display: contents;" *ngIf="itemH.iconOptions.displayText">  {{item[itemH.key]}}</div>
                                </div>
                            </div>
                            <div *ngIf="!itemH.tag && !itemH.img && !itemH.icons && itemH.check && !itemH.date && !itemH.input">
                                <label style="display: inline-block;"><input type="checkbox" [id]="'check'+j+k" (click)="checkThis(item, $event.target.checked,j,k)"></label>
                            </div>
                            <div *ngIf="!itemH.tag && !itemH.img && !itemH.icons && !itemH.check && itemH.date == true && !itemH.input">
                                <div *ngIf="!itemH.multiple">
                                    <div *ngIf="itemH.year">
                                        <label style="display: inline-block;">{{item[itemH.key] | spanishDate:true}}</label>
                                    </div>
                                    <div *ngIf="!itemH.year">
                                        <label style="display: inline-block;">{{item[itemH.key] | spanishDate}}</label>
                                    </div>
                                </div>
                                <div *ngIf="itemH.multiple">
                                    <div *ngIf="itemH.year">
                                        <label style="display: inline-block;" >{{item[itemH.key] | spanishDate:true}}</label>
                                    </div>
                                    <div *ngIf="!itemH.year">
                                        <div style="display: inline-block;" *ngFor="let date of itemH.dates; index as z">{{item[date.key] | spanishDate}} <div style="display: inline-block;" *ngIf="z < itemH.dates.length - 1">- </div></div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="!itemH.tag && !itemH.img && !itemH.icons && !itemH.check && !itemH.date && itemH.input">
                                <div *ngIf="itemH.input == 'number'">
                                    <input class="form-control" type="number" (focus)="onFocusEvent(item)" (change)="eventActionsInput(itemH,item,$event)" [value]="item[itemH.key]" [disabled]="disabledInput(itemH,item) || !userService.crudPermissions('TIME_SHEET_UPDATE')" >
                                </div>
                            </div>

                        </div>
                    </div>
                    <!------SUMATORIA------>
                    <div class='rowTable' *ngIf="data.totals" style="background-color:white; border-top: 0.5px none gray !important">
                        <div class="item" [ngStyle]="{'width': itemH.width ? itemH.width+'%' : widthItem, 'background-color': itemH.sum || itemH.isLabelTotal ||  itemH.block ? '#264660' : 'white', 'color':  itemH.sum || itemH.isLabelTotal ? 'white' : 'black', 'border-left':  itemH.sum || itemH.isLabelTotal ? 'solid 1px' : 'hidden'}" *ngFor="let itemH of data.headers | slice:0:indexStatic; index as k;">
                            <div *ngIf="itemH.isLabelTotal" style="background-color: #264660; color: white;  font-weight: bold;">
                                <div class='tagItem'>
                                    <div>{{itemH.labelTotal}}</div>
                                </div>
                            </div>
                            <div *ngIf="itemH.sum" style="background-color: #264660; color: white; font-weight: bold;">
                                <div class='tagItem'>
                                    <div>{{totales[k].toFixed(2)}}</div>
                                </div>
                            </div>
                            <div *ngIf="itemH.block" style="background-color: #264660; color: #264660; font-weight: bold;">
                                <div class='tagItem'>
                                    <div>&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!------FIN SUMATORIA------>
                </div>
            </div>
            <div class='containerInfo' id="containerInfo" [ngStyle]="{'width': data.columsStatic ? ((data.actions) ? (90-data.columsStaticWidth)+'%' : (100-data.columsStaticWidth)+'%') : ((data.actions) ? '90%' : '100%')}">
                <div [ngStyle]="{'width': widthContainer, 'padding-right': (data.actions) ? '0px' : '15px', 'padding-left': (data.columsStatic) ? '0px' : '15px'}">
                    <div class='headersTable' id='headersTable'>
                        <div class='headerItem' [ngStyle]="{'width': header.width ? header.width+'%' : widthItem}" *ngFor="let header of data.headers | slice:indexStatic:data.headers.length; index as i;">
                            <div *ngIf="!header.check">
                                {{header.text}}
                            </div>
                            <div *ngIf="header.check">
                                <input type="checkbox" (change)="selectAll($event.target.checked, i)" [id]="'checkHeader'+i">
                            </div>
                        </div>
                    </div>
                    <div class='rowTable' [id]="'row_'+j" *ngFor="let item of tableData[actualPage-1]; index as j;" [ngStyle]="{'background-color': selectRow && selectRow[data.principalKey] == item[data.principalKey] ? '#ccebff' : ((j%2 != 0) ? '#F5F5F5' : 'white'), 'border-bottom': data.totals && (tableData[actualPage-1].length - 1 == j) ? '0.5px solid gray':'0.5px none gray'}">
                        <div class="item " [ngStyle]="{'width': itemH.width ? itemH.width+'%' : widthItem}" *ngFor="let itemH of data.headers | slice:indexStatic:data.headers.length; index as k;">
                           
                            <div *ngIf="!itemH.tag && !itemH.img && !itemH.icons && !itemH.check && !itemH.date && !itemH.input && !itemH.dateFormat" >
                                <label style="display: inline-block;" [ngClass]="{'truncate': !itemH.width }" data-toggle="tooltip" data-placement="bottom"   data-title="{{item[itemH.key]}}"   >{{item[itemH.key]}}</label>
                            </div>
                            <div *ngIf="itemH.tag && !itemH.img && !itemH.icons && !itemH.check && !itemH.date && !itemH.input && !itemH.dateFormat">
                                <div class='tagItem' [ngStyle]="{'background-color': tagBG(itemH.tagOptions,item[itemH.key]), 'color': tagText(itemH.tagOptions,item[itemH.key])}">
                                    <div *ngIf="!itemH.const">{{itemH.width}}</div>
                                    <div *ngIf="itemH.const">{{tagValue(itemH.tagOptions,itemH.key,item)}}</div>
                                </div>
                            </div>
                            <div *ngIf="!itemH.tag && itemH.img && !itemH.icons && !itemH.check && !itemH.date && !itemH.input && !itemH.dateFormat">
                                <div *ngIf="itemH.arrayImg">
                                    <div *ngFor="let img of item[itemH.key]; index as x;">
                                        <img *ngIf="x == itemH.arrayPointer" style="width: 50px; display: inline-block;" [src]="img[itemH.arrayKey]">
                                    </div>
                                    <label *ngIf="itemH.imgOptions.text" style="display: inline-block;">{{item[itemH.imgOptions.keyText]}}</label>
                                </div>
                                <div *ngIf="!itemH.arrayImg">
                                    <img style="width: 50px; display: inline-block;" [src]="item[itemH.key]"><label *ngIf="itemH.imgOptions.text" style="display: inline-block;">{{item[itemH.imgOptions.keyText]}}</label>
                                </div>
                            </div>
                            <div *ngIf="!itemH.tag && !itemH.img && itemH.icons && !itemH.check && !itemH.date && !itemH.input && !itemH.dateFormat">
                                <div *ngIf="itemH.iconOptions.multiple" >
                                    <i [class]="itemH.iconOptions.icon" [ngStyle]="{'color': (itemH.iconOptions.colorKey) ? item[itemH.iconOptions.color] : itemH.iconOptions.color, 'margin': '2px'}" *ngFor="let icon of iconsArray(item[itemH.iconOptions.valueKey]).array"></i>
                                    <div *ngIf="iconsArray(item[itemH.iconOptions.valueKey]).over > 0" style="display: contents; font-weight: bold;">
                                        + {{item[itemH.iconOptions.valueKey]-5}} <div style="display: contents;" *ngIf="itemH.iconOptions.displayText">  {{item[itemH.key]}}</div>
                                    </div>
                                </div>
                                <div *ngIf="!itemH.iconOptions.multiple">
                                    <i [class]="itemH.iconOptions.icon" [ngStyle]="{'color': itemH.iconOptions.colorKey ? item[itemH.iconOptions.color] : itemH.iconOptions.color}"></i><div style="display: contents;" *ngIf="itemH.iconOptions.displayText">  {{item[itemH.key]}}</div>
                                </div>
                            </div>
                            <div *ngIf="!itemH.tag && !itemH.img && !itemH.icons && itemH.check && !itemH.date && !itemH.input && !itemH.dateFormat">
                                <label style="display: inline-block;"><input type="checkbox" [id]="'check'+j+k" (click)="checkThis(item, $event.target.checked,j,k)"></label>
                            </div>
                            <div *ngIf="!itemH.tag && !itemH.img && !itemH.icons && !itemH.check && itemH.date == true && !itemH.input && !itemH.dateFormat">
                                <div *ngIf="!itemH.multiple">
                                    <div *ngIf="itemH.year">
                                        <label style="display: inline-block;">{{item[itemH.key] | spanishDate:true}}</label>
                                    </div>
                                    <div *ngIf="!itemH.year">
                                        <label style="display: inline-block;">{{item[itemH.key] | spanishDate}}</label>
                                    </div>
                                </div>
                                <div *ngIf="itemH.multiple">
                                    <div *ngIf="itemH.year">
                                        <label style="display: inline-block;" >{{item[itemH.key] | spanishDate:true}}</label>
                                    </div>
                                    <div *ngIf="!itemH.year">
                                        <div style="display: inline-block;" *ngFor="let date of itemH.dates; index as z">{{item[date.key] | spanishDate}} <div style="display: inline-block;" *ngIf="z < itemH.dates.length - 1">- </div></div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="itemH.dateFormat">
                                <label style="display: inline-block;">{{item[itemH.key] | momentDate:itemH.typeDateFormat}}</label>
                            </div>

                            <div *ngIf="!itemH.tag && !itemH.img && !itemH.icons && !itemH.check && !itemH.date && itemH.input && !itemH.dateFormat">
                                <div *ngIf="itemH.input == 'number'">
                                    <input class="form-control" type="number" [id]="'input_'+itemH.key+j" (focus)="onFocusEvent(item)" (change)="eventActionsInput(itemH,item,$event)" [value]="item[itemH.key]" [disabled]="disabledInput(itemH,item) || !userService.crudPermissions('TIME_SHEET_UPDATE')" >
                                </div>
                            </div>

                        </div>
                    </div>
                    <!------SUMATORIA------>
                    <div class='rowTable' *ngIf="data.totals" style="background-color:white; border-top: 0.5px none gray !important">
                        <div class="item" [ngStyle]="{'width': itemH.width ? itemH.width+'%' : widthItem, 'background-color': itemH.sum || itemH.isLabelTotal ||  itemH.block ? '#264660' : 'white', 'color':  itemH.sum || itemH.isLabelTotal ? 'white' : 'black', 'border-left':  itemH.sum || itemH.isLabelTotal ? 'solid 1px' : 'hidden'}" *ngFor="let itemH of data.headers | slice:indexStatic:data.headers.length; index as k;" >
                            <div *ngIf="itemH.isLabelTotal" style="background-color: #264660; color: white;  font-weight: bold;">
                                <div class='tagItem'>
                                    <div>{{itemH.labelTotal}}</div>
                                </div>
                            </div>
                            <div *ngIf="itemH.sum" style="background-color: #264660; color: white; font-weight: bold;">
                                <div class='tagItem'>
                                    <div>{{totales[k+indexStatic].toFixed(2)}}</div>
                                </div>
                            </div>
                            <div *ngIf="itemH.block" style="background-color: #264660; color: #264660; font-weight: bold;">
                                <div class='tagItem'>
                                    <div>&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!------FIN SUMATORIA------>
                </div>
            </div>
            <div class='containerActions' *ngIf="data.actions && !data.actionsButton">
                <div class='headersTable'>
                    <div class='headerItem' id='actionsHeader' style="padding: 10px; vertical-align: middle;">
                        <div style="height: 100%; vertical-align: middle;" >
                            ACCIONES
                        </div>
                    </div>
                </div>
                <div class='rowTable' [id]="'action'+actualPage+'_'+l" *ngFor="let itemAct of tableData[actualPage-1]; index as l;" [ngStyle]="{'background-color': (l%2 != 0) ? '#F5F5F5' : 'white'}">
                    <div class="item" style=" margin-right:auto; margin-left:auto; width: 100% !important;">
                        <div [className]="!itemAct.open ? 'actionsButton' : 'actionsButtonActive'" (click)='openDrop(l)'>
                            <i class='fa fa-ellipsis-v' style='color: #4389b8;'>
                            </i>
                            <div class="actionsMenu" *ngIf="itemAct.open">
                                <div style="width: 100%; font-weight: bold; padding: 10px; cursor: default;">
                                    Elige una acción
                                </div>
                                <div *ngFor="let act of data.actionsMenu">
                                    <div class="actionMenuItem" *ngIf="userService.crudPermissions(act.permission)" (click)="eventActions(act.action,itemAct,l); $event.stopPropagation()" >
                                    <!--<div class="actionMenuItem"  (click)="eventActions(act.action,itemAct,l); $event.stopPropagation()" >-->
                                            <i [class]="act.icon" style='color: #4389b8; padding-right: 5px;'>
                                            </i>
                                            {{act.text}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='containerActions' *ngIf="data.actions && data.actionsButton">
                <div class='headersTable'>
                    <div class='headerItem' id='actionsHeader' style="padding: 10px; vertical-align: middle;">
                        <div style="vertical-align: middle;">
                            ACCIONES
                        </div>
                    </div>
                </div>
                <div class='rowTable' [id]="'action'+actualPage+'_'+l" *ngFor="let itemAct of tableData[actualPage-1]; index as l;" [ngStyle]="{'background-color': (l%2 != 0) ? '#F5F5F5' : 'white', 'border-bottom': data.totals && (tableData[actualPage-1].length - 1 == l) ? '0.5px solid gray':'0.5px none gray'}">
                    <div class="item" style="display: flex; width: 100% !important;">
                        <div class="item" *ngFor="let act of data.actionsMenu">
                            <!--<div class="actionMenuItem"  (click)="eventActions(act.action,itemAct,l); $event.stopPropagation()" >-->
                                <!--button class="btn btn-xs btn-primary" *ngIf="userService.crudPermissions(act.permission)" style="float: right; border-radius: 0px;"><i [class]="act.icon" style='color: #4389b8; padding-right: 5px;'>
                                </i></button-->
                                
                                <i *ngIf="userService.crudPermissions(act.permission)" (click)="eventActions(act.action,itemAct,l);" [class]="act.icon" style='cursor: pointer !important; color: #4389b8; padding-right: 5px;'>
                                </i>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="containerPagination">
            <div class="buttonsContainer">
                <button class="pagStartButton" (click)="firstPage()" [disabled]="actualPage == 1" [ngStyle]="{'background-color': (actualPage == 1) ? '#D7D7D7' : 'white'}">
                    <i class='fa fa-angle-double-left' style='color: #4389b8;'>
                    </i>
                </button>
                <button class="pagButton" (click)="prevPage()" [disabled]="actualPage == 1" [ngStyle]="{'background-color': (actualPage == 1) ? '#D7D7D7' : 'white'}">
                    <i class='fa fa-angle-left' style='color: #4389b8;'>
                    </i>
                </button>
                <button class="pagButton" (click)="goToPage(btnPag)" *ngFor="let btnPag of buttonsPagination" [ngStyle]="{'background-color': (btnPag == actualPage) ? '#D7D7D7' : 'white'}">
                    {{btnPag}}
                </button>
                <button class="pagButton" (click)="nextPage()" [disabled]="actualPage == totalPages" [ngStyle]="{'background-color': (actualPage == totalPages) ? '#D7D7D7' : 'white'}">
                    <i class='fa fa-angle-right' style='color: #4389b8;'>
                    </i>
                </button>
                <button class="pagEndButton" (click)="lastPage()" [disabled]="actualPage == totalPages" [ngStyle]="{'background-color': (actualPage == totalPages) ? '#D7D7D7' : 'white'}">
                    <i class='fa fa-angle-double-right' style='color: #4389b8;'>
                    </i>
                </button>
            </div>
            <div class="limitElemContainer">
                Elementos por página
                <select style="padding: 5px;" (change)="getTotalItems($event.target.value)">
                    <option [value]="'1'">1</option>
                    <option [value]="'5'">5</option> 
                    <option [value]="'10'" selected>10</option>
                    <option [value]="'25'">25</option>
                    <option [value]="'50'">50</option>
                    <option [value]="'100'">100</option>
                    <option [value]="'Todos'">Todos</option>
                </select>
            </div>
        </div>
    </div>
    <div style="width: 100%; background-color: white; text-align: center;" *ngIf="!data.data || data.data.length == 0 || notFound">
        <label style="width: 100%;margin-top: 50px; font-size: 20px;"><b>Lo sentimos, no se encontró información.</b></label>
        <img style="display: inline-block; width: 500px;" src="../../../../assets/media/misc/no_data.jpg">
    </div>
</div>
