<!--begin::Topbar-->
<ng-container>
  <ng-container *ngIf="extrasSearchLayout === 'offcanvas'">
    <!--begin::Search-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_search_toggle">
        <span [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'" cacheSVG="true"
          class="svg-icon svg-icon-xl svg-icon-primary"></span>
      </div>
    </div>
    <!--end::Search-->
  </ng-container>

</ng-container>
<ng-container *ngIf="extrasUserDisplay">
  <ng-container *ngIf="extrasUserLayout === 'offcanvas'">
    <ng-container *ngIf="user$ | async as _user">
      <!--begin::User-->
      <div class="topbar-item" (change)="getNotifications()">
        <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle" >

          <!--<a tabindex="8" aria-label="Notificaciones" href="#" id="notiLink" class="option-notifications notifications-widget modeless-box-trigger" rel="nofollow" aria-owns="modeless-65" aria-haspopup="true" popup-hidden="false">
            <i class="nav-icon-notifications">
             
            </i><span class="ml-count" style="display: block;">2</span></a>-->
          <span
            class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"><span class="ml-count" *ngIf="existNoti">0</span><i class="fas fa-portrait"></i> {{ _user.fullname }}</span>
            
          <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
            <!-- <span class="symbol-label font-size-h5 font-weight-bold">{{
              _user.fullname[0]
            }}</span> -->
          </span>
        </div>
      </div>
      <!--end::User-->
    </ng-container>
  </ng-container>

  <ng-container *ngIf="extrasUserLayout === 'dropdown'">
    <!--begin::User-->
    <ng-container *ngIf="user$ | async as _user">
      <div class="dropdown" ngbDropdown placement="bottom-right">
        <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
          <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
            <span
              class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ _user.fullname }}</span>
            <span class="symbol symbol-35 symbol-light-success"> 
              <!-- <span class="symbol-label font-size-h5 font-weight-bold">{{
                _user.fullname | firstLetter
              }}</span> -->
            </span>
          </div>
        </div>
        <!--end::Toggle-->
        <!--begin::Dropdown-->
        <div ngbDropdownMenu
          class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
          <app-user-dropdown-inner></app-user-dropdown-inner>
        </div>
      </div>
      <!--end::Dropdown-->
    </ng-container>
    <!--end::User-->
  </ng-container>
</ng-container>

<!--end::Topbar-->