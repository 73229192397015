import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShiftsService } from '../../../../services/shifts/shifts.service';
import { EmployeesService } from '../../../../services/employees/employees.service';
import Swal from 'sweetalert2';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { CompaniesService } from '../../../../services/companies/companies.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as alertify from "alertifyjs";
import { FormGroup, FormControl, Validator, Validators, FormBuilder, FormArray } from '@angular/forms';
import { CalendarService } from '../../../../services/settings/calendar/calendar.service';
import { map } from 'rxjs/operators';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-employees-shifts-modal',
  templateUrl: './employees-shifts-modal.component.html',
  styleUrls: ['./employees-shifts-modal.component.scss'],
})

export class EmployeesShiftsModalComponent implements OnInit {
  @Input() data: any;

  title = "";
  employee: any;
  shifts: any;
  periods: any = [];
  tmpperiods: any;
  imgChange = false;
  fullNameEmployee: string;
  public formdata: FormGroup;
  shiftssToDelete: any = [];
  newObjectShift: any;
  saveShiftsArray: any = [];
  shiftsArray: any = [];
  /// <reference path="test:MatDatepicker<any>" />
  test: MatDatepicker<any>;
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day === 1;
  }
  minDate = new Date();
  minDateDynamics = new Date();
  //maxDate = new Date(2020, 0, 1);
  defDate = new Date(4000, 0, 1, 15);
  shiftsAllbyEmployeeNumber: any = [];
  minDatesArray: any = [];
  shifsByDelete: any = [];
  period = null;

  constructor(public activeModal: NgbActiveModal,
    private _employeesService: EmployeesService,
    private _shiftsService: ShiftsService,
    private _cdr: ChangeDetectorRef,
    private _companiesService: CompaniesService,
    private fb: FormBuilder,
    private _sanitizer: DomSanitizer,
    private _periodsService: CalendarService,
    private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }

  async ngOnInit() {

    this.formdata = this.shiftData();

    this.period = await this._periodsService.getLastOpenPeriod();

    this.minDate = moment(this.period.stringStartDate).toDate();
    this.minDateDynamics = moment(this.period.stringStartDate).toDate();

    this.employee = await this._employeesService.getEmployeeById(this.data.data.employeeId);
    console.log(this.employee);
    this.fullNameEmployee = this.employee.employeeName + ' ' + this.employee.firstName + ' ' + this.employee.lastName;
    this.title = "Turnos empleado - " + this.employee.employeeNumber + " - " + this.fullNameEmployee;
    this.shifts = await this._shiftsService.getShiftsByProjectId(this.employee.projectId);

    this.shiftsAllbyEmployeeNumber = await this._employeesService.getEmployeeAllShifts(this.employee.employeeNumber, this.period.stringStartDate);
    console.log(this.shiftsAllbyEmployeeNumber);

    if (this.employee.shiftId) {
      let employeeFg = this.formdata;

    }
    const validate = dateString => {
      const day = (new Date(dateString)).getDay();
      if (day == 0 || day == 6) {
        return false;
      }
      return true;
    }
    var curr = moment(this.period.stringStartDate).toDate(); // get current date
    var first = curr.getDate() - curr.getDay() + 1;
    var firstday = new Date(curr.setDate(first));
    this.minDate = firstday;
    this.minDatesArray[0] = this.minDate;

    this._cdr.detectChanges();
    this.existShifts();
  }

  existShifts() {

    let dinamycsArr: any = [];

    for (let index = 0; index < this.shiftsAllbyEmployeeNumber.items.length; index++) {

      if (index <= 0) {

        this.formdata.patchValue(
          {
            shiftId: this.shiftsAllbyEmployeeNumber.items[index].shiftId.shiftId,
            startDate: moment(this.shiftsAllbyEmployeeNumber.items[index].startDate).toDate(),
            endDate: moment(this.shiftsAllbyEmployeeNumber.items[index].endDate + "T15:00:00").toDate(),
            employeeShiftId: this.shiftsAllbyEmployeeNumber.items[index].employeeShiftId
          }
        )

        if (this.shiftsAllbyEmployeeNumber.items.length > 1 || moment(this.shiftsAllbyEmployeeNumber.items[index].startDate).format("YYYY-MM-DD") < this.period.stringStartDate) {
          this.formdata.controls['shiftId'].disable();
        }

        if (this.shiftsAllbyEmployeeNumber.items.length > 1 || moment(this.shiftsAllbyEmployeeNumber.items[index].startDate).format("YYYY-MM-DD") < this.period.stringStartDate) {
          this.formdata.controls['startDate'].disable();
        }

      }
      else {
        dinamycsArr = this.formdata.get("shifts") as FormArray;
        let dinamycsFG = this.newShift();
        dinamycsArr.push(dinamycsFG);
        for (let index = 0; index < this.formdata.value.shifts.length; index++) {
          if (index == 0) {
            let currDate = new Date(moment(this.formdata.get('startDate').value).toDate()); // get current date
            let second = currDate.getDate() + currDate.getDay() + 1;
            let secondday = new Date(currDate.setDate(second))
            this.minDatesArray[index + 1] = secondday;
          }
          else {
            let shiftsArrAux = this.formdata.get('shifts') as FormArray;


            let currDate = new Date(moment(this.formdata.value.shifts[index - 1].startDate).toDate()); // get current date
            let second = currDate.getDate() + currDate.getDay() + 1;
            let secondday = new Date(currDate.setDate(second))
            this.minDatesArray[index + 1] = secondday;
          }
        }

        dinamycsFG.patchValue(
          {

            shiftId: this.shiftsAllbyEmployeeNumber.items[index].shiftId.shiftId,
            startDate: moment(this.shiftsAllbyEmployeeNumber.items[index].startDate).toDate(),
            endDate: moment(this.shiftsAllbyEmployeeNumber.items[index].endDate).toDate(),
            employeeShiftId: this.shiftsAllbyEmployeeNumber.items[index].employeeShiftId
          }
        );

      }

    }
    this._cdr.detectChanges();
  }

  shiftData() {
    return (this.fb.group({

      shiftId: [{ value: '', disabled: false }],
      employeeShiftId: [{ value: '', disabled: false }],
      startDate: [{ value: '', disabled: false }],
      endDate: [{ value: this.defDate, disabled: false }],
      shifts: this.fb.array([])
    }));

  }

  newShift() {
    return (this.fb.group({

      shiftId: [{ value: '', disabled: false }],
      employeeShiftId: [{ value: '', disabled: false }],
      startDate: [{ value: '', disabled: false }],
      endDate: [{ value: this.defDate, disabled: false }]

    }));


  }

  addNewShift() {
    let vacio: any = false;
    let shiftsArrAux = this.formdata.get('shifts') as FormArray;

    if (shiftsArrAux.controls.length > 0) {
      for (let index = 0; index < shiftsArrAux.length; index++) {

        if (shiftsArrAux.controls[index].value.startDate == "" ||
          shiftsArrAux.controls[index].value.shiftId == "") {

          vacio = true;
        }


      }
      if (vacio == false) {
        let shiftsArr = this.formdata.get('shifts') as FormArray;
        let shiftFG = this.newShift();
        shiftsArr.push(shiftFG);

      }
      else {
        alertify.error('Debes completar el nuevo turno antes de agregar uno nuevo');
      }

    }
    else {
      if (this.formdata.value.startDate === "" || this.formdata.value.shiftId === "") {
        alertify.error('Debes completar el turno');
      }
      else {
        let shiftsArr = this.formdata.get('shifts') as FormArray;
        let shiftFG = this.newShift();
        shiftsArr.push(shiftFG);
        this.formdata.controls['shiftId'].disable();
        this.formdata.controls['startDate'].disable();
      }
    }

    if (vacio == false) {
      for (let index = 0; index < shiftsArrAux.length; index++) {

        if (index == 0) {

          let currDate = moment(this.period.stringStartDate).toDate();
          if (currDate != moment(this.formdata.get('startDate').value).toDate()) {
            let second = currDate.getDate() - currDate.getDay() + 1;

            let secondday = new Date(currDate.setDate(second))
            const format = 'dd/MM/yyyy';
            
            const locale = 'en-US';
            let beforeDate = moment(this.formdata.get('startDate').value).toDate();
            const firsDateString = formatDate(beforeDate, format, locale);
            const seconddayString = formatDate(secondday, format, locale);

            if (firsDateString == seconddayString) {
              let second = currDate.getDate() + currDate.getDay() + 1;
              secondday = new Date(currDate.setDate(second))
            }
            this.minDatesArray[index + 1] = secondday;
          } else {
            let minDateAux = new Date(moment(this.formdata.get('startDate').value).toDate()); // get startdate formdata 
            let second = minDateAux.getDate() + minDateAux.getDay() + 1;
            let secondday = new Date(minDateAux.setDate(second))
            this.minDatesArray[index + 1] = secondday;
          }

        } else {
          let currDate = new Date(moment(this.formdata.value.shifts[index - 1].startDate).toDate()); // get current date
          let second = currDate.getDate() + currDate.getDay() + 1;
          let secondday = new Date(currDate.setDate(second))
          this.minDatesArray[index + 1] = secondday;
        }
      }
      console.log(this.minDatesArray);
    }

    this._cdr.detectChanges();
  }

  deleteShift(index: number) {

    let indice = index;
    let shifts = this.formdata.get('shifts') as FormArray;
    let deleteShift = shifts.at(index) as FormGroup;
    if (deleteShift.controls['shiftId'].value != '0') {
      this.shiftssToDelete.push(<number>deleteShift.controls['shiftId'].value);
    }

    if (shifts.controls[indice].value.employeeShiftId) {
      let startDate = new Date(moment(this.formdata.value.shifts[index].startDate).toDate());
      let endDate = new Date(moment(shifts.controls[index].value.endDate).toDate());

      if (indice < this.formdata.value.shifts.length - 1) {
        this.newObjectShift =
        {
          employeeShiftId: this.formdata.value.shifts[index].employeeShiftId,
          employeeId: this.employee.employeeId,
          shiftId: this.formdata.value.shifts[index].shiftId,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate.setDate(endDate.getDate() - 1)).format("YYYY-MM-DD"),
          tagDelete: 1,
          tagActive: 0
        };
      } else {
        this.newObjectShift =
        {
          employeeShiftId: this.formdata.value.shifts[index].employeeShiftId,
          employeeId: this.employee.employeeId,
          shiftId: this.formdata.value.shifts[index].shiftId,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate.setDate(endDate.getDate() - 1)).format("YYYY-MM-DD"),
          tagDelete: 1,
          tagActive: 0
        };
      }
      this.shifsByDelete.push(this.newObjectShift);
    }

    shifts.removeAt(indice);

    this.minDatesArray.splice(indice + 1);
   
    if (this.formdata.value.shifts[index - 1]) {
      (this.formdata.get('shifts') as FormArray).at(index - 1).get('endDate').setValue(this.defDate);
    }
    else {

      this.formdata.get('endDate').setValue(this.defDate);
      let stringDate = moment(this.formdata.get('startDate').value).format("YYYY-MM-DD");
      if (stringDate >= this.period.stringStartDate) {
        this.formdata.controls['shiftId'].enable();
        this.formdata.controls['startDate'].enable();
      }
    }
  }

  confirmSave() {
    let vacio: any = false;
    let shiftsArrAux = this.formdata.get('shifts') as FormArray;

    if (shiftsArrAux.controls.length != 0) {

      for (let index = 0; index < shiftsArrAux.length; index++) {

        if (shiftsArrAux.controls[index].value.startDate == "" ||
          shiftsArrAux.controls[index].value.shiftId == "") {

          vacio = true;
        }

      }

      if (vacio == false) {
        Swal.fire({
          title: 'Espere',
          text: 'Está seguro que quiere editar la programación de turno del empleado ' + this.employee.employeeName,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, actualizar',
          cancelButtonText: 'No, cancelar'
        }).then((result) => {
          if (result.value) {
            this.saveShifts();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            return;
          }
        });

      } else {
        alertify.error('Debes completar el nuevo turno antes de guardar la configuración');
      }

    } else {

      if (this.formdata.value.startDate === "" || this.formdata.value.shiftId === "") {
        alertify.error('Debes completar el turno antes de guardar la configuración');
      } else {
        Swal.fire({
          title: 'Espere',
          text: 'Está seguro que quiere editar la información del empleado ' + this.employee.employeeName,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, actualizar',
          cancelButtonText: 'No, cancelar'
        }).then((result) => {
          if (result.value) {
            this.saveShifts();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            return;
          }
        });
      }
    }

  }

  async saveShifts() {

    this.validations();
    this.saveShiftsArray = [];
    this.newObjectShift = [];
    let auxDefaultEndDate = new Date(moment(this.formdata.get('endDate').value).toDate());
    let shiftsArrAux: any;
    if (this.formdata.value.shifts.length >= 0) {
      shiftsArrAux = this.formdata.get('shifts') as FormArray;
    }
    if (shiftsArrAux.length > 0) {
      if (this.formdata.get('employeeShiftId').value != '') {
        this.newObjectShift =
        {
          employeeShiftId: this.formdata.get('employeeShiftId').value,
          employeeId: this.employee.employeeId,
          shiftId: this.formdata.get('shiftId').value,
          startDate: moment(this.formdata.get('startDate').value).format("YYYY-MM-DD"),
          endDate: moment(auxDefaultEndDate.setDate(auxDefaultEndDate.getDate() - 1)).format("YYYY-MM-DD")
        };
        this.saveShiftsArray.push(this.newObjectShift);
      }
      else {

        this.newObjectShift =
        {

          employeeId: this.employee.employeeId,
          shiftId: this.formdata.get('shiftId').value,
          startDate: moment(this.formdata.get('startDate').value).format("YYYY-MM-DD"),
          endDate: moment(auxDefaultEndDate.setDate(auxDefaultEndDate.getDate() - 1)).format("YYYY-MM-DD")
        };

        this.saveShiftsArray.push(this.newObjectShift);
      }

      if (this.formdata.value.shifts.length >= 0) {

        for (let i = 0; i < shiftsArrAux.length; i++) {
          let endDate = new Date(moment(shiftsArrAux.controls[i].value.endDate).toDate());
          let startDate = new Date(moment(this.formdata.value.shifts[i].startDate).toDate());


          if (this.formdata.value.shifts[i].employeeShiftId) {
            if (i != this.formdata.value.shifts.length - 1) {
              this.newObjectShift =
              {
                employeeShiftId: this.formdata.value.shifts[i].employeeShiftId,
                employeeId: this.employee.employeeId,
                shiftId: this.formdata.value.shifts[i].shiftId,
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate.setDate(endDate.getDate() - 1)).format("YYYY-MM-DD")
              };
            } else {
              this.newObjectShift =
              {
                employeeShiftId: this.formdata.value.shifts[i].employeeShiftId,
                employeeId: this.employee.employeeId,
                shiftId: this.formdata.value.shifts[i].shiftId,
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate.setDate(endDate.getDate())).format("YYYY-MM-DD")
              };
            }

            this.saveShiftsArray.push(this.newObjectShift);
          } else {
            if (i != this.formdata.value.shifts.length - 1) {
              this.newObjectShift =
              {
                //this.formdata.value.shifts[index-1].startDate).toDate()
                employeeId: this.employee.employeeId,
                shiftId: this.formdata.value.shifts[i].shiftId,
                startDate: moment(this.formdata.value.shifts[i].startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate.setDate(endDate.getDate() - 1)).format("YYYY-MM-DD")
              };
            } else {
              this.newObjectShift =
              {
                //this.formdata.value.shifts[index-1].startDate).toDate()
                employeeId: this.employee.employeeId,
                shiftId: this.formdata.value.shifts[i].shiftId,
                startDate: moment(this.formdata.value.shifts[i].startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate.setDate(endDate.getDate())).format("YYYY-MM-DD")
              };
            }

            this.saveShiftsArray.push(this.newObjectShift);
          }

        }
      }
    } else {
      if (this.formdata.get('employeeShiftId').value != '') {
        this.newObjectShift =
        {
          employeeShiftId: this.formdata.get('employeeShiftId').value,
          employeeId: this.employee.employeeId,
          shiftId: this.formdata.get('shiftId').value,
          startDate: moment(this.formdata.get('startDate').value).format("YYYY-MM-DD"),
          endDate: moment(auxDefaultEndDate.setDate(auxDefaultEndDate.getDate())).format("YYYY-MM-DD")
        };
        this.saveShiftsArray.push(this.newObjectShift);
      } else {
        this.newObjectShift =
        {

          employeeId: this.employee.employeeId,
          shiftId: this.formdata.get('shiftId').value,
          startDate: moment(this.formdata.get('startDate').value).format("YYYY-MM-DD"),
          endDate: moment(auxDefaultEndDate.setDate(auxDefaultEndDate.getDate())).format("YYYY-MM-DD")
        };
        this.saveShiftsArray.push(this.newObjectShift);
      }
    }

    this.saveShiftsArray = this.saveShiftsArray.concat(this.shifsByDelete);

    let response = await this._employeesService.createNewProgramerShift(this.saveShiftsArray);

    if (response.statusCode == 201) {
      Swal.fire({
        icon: 'success',
        title: 'Se actualizó la programación del turno correctamente',
        text: 'Éxito'
      });
      this.activeModal.close(true);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrió un error al actualizar la programación del turno inténtalo más tarde'
      });
    }
  }

  validations(i?: number) {
    
    let auxArrayDates: any = [];
    auxArrayDates = this.formdata.value.shifts;
    
    auxArrayDates.map((date, i, auxArrayDates) => {
      
      let auxStartDate = date.startDate.setHours(15);
      let startDate = new Date(auxStartDate);
      
      if (auxArrayDates[i - 1]) {
        (this.formdata.get('shifts') as FormArray).at(i - 1).get('endDate').setValue(moment(startDate.setDate(startDate.getDate())).format("YYYY-MM-DD"));
      } else {
        this.formdata.get('endDate').setValue(moment(startDate.setDate(startDate.getDate())).format("YYYY-MM-DD"));
      }

    });
  }

}
