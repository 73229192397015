<!-- begin::Header Nav -->
<ul class="menu-nav" [ngClass]="ulCSSClasses">
  <!-- begin::1 Level -->
  <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
    <span style="color: white; font-weight: bold; font: 18px; cursor: default;"[innerHTML] = "title"></span>
    <ng-container *ngIf="rootArrowEnabled">
      <i class="menu-arrow"></i>
    </ng-container>
  </li>
  <!-- end::1 Level -->

  <!-- begin::1 Level -->
  <!-- <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
    <a class="menu-link" routerLink="/builder" routerLinkActive="active">
      <span class="menu-text">Builder</span>
      <ng-container *ngIf="rootArrowEnabled">
        <i class="menu-arrow"></i>
      </ng-container>
    </a>
  </li> -->
  <!-- end::1 Level -->
</ul>
<!-- end::Header Nav -->
