<div class="subheader">
    <div class="subHeaderContainer">
        {{headertitle}} {{countRows}}  total
        <input type="text" placeholder="Buscar" class="searchInput" (keyup)="searchVal = $event.target.value">
        
    </div>
    <!---->
    <div class="row col-md-12 justify-content-space-between">
        <div class="col-md-7 justify-align-end">
            <button id="btnSyncJusti"  class="btn btn-primary editar col-md-12" *ngIf="usersService.crudPermissions('JUSTIFICATION_UPDATE')" style="margin-left: 73%; width: 35%;" (click)="syncExculpatory()" >Sincronizar Justificantes</button>
        </div>
        
        <div class="col-md-5">
            <button id="btnSyncJusti"  class="btn btn-primary editar col-md-12 " *ngIf="usersService.crudPermissions('JUSTIFICATION_UPDATE')" style="margin-left: 10%; width: 40%;" (click)="sendExculpatory()" >Aplicar Justificantes</button>
        </div>
    </div>
    
    
</div>
<ls-table [dataTable]="tableData" id="tblExculpatory" *ngIf="tblExculpatory"      (selectedItems)="selectedItems = $event"  [searchVal]="searchVal"></ls-table>
