import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { IFilterProfiles } from '../../../interface/IFilterProfiles';
@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  constructor( private apiService: ApiService) { }

  async getProfiles(filter:IFilterProfiles){
    const responseProfiles: any = await this.apiService.get("profile",filter);
      if(responseProfiles.statusCode === 200){
        return responseProfiles.data.items;
      }
  }
  async getProfilesModules(){
    const responseProfilesModules: any = await this.apiService.get("profile/modules");
      if(responseProfilesModules.statusCode === 200){
        return responseProfilesModules.data.items;
      }
  }

  async getProfileById(id: any){
    const responseProfile: any = await this.apiService.get(`profile/${id}`);
      if(responseProfile.statusCode === 200){
        return responseProfile.data;
      }
  }
  async createProfile(profile){
    const createProfileResponse = await this.apiService.post(`profile`, profile)
    return createProfileResponse;
  }
  async updateProfile(id:any, profile: any){
    const updateProfileResponse = await this.apiService.put(`profile`, id, profile)
    return updateProfileResponse;
  }

  async deleteProfile(id:any){
    const deleteProfileResponse = await this.apiService.delete(`profile`, id)
    return deleteProfileResponse;
  }
}
