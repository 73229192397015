<div style="text-align:center">
	<div>
		<webcam [height]="210" id="captura" [width]="260" style="margin-right: auto; margin-left: auto;"  [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
		 [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
		 (initError)="handleInitError($event)"></webcam>
		<br/><br>
        <div class="row" style="margin-left: auto; margin-right: auto;">
            <div  style="margin-left: auto; margin-right: auto;" >
			<button class="btn btn-primary" (click)="triggerSnapshot();" title="Tomar Foto" style="width: 100%;">Tomar Fotografía</button>
			</div>
           <!-- <div class="actionBtn" style="margin-left: auto; margin-right: auto;" (click)="showNextWebcam(true);" title="Switch Camera">🔁</div>-->
        </div>
		
	</div>
</div>

<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
	<li>{{error | json}}</li>
</ul>