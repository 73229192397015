<div class="subheader">
    <div class="subHeaderContainer" style="width: 200px;">
        <input type="text" placeholder="Buscar empleado" class="searchInput" style="width: 200px;" (keyup)="searchEmployee($event.target.value)">
    </div>
    <div class="subHeaderContainer" style="width: 170px;">
        <input type="date" [ngStyle]="{'width': daysList.length > 1 ? '100%': '110px', 'display': 'inline-block', 'vertical-align': 'top'}" (change)="searchDate($event.target.value)">
        <button class="addShiftButton" style="display: inline-block; vertical-align: top;" *ngIf="daysList.length == 1" (click)="cleanDate()">Limpiar</button>
    </div>
    <div class="subHeaderContainer" style="width: 170px;">
        <select style="width: 100%; height: 24px;" (change)="getDepartments($event.target.value)">
            <option value="" selected>Proyectos (Todos)</option>
            <option *ngFor="let project of projects" [value]="project.projectId">{{project.projectName}}</option>
        </select>
    </div>
    <div class="subHeaderContainer" style="width: 170px;">
        <select style="width: 100%; height: 24px;" (change)=" searchDepartment = $event.target.value" [disabled]="departments.length == 0">
            <option value="" selected>Departamentos (Todos)</option>
            <option *ngFor="let department of departments" [value]="department.departmentId"> {{department.departmentName}}</option>
        </select>
    </div>
    <div class="subHeaderContainer" style="width: 170px;">
        <select style="width: 100%; height: 24px;" (change)="searchShift = $event.target.value">
            <option value="" selected>Turnos (Todos)</option>
            <option *ngFor="let shift of shiftsList" [value]="shift.shiftId">{{shift.shiftName}}</option>
        </select>
    </div>
</div>
<div class="tableContainer">
    <div class="tableHeader">
        <div class="itemHeader" style="width: 23% !important; font-weight: bold; font-size: 18px;">
            <div class="subContHeader" style="padding: 20px;">
                <div class="subContHeader2" style="text-align: left;">
                    Empleado
                </div>
            </div>
        </div>
        <div class="itemHeader" [ngStyle]="{'width': daysList.length == 1 ? '77%' : '11%'}" *ngFor="let item of daysList">
            <div class="subContHeader">
                <div class="subContHeader2" style="width: 50%; font-size: 50px; font-weight: bold;">
                    {{item.day}}
                </div>
                <div style="width: 100%; height: 100%;">
                    <div style="width: 100%; height: 50%; display: table; font-weight: bold;">
                        <div style="display: table-cell; vertical-align: bottom; text-align: center;" [ngStyle]="{'font-size': daysList.length == 1 ? '25px': '12px'}">
                            {{item.weekDay}}
                        </div>
                    </div>
                    <div style="width: 100%; height: 50%; display: table;">
                        <div style="display: table-cell; vertical-align: top; text-align: center;" [ngStyle]="{'font-size': daysList.length == 1 ? '25px': '12px'}">
                            {{item.month}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngFor="let project of filterData" style="width: 100%;">
        <div *ngIf="searchProject == '' || searchProject == project.projectId">
            <div class="menuProject" (click)="project.open = !project.open">
                <i class="fas fa-chevron-down" *ngIf="project.open"></i> <i class="fas fa-chevron-right" *ngIf="!project.open"></i> {{project.projectIdIca}} - {{project.projectName}}
            </div>
            <div style="width: 100%;" *ngIf="project.open">
                <div style="width: 100%;" *ngFor="let department of project.departments">
                    <div *ngIf="department.employees.length > 0 || (searchDepartment == department.departmentId || searchDepartment =='')">
                        <div class="menuAprtment" (click)="department.open = !department.open">
                            <i class="fas fa-chevron-down" *ngIf="department.open"></i><i class="fas fa-chevron-right" *ngIf="!department.open"></i> {{department.departmentName}}
                            <button class="addShiftButton" *ngIf="usersService.crudPermissions('SHIFTS_CREATE')" (click)="$event.stopPropagation();  openModal(project, department)">Agregar turno <i class="fa fa-plus" style="color: white"></i></button>
                        </div>
                        <div style="width: 100%;" *ngIf="department.open">
                            <div class="tableHeader" *ngFor="let employee of department.employees" style="border-top: 1px solid gray;">
                                <div class="tableHeader" *ngIf="searchShift == '' || searchShift == employee.shift.shiftId" style="border-top: 1px solid gray;">
                                    <div class="itemHeader" style="width: 23% !important; font-weight: bold; font-size: 16px;">
                                        <div class="subContHeader" style="padding: 20px;">
                                            <div class="subContHeader2" style="text-align: left;">
                                                {{employee.employeeName}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="itemHeader" *ngFor="let item of daysList" [ngStyle]="{'width': daysList.length == 1 ? '77%' : '11%', 'overflow': 'auto'}">
                                        <div class="subContHeader">
                                            <div class="subContHeader2" style="width: 100%; background-color: white;">
                                                <div style="width: 100%;" *ngIf="employee.shift != null && employee.shift.dueDate <= item.date ">
                                                    <div style="width: 100%;" *ngFor="let schedule of employee.shift.schedule">
                                                        <div class="shiftClass"  [ngStyle]="{'background-color': employee.shift.shiftColor + '80', 'border': '2px solid '+ employee.shift.shiftColor}" *ngIf="schedule[item.dayEn] && schedule.tagActive == 1">
                                                            <b style="width: 100%;">{{employee.shift.shiftName}}</b>
                                                            {{schedule.startHour}}hrs - {{schedule.endHour}}hrs
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

