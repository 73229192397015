import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private URL = 'users'
    profiles:any;
    constructor(private apiService: ApiService) {}

    async getUsers(){
        const responseUsers: any = await this.apiService.get(this.URL);
        if(responseUsers.statusCode === 200){
            return responseUsers.data.items;
        }
    }

    async getUserById(id: any){
        const responseUser: any = await this.apiService.get(`${this.URL}/${id}`);
        if(responseUser.statusCode === 200){
            return responseUser.data;
        }
    }

    getProfilesModule(modulepermissionKey:any)
    {
       this.profiles= JSON.parse(localStorage.getItem('profiles')) ;
        //console.log(this.profiles);
      // var res = this.profiles.filter(o => Object.keys(o).some(k => o[k] != null && o[k] == moduleId));
      if(!this.profiles || this.profiles.length == 0){
        return false;
      }
      return   modulepermissionKey.filter(argPK => this.profiles.filter(pk => pk.startsWith(argPK+'_')).length>0 ).length>0;
     
     
    }
    crudPermissions(permit:string)
    {
        
        this.profiles= JSON.parse(localStorage.getItem('profiles')) ;
        return   this.profiles.filter(pk => pk == permit ).length>0;
      
}
    routingPermissions()
    {
        if(JSON.parse(localStorage.getItem('profiles')).length>0)
        {
            this.profiles= JSON.parse(localStorage.getItem('profiles')) ;
            let pages = [['EMPLOYEE','employees/employees'],['GROUP_WORK','employees/work_groups'],
                        ['APPROVE_ASSISTANCE','attendance/approve_attendance'],['SHIFTS','attendance/schedule'],
                        ['JUSTIFICATION','attendance/exculpatory'],['REQUEST_TIME','requests/time_request'],
                        ['REQUEST_ADDITIONAL','requests/aditional_request'],['TIME_SHEET','time_sheets/time_sheets'],
                        ['HISTORY_EMPLOYEE','record/employee_record'],['HISTORY_ASSISTANCE','record/assist_record'],
                        ['HISTORY_AUTHORIZATIONS','record/auth_record'],['HISTORY_GROUP_WORK','record/workgroup_record'],
                        ['HISTORY_TABULATOR','record/tab_record'],['USER','settings/users'],
                        ['PROFILE','settings/profiles'],['PROJECT','settings/projects'],
                        ['AGREEMENT','settings/agreement'],['ACCESS','settings/access'],
                        ['CALENDR','settings/calendar'],['EVENTS','settings/inci_vec'],
                        ['INVENTORY','devices/inventory'],['ADMINISTRATION','/devices/inventory'],
                        ['COLLECTIVE_AGREEMENT','settings/tabs']];
            let page = '';
            let ban =0;
            pages.forEach(argPK => {
                if(this.profiles.filter(pk => pk.startsWith(argPK[0]+'_')).length>0){
                    if(ban==0)
                    {
                        page = argPK[1];
                        ban=1;
                    }
                }
            });
            
            return page;

            


            /*

     
    
            
            */
        }
      
    }


    async getDepartmentByProjectId(id: any){
        const responsePosition: any = await this.apiService.get(`${this.URL}/departments?projectsIds=[${id}]`);
        if(responsePosition.statusCode === 200){
            return responsePosition.data;
        }
    }

    async createUser(user:FormData) : Promise<any>{
        return this.apiService.postFile(`${this.URL}`, user);
    }

    async updateUser(id:any, user: FormData): Promise<any>{
 
        return this.apiService.putFile(`${this.URL}`, id, user);
    }

    async deleteUser(id:any)
    {
        const deleteUser = await this.apiService.delete(`${this.URL}`,id)
        return deleteUser;
    }

}
