import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
@Injectable({
  providedIn: 'root'
})
export class JustificationsService {
  constructor( private apiService: ApiService) { }

  async getJustifications(){
    const responseTabs: any = await this.apiService.get("justification");
      if(responseTabs.statusCode === 200){
        return responseTabs.data.items;
      }
  }
  async getSyncUpJustifications(){
    const responseTabs: any = await this.apiService.get("justification/getJustifications");
      if(responseTabs.statusCode === 200){
        return responseTabs;
      }
  }
  async applySyncUpJustifications(idJustification:number){
    
    const responseTabs: any = await this.apiService.post(`justification/applyJustification/${idJustification}`,null);
    
      if(responseTabs.statusCode === 201){
        return responseTabs.statusCode;
      }
  }
 
}