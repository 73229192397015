import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api.service'; 

@Injectable({
  providedIn: 'root'
})
export class PeriodService {

  constructor(private apiService: ApiService) { }

  async getPeriodsByCurrenYear(){
    const periods: any = await this.apiService.get("period/getByCurrentYear");
      if(periods.statusCode === 200){
        return periods.data;
      }
  }

  async getEventsByPeriodAndEmployee(employeeNumber:string,periodId){
    
    const periods: any = await this.apiService.get(`period/getEventsByPeriodAndEmployee/${employeeNumber}/${periodId}`);
    
      if(periods.statusCode === 200){
        return periods.data;
      }
  }

}
