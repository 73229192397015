import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { IFilterRecordAssist } from 'src/app/interface/IFilterRecordAssist';
import * as moment from 'moment';
@Injectable()
export class EmployeesService {
    private URL_EMPLOYEE = 'employees';

    constructor(
        private apiService: ApiService
    ) { }

    async getEmployees(){
        const responseEmployees: any = await this.apiService.get(`${this.URL_EMPLOYEE}`);
        if(responseEmployees.statusCode === 200){
            return responseEmployees.data.items;
        }
    }

    async getEmployeeById(id: any){
        const responseEmployee: any = await this.apiService.get(`${this.URL_EMPLOYEE}/${id}`);
        if(responseEmployee.statusCode === 200){
            return responseEmployee.data;
        }
    }

    async editEmployee(id: any, params: any){
        try {
            const responseEmployee: any = await this.apiService.putFile(`${this.URL_EMPLOYEE}`, id, params);
            // if(responseEmployee.statusCode === 200){
                console.log(responseEmployee);
                return responseEmployee;
            // }
        } catch (error) {
            return error;
        }
    }

    async getWorkGroups(){
        const responseWrokGroups: any = await this.apiService.get("workgroup");
        if(responseWrokGroups.statusCode === 200){
            return responseWrokGroups.data.items;
        }
    }

    async getWorkGroupById(id) {
        const responseWrokGroup: any = await this.apiService.get(`workgroup/${id}`);
        if(responseWrokGroup.statusCode === 200){
            return responseWrokGroup.data;
        }
    }

    async createWorkGroup(params){
        const responseWrokGroup: any = await this.apiService.post("workgroup", params);
        return responseWrokGroup;
    }
    async updateWorkGroup(id:any, workGroup: any){
        debugger
        console.log(workGroup);
        const updateWorkGroup = await this.apiService.put(`workgroup`, id, workGroup)
        return updateWorkGroup;
      }
      async deleteWorkGroupUser(workGroupId:any,id:any)
      {
          const deleteUser = await this.apiService.delete(`workgroup/removeEmployee/${workGroupId}`,id)
          return deleteUser;
      }
    

    async getEmployeeHistory(){
        const responseEmployeeHistory: any = await this.apiService.get(`${this.URL_EMPLOYEE}/history`);
        if(responseEmployeeHistory.statusCode == 200){
            return responseEmployeeHistory.data.items;
        }
    }

    async getEmployeesHistoryPeriods(employeeNumber){
        const responseEmployeeHistory: any = await this.apiService.get(`${this.URL_EMPLOYEE}/historyPeriods/${employeeNumber}`);
        if(responseEmployeeHistory.statusCode == 200){
            return responseEmployeeHistory.data;
        }
    }
    async getEmployeesHistoryAssist(employeeNumber, filter:IFilterRecordAssist){
        const responseEmployeeHistory: any = await this.apiService.get(`${this.URL_EMPLOYEE}/historyAttendence/${employeeNumber}`,filter);
        if(responseEmployeeHistory.statusCode == 200){
            return responseEmployeeHistory.data;
        }
    }

    async getEmployeeHistoryShifts(employeeNumber){
        const responseEmployeeHistory: any = await this.apiService.get(`${this.URL_EMPLOYEE}/historyShifts/${employeeNumber}`);
        if(responseEmployeeHistory.statusCode == 200){
            return responseEmployeeHistory.data;
        }
    }

    async getEmployeeHistoryCostDestination(employeeNumber){
        const responseEmployeeHistory: any = await this.apiService.get(`${this.URL_EMPLOYEE}/historyCostDestination/${employeeNumber}`);
        if(responseEmployeeHistory.statusCode == 200){
            return responseEmployeeHistory.data;
        }
    }

    async createNewProgramerShift(shift){
        
        console.log(shift);
        const response: any = await this.apiService.postShiftProgram(`${this.URL_EMPLOYEE}/add-shift`, shift);
        return response;
    }

    async getEmployeeAllShifts(employeeNumber,currentDate){
        
        let params = 
        {
            employeeNumber:employeeNumber,
            startDate:currentDate, 
        }

        const responseShiftAll: any = await this.apiService.get(`${this.URL_EMPLOYEE}/shiftAll`,params);
        if(responseShiftAll.statusCode == 200){
            return responseShiftAll.data;
        }
    }
    async getEmployeeShiftByPeriod (employeeNumber?:number,periodId?:number)
    {
        const responseShift:any = await this.apiService.get(`${this.URL_EMPLOYEE}/getEmployeeShiftByPeriod?employeeNumber=${employeeNumber}&periodId=${periodId}`)
        if(responseShift.statusCode == 200){
            return responseShift.data;
        }
    }

}
