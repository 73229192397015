
 <form novalidate  [formGroup]="formdata">
<div class="modal-header">
    <h4 class="modal-title"><b>{{title}}</b></h4>
  
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="employee">
        <div class="row">
            <label><b>Turno actual</b></label>
            <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 5px;">
                <label>Turno 1</label>
                <select class="form-control" placeholder="Seleccione un turno"   formControlName="shiftId" >
                  <option selected value="">Selecciona un turno</option>
                  <option *ngFor="let shift of shifts" [value]="shift.shiftId" >{{shift.shiftName}}</option>
              </select>
            </div>
            <div class="col-6" style="padding: 5px;">
                <label><b>Fecha de inicio de turno</b></label>
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input matInput [matDatepickerFilter]="myFilter" [min]="minDatesArray[0]"  [matDatepicker]="picker" formControlName="startDate">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
               
            </div>
            <div class="col-6" style="padding: 5px;">
                <label><b>Fecha de fin de turno</b></label>
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input matInput  disabled   [matDatepicker]="picker2" formControlName="endDate">
                  <mat-datepicker-toggle matSuffix [disabled] [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 ></mat-datepicker>
                </mat-form-field>
               
            </div>
        </div>
  
  <div formArrayName="shifts"
  *ngFor="let shift of formdata.get('shifts')['controls']; let i = index; let ultimo=last" 
  >
  <div [formGroupName]="i" >

    <div class="row">
     
      <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 5px;">
        <fieldset [disabled]="!ultimo">
          <label [attr.for]="'Shift' + i">Turno {{i+2}}</label>
          <select class="form-control" placeholder="Seleccione un turno"    [attr.id]="'newShift' + i" formControlName="shiftId" >
            <option selected value="">Selecciona un turno</option>
            <option *ngFor="let shift of shifts" [value]="shift.shiftId" >{{shift.shiftName}}</option>
        </select>
      </fieldset>
      </div>
      <div class="col-6" style="padding: 5px;">
          <label [attr.for]="'ShiftdateStart' + i" ><b>Fecha de inicio de turno</b></label>
          <mat-form-field class="example-full-width" appearance="fill" >
            <mat-label [attr.for]="'choiceShiftdateStart' + i">Selecciona una fecha</mat-label>
            <input matInput [matDatepickerFilter]="myFilter" [disabled]="!ultimo" [min]="minDatesArray[i+1]" (dateChange)="validations(i)"  [matDatepicker]="otro"      formControlName="startDate">
            <mat-datepicker-toggle matSuffix [disabled]="!ultimo" [for]="otro"></mat-datepicker-toggle>
            <mat-datepicker class="ui-timepicker-container" ref-otro></mat-datepicker>
          </mat-form-field>
         
      </div>
      <div class="col-6" style="padding: 5px;">
        <fieldset disabled>
          <label [attr.for]="'ShiftdateEnd' + i"><b>Fecha de fin de turno</b></label>
          <mat-form-field class="example-full-width ui-timepicker-container" appearance="fill">
            <mat-label [attr.for]="'choiceShiftdateEnd' + i">Selecciona una fecha</mat-label>
            <input matInput     [matDatepicker]="ii" [disabled]="true" formControlName="endDate">
            <mat-datepicker-toggle matSuffix [disabled]="true"  [for]="ii"></mat-datepicker-toggle>
            <mat-datepicker class="ui-timepicker-container"class="ui-timepicker-container" #ii  ></mat-datepicker>
          </mat-form-field>
        </fieldset>
      </div>
  </div>
  
  </div>
  <div class="row" >
    <div class="mt-2 mb-2 col-md-12  " >
      <i type="button" class="  fa fa-trash" aria-hidden="true" *ngIf="ultimo"  title="Eliminar" style="width:5%; float: right; color: red; font-size:large ;" (click)="deleteShift(i)"></i>
    </div>
  </div>
  </div>
</div>
  <div class=" col-sm-12 col-md-12 col-lg-12 form-group ml-8" >
    <a class="justify-content-space-between" style="color: #187DE4; cursor: pointer; margin-left: 80%;" (click)="addNewShift()" >+ Agregar horario</a>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
    <button type="button"  class="btn btn-primary" (click)="confirmSave()">Guardar</button>
  </div>
</form>
