import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { tap } from 'rxjs/operators'
import { Router } from '@angular/router';
import { AuthService } from '../../../modules/auth/_services/auth.service';
@Injectable()
export class InterceptService implements HttpInterceptor {
exclude:any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
	this.exclude = request;
    return next.handle(request).pipe(
			tap(
				event => {

					   if (event instanceof HttpResponse) {
							if(!event.url.includes("/notification"))
							{
								document.getElementById('maskLoading').style.display = "none";
							}
					  }else{
							if(!this.exclude.url.includes("/notification"))
							{
								document.getElementById('maskLoading').style.display = "block";
							}
					  }
					  return true;
					
					
				},
				async (error) => {
					

					if(error.url.includes("/notification"))
					{
						return;
					}else{
						document.getElementById('maskLoading').style.display = "none";
					}

					switch(error.status){
						case 401:
							this.authService.logout();
							//this.router.navigate(['auth/login']);
							return;
						case 403:
							Swal.fire({  
								icon: 'error',  
								title: 'Oops...',  
								text: 'No cuenta con los permisos para realizar esa acción' 
							  });
							  return;
							  case 400:
								Swal.fire({  
									icon: 'error',  
									title: 'Oops...',  
									text: error.error.message 
								  });
						default:
							// 	if(this.cookie.get("remindme")=="true"){
						// 		const user: any = await this.api.post("login/refreshToken", { refreshToken: this.refreshToken })
						// 		this.store.dispatch(new Login({authToken: user.data.token, refreshToken: user.data.refreshToken}));
						// 		window.location.reload();
						// 		return;
						// 	}
							
						// 	Swal.fire(error.error.error, error.error.message, "error")
						// 	this.store.dispatch(new Logout())
						// break;

					return;
					}
				}
			)
		);
  }

  constructor(private router: Router, private authService:AuthService) { }
}
