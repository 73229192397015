import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AttendanceRoutingModule } from './attendance-routing.module';
import { ScheduleComponent } from './schedule/schedule.component';
import { AttendanceComponent } from './attendance.component';
import { ScheduleService } from '../../../services/schedule/schedule.service';
import { ExculpatoryComponent } from './exculpatory/exculpatory.component';
import { LayoutModule } from 'src/app/pages/layout.module';
import { AddScheduleModalComponent } from '../attendance/schedule/add-schedule-modal/add-schedule-modal.component';
import { ApproveAttendanceComponent } from './approve-attendance/approve-attendance.component';
import { FormsModule } from '@angular/forms';
import { TimeFechaPipe } from 'src/app/_metronic/core/pipes/time-fecha.pipe';
import { EventsModalComponent } from './events-modal/events-modal.component';






@NgModule({
  declarations: [AttendanceComponent, ScheduleComponent, AddScheduleModalComponent,ExculpatoryComponent, ApproveAttendanceComponent,TimeFechaPipe, EventsModalComponent],
  imports: [
    CommonModule,
    AttendanceRoutingModule,
    LayoutModule,
    //ReactiveFormsModule,
    FormsModule
  ],
  providers:[
    ScheduleService
  ]
})
export class AttendanceModule { }
