// Angular
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'timeFecha',
})
export class TimeFechaPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    return value && value != "-" ? moment(value).format('HH:mm') : '--';
  }
}
