import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public url = environment.apiUrl;
  constructor(
    private http: HttpClient
  ) { }

  
  setHeaders(params?) {
    const token = localStorage.getItem('token');
    // const token = environment.token;
    if (params) {
      const options = {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': `bearer ${token}`
        }),
        params: { filters: JSON.stringify(params) }
      };
      return options;
    } else {
      const options = {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': `bearer ${token}`
        })
      };
      return options;
    }
  }

  setHeadersProgress(params?) {
    const token = localStorage.getItem('token');
    // const token = environment.token;
    if (params) {
      const options = {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': `bearer ${token}`
        }),
        params: { filters: JSON.stringify(params) },
        reportProgress: true
      };
      return options;
    } else {
      const options = {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': `bearer ${token}`
        }),
        reportProgress: true
      };
      return options;
    }
  }

  setHeadersGet(params?) {
    const token = localStorage.getItem('token');
    // const token = environment.token;
    if (params) {
      const options = {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': `bearer ${token}`
        }),
        params: params
      };
      return options;
    } else {
      const options = {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf-8",
          'Authorization': `bearer ${token}`
        })
      };
      return options;
    }
  }

  get(page, params? ) {
    return this.http.get(`${this.url}${page}`, this.setHeadersGet(params) ).toPromise();
  }
  post(page, body ) {
    
    
    return this.http.post(`${this.url}${page}`, body, this.setHeaders()).toPromise();
  }
  postShiftProgram(page, body ) {
    
    
    return this.http.post(`${this.url}${page}`, body, this.setHeaders()).toPromise();
  }


  postProgress(page, body ) {
    
    return this.http.post(`${this.url}${page}`, body, this.setHeadersProgress()).toPromise();
  }


  postFile(page, body: FormData) {
    const token = localStorage.getItem('token');
    const uploadOptions = {
      headers: new HttpHeaders({
        'Authorization': `bearer ${token}`,
      })
    };
    
    return this.http.post(`${this.url}${page}`, body, uploadOptions).toPromise();
  }

  


  put(page:string, id:number, body:any) {
   
    return this.http.put(`${this.url}${page}/${id}`, body, this.setHeaders()).toPromise();
  }


  putNotifi(page, id,status) {
    return this.http.put(`${this.url}${page}/${id}/${status}`,null, this.setHeaders()).toPromise();
  }

  putUrl(page, body?) {
    return this.http.put(`${this.url}${page}`, body, this.setHeaders()).toPromise();
  }



  putFile(page, id, body: FormData) {
    const token = localStorage.getItem('token');
    const uploadOptions = {
      headers: new HttpHeaders({
        'Authorization': `bearer ${token}`,
      })
    };
    
    return this.http.put(`${this.url}${page}/${id}`, body, uploadOptions).toPromise();
  }

  
  delete(page, id) {
    return this.http.delete(`${this.url}${page}/${id}`, this.setHeaders()).toPromise();
  }
  // loginHeaders(token) {
  //   const options = {
  //     headers: new HttpHeaders({
  //       'Access-Control-Allow-Origin': '*',
  //       'Content-Type': 'application/json; charset=utf-8',
  //       'Authorization': `bearer ${token}`
  //     })
  //   };
  //   return options;
  // }
  loginHeaders() {
    const options = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8'
      })
    };
    return options;
  }
  login(page, body) {
    
    return new Promise(async (resolve, reject) => {
      const login = await this.http.post(`${this.url}${page}`, body, this.loginHeaders()).toPromise().then(
        (res: any) => {
          
          if (res.statusCode === 201) {
            resolve(res.data);
          } 
          else if (res.status === 500) {
            reject(res.error);
          }else {
            reject(res.error);
          }
        }
      ).catch(err => { reject(err) });
    })
  }
}
