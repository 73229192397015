import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

  transform(value: string, type: string): any {
    if(!value || value == null || value == undefined || value == ''){
      return ''
    }
    moment.locale('es-es');
    
    if(type == 'datetime'){
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    }
    else if(type == 'date'){
      return moment(value).format('YYYY-MM-DD');
    } else if(type == 'time'){
      return moment(value).format('HH:mm');
    }else if(type == 'dayLetter'){
      return moment(value).format('dddd').toUpperCase();
    }

    return value;
  }

  getMonth(value){
    let month = "";
    switch(value){
      case '01':
      month = "Enero";
      break;

      case '02':
      month = "Febrero";
      break;

      case '03':
      month = "Marzo";
      break;

      case '04':
      month = "Abril";
      break;

      case '05':
      month = "Mayo";
      break;

      case '06':
      month = "Junio";
      break;

      case '07':
      month = "Julio";
      break;

      case '08':
      month = "Agosto";
      break;

      case '09':
      month = "Septiembre";
      break;

      case '10':
      month = "Octubre";
      break;

      case '11':
      month = "Noviembre";
      break;

      case '12':
      month = "Diciembre";
      break;
    }
    return month;
  }

  getDayOfWeek(value){
    value = moment(value).format('LLL');
    let tmpDate = new Date(value);
    let tmpDay = tmpDate.getDay();
    let day = '';
    switch(tmpDay){
      case 0:
      day = 'Domingo';
      break;
      
      case 1:
      day = 'Lunes';
      break;

      case 2:
      day = 'Martes';
      break;

      case 3:
      day = 'Miércoles';
      break;

      case 4:
      day = 'Jueves';
      break;

      case 5:
      day = 'Viernes';
      break;

      case 6:
      day = 'Sábado';
      break;
    }
    return day;
  }

}
