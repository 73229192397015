<form novalidate  [formGroup]="formdata" >
<div class="modal-header">
    <h4 class="modal-title"><b>Crear grupo de trabajo</b></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block;">
                    <label><b>Nombre de grupo de trabajo</b></label>
                    <input type="text" class="form-control" [value]="data.workgroupName" (keyup)="data.workgroupName = $event.target.value">
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block;">
                    <label><b>Empresa</b></label>
                    <select class="form-control" (change)="getProjects($event.target.value)">
                        <option value="" selected>Seleccione una empresa</option>
                        <option *ngFor="let company of companies" [value]="company.companyId" [selected]="data.companyId == company.companyId">{{company.companyName}}</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Proyecto</b></label>
                    <select class="form-control" [disabled]="projects.length == 0" (change)="getAgreements($event.target.value)">
                        <option value="" selected>Seleccione un proyecto</option>
                        <option *ngFor="let project of projects" [value]="project.projectId" [selected]="data.projectId == project.projectId">{{project.projectName}}</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Convenio</b></label>
                    <select class="form-control" [disabled]="agreements.length == 0" (change)="getDepartments($event.target.value)">
                        <option value="" selected>Seleccione un convenio</option>
                        <option *ngFor="let agreement of agreements" [value]="agreement.agreementId" [selected]="data.agreementId == agreement.agreementId">{{agreement.agreementNumber}} - {{agreement.agreementName}}</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Departamento</b></label>
                    <select class="form-control" [disabled]="departments.length == 0" (change)="getCostDestination($event.target.value)">
                        <option value="" selected>Seleccione un departamento</option>
                        <option *ngFor="let department of departments" [value]="department.departmentId" [selected]="data.departmentId == department.departmentId">{{department.departmentName}}</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Destino de costo</b></label>
                    <select class="form-control" [disabled]="costDestination.length == 0" (change)="data.costDestinationId = $event.target.value">
                        <option value="" selected>Seleccione un destino de costo</option>
                        <option *ngFor="let cost of costDestination" [value]="cost.costDestinationId" [selected]="data.costDestinationId == cost.costDestinationId">{{cost.costDestinationName}}</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6" style="display: inline-block; margin-top: 20px;">
                    <label><b>Turno</b></label>
                    <select class="form-control" [disabled]="shifts.length == 0" (change)="data.shiftId = $event.target.value">
                        <option value="" selected>Seleccione un turno</option>
                        <option *ngFor="let shift of shifts" [value]="shift.shiftId" [selected]="data.shiftId == shift.shiftId">{{shift.shiftName}}</option>
                    </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12" style="display: inline-block; margin-top: 20px;">
                    <label><b>Lider(es) grupo de trabajo</b></label>
                    <ng-select [multiple]="true"  (change)="getLeaders($event)" formControlName="leaders" >
                        <ng-option [value]="employee.employeeId" *ngFor="let employee of tmpLeaders">{{employee.employeeNumber}}-{{employee.employeeName}}</ng-option>
                    </ng-select>
                </div>
                <div class="mt-2" *ngIf="employeesWithWorkGroup.length>0">
                    <div class="row" id="i" *ngFor="let employee of employeesWithWorkGroup; let i = index">
                        <p style="color:red; margin-left: 2%;" value="employee.employeeId" >El empleado {{employee.employeeNumber}}-{{employee.employeeIdName}} ya se encuentra en otro grupo de trabajo.</p>
                        <a style="font-style: none; cursor:pointer; color:blue; margin-left:1%;" (click)="changeWorkGroup(employee.employeeId,i)">Cambiar a este grupo</a>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12" style="display: inline-block; margin-top: 20px;">
                    <label><b>Integrantes grupo de trabajo</b></label>
                    <ng-select [multiple]="true" (change)="getSubordinates($event)" formControlName="subordinates">
                        <ng-option [value]="employee.employeeId" *ngFor="let employee of tmpSubordinates">{{employee.employeeNumber}}-{{employee.employeeName}}</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
  </div>
</form>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
    <button type="button" class="btn btn-primary" (click)="saveWorkGroup()">Guardar</button>
  </div>
