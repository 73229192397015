<div class="modal-header">
    <h4 class="modal-title"><b>Agregar turno</b></h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body">
    <div class="row">
        <div class="row row-full-height" style="padding-left: 20px; padding-right: 20px;">
            <div class="col-6" style="padding: 5px;">
                <label><b>Empresa</b></label>
                <input type="text" disabled class="form-control" [value]="info.companyName">
            </div>
            <div class="col-6" style="padding: 5px;">
                <label><b>Proyecto</b></label>
                <input type="text" disabled class="form-control" [value]="info.projectName">
            </div>
            <div class="col-6" style="padding: 5px;">
                <label><b>Convenio</b></label>
                <input type="text" disabled class="form-control" [value]="info.agreementName">
            </div>
            <div class="col-6" style="padding: 5px;">
                <label><b>Departamento</b></label>
                <input type="text" disabled class="form-control" [value]="info.departmentName">
            </div>
            <div class="col-12" style="padding: 5px;">
                <label><b>Turno</b></label>
                <select class="form-control" (change)="getShift($event.target.value)">
                    <option value="" selected>Seleccione un turno</option>
            <option *ngFor="let shift of shifts" [value]="shift.shiftId">{{shift.shiftName}}</option>
                </select>
            </div>
            <div class="col-12" style="padding: 5px;">
                <label><b>Notas</b></label>
                <textarea class="form-control" style="height: 150px;" (keyup)="getNotes($event.target.value)"></textarea>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
    <button type="button" [disabled]="selectedShift == '' || note == ''" class="btn btn-primary" (click)="saveShift()">Guardar</button>
</div>