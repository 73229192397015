import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeesRoutingModule } from './employees-routing.module';
import { EmployeesComponent1 } from './employees.component';
import { LayoutModule } from '../../layout.module';
import { EmployeesComponent } from './employees/employees.component';
import { EmployeesModalComponent } from './employees-modal/employees-modal.component';
import { ModalModule } from 'ngb-modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule,ReactiveFormsModule } from "@angular/forms";
import { EmployeesService } from '../../../services/employees/employees.service';
import { ShiftsService } from '../../../services/shifts/shifts.service';
import { WorkGroupsComponent } from './work-groups/work-groups.component';
import { WorkGroupsModalComponent } from './work-groups-modal/work-groups-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { WorkGroupEmployeeModalComponent } from './work-group-employee-modal/work-group-employee-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EmployeesShiftsModalComponent } from './employees-shifts-modal/employees-shifts-modal.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { EmployeesFacevalidationModalComponent } from './employees-facevalidation-modal/employees-facevalidation-modal.component';

@NgModule({
  declarations: [EmployeesComponent1, EmployeesComponent, EmployeesModalComponent, WorkGroupsComponent, WorkGroupsModalComponent, WorkGroupEmployeeModalComponent, EmployeesShiftsModalComponent, EmployeesFacevalidationModalComponent],
  imports: [
    CommonModule,
    EmployeesRoutingModule,
    LayoutModule,
    ModalModule,
    
    NgbModule,
    NgSelectModule,
    FormsModule, ReactiveFormsModule,NgMultiSelectDropDownModule,MatInputModule, 
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule
  ],
  providers: [
    EmployeesService,
    ShiftsService,
    MatDatepickerModule
  ]
})
export class EmployeesModule { }
