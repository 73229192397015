import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    private URL_PERIOD = 'period';
    private URL_HOLIDAY = 'holidays';

    constructor(private apiService: ApiService) { }

    async getHolidays(){
        const holidaysResponse: any = await this.apiService.get(`${this.URL_HOLIDAY}`, {});
        if(holidaysResponse.statusCode == 200){
            return holidaysResponse.data.items;
        }
    }

    async getHolidaysFilters(filter: any){
        const holidaysResponse: any = await this.apiService.get(`${this.URL_HOLIDAY}`, filter);
        if(holidaysResponse.statusCode == 200){
            return holidaysResponse.data.items;
        }
    }

    async createHoliday(params){
        const holidaysResponse: any = await this.apiService.post(`${this.URL_HOLIDAY}`, params);
        return holidaysResponse;
    }

    async updateHoliday(params, id){
        const holidaysResponse: any = await this.apiService.put(`${this.URL_HOLIDAY}`,id,params);
        return holidaysResponse;
    }

    async deleteHoliday(id){
        const holidaysResponse: any = await this.apiService.delete(`${this.URL_HOLIDAY}`,id);
        return holidaysResponse;
    }

    async getPeriods(){
        const periodsResponse: any = await this.apiService.get(`${this.URL_PERIOD}`);
        if(periodsResponse.statusCode == 200){
            return periodsResponse.data.items;
        }
    }

    async getLastOpenPeriod(){
        const periodsResponse: any = await this.apiService.get(`${this.URL_PERIOD}/getLastOpenPeriod`);
        if(periodsResponse.statusCode == 200){
            return periodsResponse.data;
        }
    }

    async createPeriod(params) {
        try {
            const periodResponse: any = await this.apiService.post(`${this.URL_PERIOD}`,params);
            return periodResponse;
        } catch (error) {
            return error;
        }
    }
    async updatePeriod(id, data) {
       
        try {
            const periodResponse: any = await this.apiService.putUrl(`period/updateStatus/${id}/${data.periodStatus}`);
            return periodResponse;
        } catch (error) {
            return error;
        }
    }
    async periodRefreshProcessAttendance(id){
      
        const refreshPeriodResponse: any = await this.apiService.put(`${this.URL_PERIOD}/processAttendance`,id,null);
        return refreshPeriodResponse;
    }

}
