import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-employees-facevalidation-modal',
  templateUrl: './employees-facevalidation-modal.component.html',
  styleUrls: ['./employees-facevalidation-modal.component.scss']
})
export class EmployeesFacevalidationModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
  }

}
