<!-- begin::User Panel-->
<div id="kt_quick_user" class="offcanvas p-10" style="overflow: scroll;" [ngClass]="extrasUserOffcanvasDirection">
  <ng-container *ngIf="user$ | async as _user">
    <!--begin::Header-->
    <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
      <h3 class="font-weight-bold m-0">
        Admin Admin
        <!-- <small class="text-muted font-size-sm ml-2">12 messages</small> -->
      </h3>
      <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
        <i class="ki ki-close icon-xs text-muted"></i>
      </a>
    </div>
    <!--end::Header-->
    <div class="subHeaderContainer row" style="width: 100%;">
      <div class="selector">
          <div class="selectorButton" [ngStyle]="{'border-bottom': (view == 1) ? '3px solid  ' : '3px solid #d6cfcf '}" (click)="chngeView(1)">
             Notificaciones
          </div>
      </div>
      <div class="selector">
          <div class="selectorButton" [ngStyle]="{'border-bottom': (view == 2) ? '3px solid #264660' : '3px solid #d6cfcf'}" (click)="chngeView(2)">
              Configuraciones
          </div>
      </div>
  </div>
    <!--begin::Content-->
    <div *ngIf="view == 1">
      <div *ngFor="let notification of dataNotifications; let i = index" [attr.id]="notification.notificationId" [ngClass]="{'notification': notification.status == '0'}" style="cursor: pointer;" (click)="readNotifi(notification.notificationId,readNotification, notification)">
        
        <p class="mt-4 mr-4" style="text-align: end;">{{notification.createdAt.substr(0,9) | date:'dd/MM/yyyy'}}</p>
        <div class="row">
          <i style="color: #223F56;" class="fas fa-exclamation-circle ml-6"></i>
          <p class="ml-4">{{notification.messageNotification}}</p>
        </div>
        <strong class="ml-8" style="color: #223F56; font-size: bold;">{{notification.receivingUserId.userId}} - {{notification.receivingUserId.firstName}} {{notification.receivingUserId.lastName}}</strong>
      </div>
    </div>
    <div *ngIf="view == 2">
      <strong class="ml-5" style="color: #223F56; font-size: bold;">Periodo de trabajo</strong>
      <div class="row d-flex justify-content-around mt-4">
        <div class="custom-control custom-radio custom-control-inline" (change)="changePeriod()">
          <input type="radio" id="customRadioInline1"  name="customRadioInline" [checked]="checked[0]" value="0"  class="custom-control-input">
          <label class="custom-control-label" for="customRadioInline1">Semanal</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline" (change)="changePeriod()">
          <input type="radio" id="customRadioInline2" name="customRadioInline" [checked]="checked[1]" value="1"  class="custom-control-input">
          <label class="custom-control-label" for="customRadioInline2">Catorcenal</label>
        </div>
        
      </div>




    </div>


    <div class="offcanvas-content pr-5 mr-n5">
      <!--begin::Header-->
      <div class="d-flex align-items-center mt-5">
        <div class="symbol symbol-100 mr-5">
          <!-- <div class="symbol-label" style="background-image: url('./assets/media/users/300_21.jpg');"></div>
          <i class="symbol-badge bg-success"></i> -->
        </div>
        <div class="d-flex flex-column">
          <!-- <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
            {{ _user.firstname }} {{ _user.lastname }}
          </a> -->
          <!-- <div class="text-muted mt-1">
            Application Developer
          </div> -->
         
          <div class="navi mt-2">
            <!-- <a href="#" class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <span [inlineSVG]="
                      './assets/media/svg/icons/Communication/Mail-notification.svg'
                    " cacheSVG="true" class="svg-icon svg-icon-lg svg-icon-primary"></span>
                </span>
                <span class="navi-text text-muted text-hover-primary">{{
                  _user.email
                }}</span>
              </span>
            </a> -->

            <a class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5 cursor-pointer" (click)="logout()">Cerrar sesión</a>
          </div>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Separator-->
      <!-- <div class="separator separator-dashed mt-8 mb-5"></div> -->
      <!--end::Separator-->

      <!--begin::Nav-->
      <div class="navi navi-spacer-x-0 p-0">
        <!--begin::Item-->
        <!-- <a class="navi-item cursor-pointer" routerLink="/user-profile">
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span [inlineSVG]="
                    './assets/media/svg/icons/General/Notification2.svg'
                  " cacheSVG="true" class="svg-icon svg-icon-md svg-icon-success"></span>
              </div>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                My Profile
              </div>
              <div class="text-muted">
                Account settings and more
                <span class="label label-light-danger label-inline font-weight-bold">update</span>
              </div>
            </div>
          </div>
        </a> -->
        <!--end:Item-->

        <!--begin::Item-->
        <!-- <a class="navi-item cursor-pointer">
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span [inlineSVG]="
                    './assets/media/svg/icons/Shopping/Chart-bar1.svg'
                  " cacheSVG="true" class="svg-icon svg-icon-md svg-icon-warning"></span>
              </div>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                My Messages
              </div>
              <div class="text-muted">
                Inbox and tasks
              </div>
            </div>
          </div>
        </a> -->
        <!--end:Item-->

        <!--begin::Item-->
        <!-- <a class="navi-item cursor-pointer">
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span [inlineSVG]="
                    './assets/media/svg/icons/Files/Selected-file.svg'
                  " cacheSVG="true" class="svg-icon svg-icon-md svg-icon-danger"></span>
              </div>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                My Activities
              </div>
              <div class="text-muted">
                Logs and notifications
              </div>
            </div>
          </div>
        </a> -->
        <!--end:Item-->

        <!--begin::Item-->
        <!-- <a class="navi-item cursor-pointer">
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span [inlineSVG]="
                    './assets/media/svg/icons/Communication/Mail-opened.svg'
                  " cacheSVG="true" class="svg-icon svg-icon-md svg-icon-primary"></span>
              </div>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                My Tasks
              </div>
              <div class="text-muted">
                latest tasks and projects
              </div>
            </div>
          </div>
        </a> -->
        <!--end:Item-->
      </div>
      <!--end::Nav-->

      <!--begin::Separator-->
      <!-- <div class="separator separator-dashed my-7"></div> -->
      <!--end::Separator-->

      <!--begin::Notifications-->
      <div>
        <!--begin:Heading-->
        <!-- <h5 class="mb-5">
          Recent Notifications
        </h5> -->
        <!--end:Heading-->

        <!--begin::Item-->
        <!-- <div class="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
          <span class="svg-icon svg-icon-warning mr-5">
            <span [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'" cacheSVG="true"
              class="svg-icon svg-icon-lg"></span>
          </span>

          <div class="d-flex flex-column flex-grow-1 mr-2">
            <a href="#" class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Another purpose
              persuade</a>
            <span class="text-muted font-size-sm">Due in 2 Days</span>
          </div>

          <span class="font-weight-bolder text-warning py-1 font-size-lg">+28%</span>
        </div> -->
        <!--end::Item-->

        <!--begin::Item-->
        <!-- <div class="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
          <span class="svg-icon svg-icon-success mr-5">
            <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" cacheSVG="true"
              class="svg-icon svg-icon-lg"></span>
          </span>
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <a href="#" class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Would be to
              people</a>
            <span class="text-muted font-size-sm">Due in 2 Days</span>
          </div>

          <span class="font-weight-bolder text-success py-1 font-size-lg">+50%</span>
        </div> -->
        <!--end::Item-->

        <!--begin::Item-->
        <!-- <div class="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
          <span class="svg-icon svg-icon-danger mr-5">
            <span [inlineSVG]="
                './assets/media/svg/icons/Communication/Group-chat.svg'
              " cacheSVG="true" class="svg-icon svg-icon-lg"></span>
          </span>
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <a href="#" class="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">Purpose would be to
              persuade</a>
            <span class="text-muted font-size-sm">Due in 2 Days</span>
          </div>

          <span class="font-weight-bolder text-danger py-1 font-size-lg">-27%</span>
        </div> -->
        <!--end::Item-->

        <!--begin::Item-->
        <!-- <div class="d-flex align-items-center bg-light-info rounded p-5">
          <span class="svg-icon svg-icon-info mr-5">
            <span [inlineSVG]="'./assets/media/svg/icons/General/Attachment2.svg'" cacheSVG="true"
              class="svg-icon svg-icon-lg"></span>
          </span>

          <div class="d-flex flex-column flex-grow-1 mr-2">
            <a href="#" class="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">The best
              product</a>
            <span class="text-muted font-size-sm">Due in 2 Days</span>
          </div>

          <span class="font-weight-bolder text-info py-1 font-size-lg">+8%</span>
        </div> -->
        <!--end::Item-->
      </div>
      <!--end::Notifications-->
    </div>
    <!--end::Content-->
  </ng-container>
</div>
<ng-template #readNotification let-c="close" let-d="dismiss">
    
  <div class="modal-header">
    <h4 class="modal-title titulos " style="font-weight: bold;" id="modal-basic-title">{{notificationId.subject}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span >x</span>
    </button>
  </div>
  <div class="modal-body" style="font-weight: bold; width: 100%;">
    <p style="text-align: center;">{{notificationId.description}}</p>
    <div class="row shadows justify-content-between" style="background-color: whitesmoke; display: flex; align-items:center" >
      <div class="col-md-12 row">
        <div class="col-md-6" style="text-align: center; margin-left: 10%;"><strong>NOMBRE</strong></div>
        <div class="col-md-6" style="text-align: center; margin-left: -15%;"><strong>PAPEL</strong></div>
      </div>
      
      <div class="col-md-3 ">
          <img style="width: 100%; height: 100px; ; display: inline-block;" [src]="imgUser">
         
      </div>
      <div class="col-md-4 row">
          <strong >{{notificationId.userId}}&nbsp; &nbsp;  </strong>
          <p>{{notificationId.fullName}}</p>
      </div>
      <div class="col-md-5" style="margin-top: -3%;">
          <strong >{{notificationId.position}}</strong>
          
      </div>
      
  </div>

  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn btn-danger " (click)="d('Cross click')">Cerrar</button>
      
  </div>

</ng-template>
<!-- end::User Panel-->