import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalManager } from 'ngb-modal';
import { SharedVarServiceService } from '../../../../services/SharedVarService/shared-var-service.service';
import { AuthService } from '../../../../modules/auth/_services/auth.service';
import { EmployeesService } from '../../../../services/employees/employees.service';
import { EmployeesModalComponent } from '../employees-modal/employees-modal.component';
import { UsersService } from '../../../../services/settings/users/users.service';
import { Router } from '@angular/router';
import { EmployeesShiftsModalComponent } from '../employees-shifts-modal/employees-shifts-modal.component';
import { debug } from 'console';
@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  tableData: any;
  data: any= [];
  searchVal:any = "";
  title = "Empleados";
  

  constructor(
    private modalService: NgbModal,
    private _employeesService: EmployeesService,
    private _cdr: ChangeDetectorRef,
    private sharedVar: SharedVarServiceService,
    private userService:UsersService,
    private router:Router,
    
    config: NgbModalConfig) {
    config.backdrop = "static";
    config.keyboard = false;
    this.getEmployees();

    if(!userService.getProfilesModule(['EMPLOYEE']))
     {
      this.router.navigate([userService.routingPermissions()]);
     }
    

  }

  async getEmployees(){
    this.data = await this._employeesService.getEmployees();
    console.log(this.data);
    let dataEmployees:any=[];
    for (let index = 0; index < this.data.length; index++) {
      let objectEmployee = {
        employeeId:this.data[index].employeeId,
        employeeName:this.data[index].employeeName,
        employeeNumber: this.data[index].employeeNumber,
        photos: this.data[index].photos,
        companyName: this.data[index].companyName,
        projectName:this.data[index].projectName,
        agreementName:this.data[index].agreementName,
        departmentName:this.data[index].departmentName,
        positionName:this.data[index].positionName,
        period:this.data[index].period,
        rol:this.data[index].rol,
        shiftName:this.data[index].shiftName,
        costDestinationName:this.data[index].costDestinationName,
        tagActive:this.data[index].tagActive
        
      }
      dataEmployees.push(objectEmployee);
      
    }
    this.tableData = {
      limitCol: 5,
      actions: true,
     // actionsButton: true,
      actionsMenu: [{
        text: "Ver",
        icon: "fa fa-eye",
        action: 'view',
        permission:'EMPLOYEE_READ'
      },{
        text: "Editar",
        icon: "fas fa-pencil-alt",
        action: 'edit',
        permission:'EMPLOYEE_UPDATE'     
      },
      {
        text:"Turno",
        icon: "fas fa-stopwatch",
        action:'shift',
        permission:'EMPLOYEE_UPDATE'
      }
    ],
      headers:[{
        text: "# EMPLEADO",
        key: "employeeNumber"
      },
      {
        text: "NOMBRE",
        key: "photos",
        img: true,
        arrayImg: true,
        arrayPointer: 0,
        arrayKey: 'employeePhoto',
        imgOptions:{
          text: true,
          keyText: "employeeName"
        }
      },{
        text: "EMPRESA",
        key: "companyName"
      },
      {
        text: "PROYECTO",
        key: "projectName"
      },{
        text: "CONVENIO",
        key: "agreementName"
      },{
        text: "DEPARTAMENTO",
        key: "departmentName"
      },
      {
        text: "PUESTO",
        key: "positionName"
      },
      {
        text: "PERIODO",
        key: "period"
      },
      {
        text: "ROL",
        key: "rol"
      },
      {
        text: "TURNO",
        key: "shiftName"
      },
      {
        text: "DESTINO DE COSTO",
        key: "costDestinationName"
      },
      {
        text: "VIGENCIA DESTINO DE COSTO",
        key: "effectiveDateCostDestinationString"
      },
      {
        text: "LUGAR DE TRABAJO",
        key: "workplaceName"
      },
      {
        text: "TABULADOR"
        
      },
      {
        text: "Estado",
        key: "tagActive",
        tag: true,
        const: true,
        tagOptions:[{
          value:1,
          colorBg:'#17C827',
          colorText: 'white',
          text: 'Activo'
        },{
          value: 0,
          colorBg:'#B41D1D',
          colorText: 'white',
          text: 'Inactivo'
        }]
      }],
      data: dataEmployees
    };
    this._cdr.detectChanges();
  }

  ngOnInit() {
    this.sharedVar.setValue("<i class='fa fa-user'></i> Empleados • Empleados");
  }

  openModal(data){
  
    if(data.action!="shift")
    {
      try {
        const modalRef = this.modalService.open(EmployeesModalComponent, {centered: true, size:'xl'})
        modalRef.componentInstance.data = data;
        modalRef.result.then( res => {
          
          if (res != undefined && res != "Close click") {
            this.getEmployees();
          }
        })
        } catch (error) {
          console.log(error)
        }
    }
    else if(data.action=="shift")
    {
      try {
        const modalRef = this.modalService.open(EmployeesShiftsModalComponent, {centered: true, size:'lg'})
        modalRef.componentInstance.data = data;
        modalRef.result.then( res => {
          
          if (res != undefined && res != "Close click") {
            this.getEmployees();
          }
        })
        } catch (error) {
          console.log(error)
        }
    }
    
  }
  closeModal(){
      // this.modalService.close(this.modalRef);
      //or this.modalRef.close();
  }

}
