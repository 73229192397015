import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeesComponent1 } from './employees.component';
import { EmployeesComponent } from './employees/employees.component';
import { WorkGroupsComponent } from './work-groups/work-groups.component';

const routes: Routes = [{
  path: '',
  component: EmployeesComponent1,
  children: [
    {
      path: 'employees',
      component: EmployeesComponent,
    },
    {
      path: 'work_groups',
      component: WorkGroupsComponent,
    },
    { path: '', redirectTo: 'employees', pathMatch: 'full' },
    {
      path: '**',
      component: EmployeesComponent,
      pathMatch: 'full',
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeesRoutingModule { }
