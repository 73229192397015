import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './_layout/layout.component';
import { UsersService } from "../services/settings/users/users.service";



const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'builder',
                loadChildren: () => import('./builder/builder.module').then((m) => m.BuilderModule),
            },
            {
                path: 'employees',
                loadChildren: () => import('./views/employees/employees.module').then((m) => m.EmployeesModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/projects/projects.module').then((m) => m.ProjectsModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/profiles/profiles.module').then((m) => m.ProfilesModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/users/users.module').then((m) => m.UsersModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/tabs/tabs.module').then((m) => m.TabsModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/agreement/agreement.module').then((m) => m.AgreementModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/access/access.module').then((m) => m.AccessModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/inci-vect/inci-vect.module').then((m) => m.InciVectModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/calendar/calendar.module').then((m) => m.CalendarModule),
            },
            {
                path: 'attendance',
                loadChildren: () => import('./views/attendance/attendance.module').then((m) => m.AttendanceModule),
            },
            {
                path: 'time_sheets',
                loadChildren: () => import('./views/time_sheets/time-sheets.module').then((m) => m.TimeSheetsModule),
            },
            {
                path: 'record',
                loadChildren: () => import('./views/record/record.module').then((m) => m.RecordModule),
            },
            {
                path: 'devices',
                loadChildren: () => import('./views/devices/devices.module').then((m) => m.DevicesModule),
            },
            {
                path: 'requests',
                loadChildren: () => import('./views/requests/requests.module').then((m) => m.RequestsModule),
            },
            {
                path: '',
                redirectTo: 'employees',
                pathMatch: 'full',
            },
            {
                path: '**',
                redirectTo: 'errors/404',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
