<div class="subheader">
    <div class="subHeaderContainer" *ngIf="action == ''">
        Grupos de trabajo {{countRows}} total
        <input type="text" placeholder="Buscar" class="searchInput" (keyup)="searchVal = $event.target.value">
        <button class="btn btn-primary" style="float: right;" *ngIf="userService.crudPermissions('GROUP_WORK_CREATE')" (click)="openModal(1)">Crear grupo de trabajo</button>
    </div>
    
    <div class="subHeaderContainer" *ngIf="action == 'view'">
        {{workGroupName}}   {{countRows}} empleados total
        
        <button class="btn btn-primary" style="float: right;" *ngIf="userService.crudPermissions('GROUP_WORK_UPDATE')" (click)="openModal(2)">Editar grupo de trabajo</button>
    </div>
</div>
<ls-table *ngIf="action == ''" [dataTable]="tableData" (actionsEvent)="show($event)" [searchVal]="searchVal"></ls-table>
<div style="width: 100%;" *ngIf="action == 'view'">
    <div style="width: 100%;">
        <div class="btn-back" (click)="getWorkGroups()">
            <i class="fas fa-chevron-left" style="color: #3699FF;"></i> Regresar
        </div>
        <div class="infoWorkGroup">
            <div class="infoWorkGroupItem">
                <div style="width: 100%; font-weight: bold; padding: 5px;">
                    Grupo
                </div>
                <div style="width: 100%; padding: 5px;">
                   {{workGroupName}}
                </div>
            </div>
            <div class="infoWorkGroupItem">
                <div style="width: 100%; font-weight: bold; padding: 5px;">
                    Empresa
                </div>
                <div style="width: 100%; padding: 5px;">
                   {{workGroupSelected.companyName}}
                </div>
            </div>
            <div class="infoWorkGroupItem">
                <div style="width: 100%; font-weight: bold; padding: 5px;">
                    Proyecto
                </div>
                <div style="width: 100%; padding: 5px;">
                   {{workGroupSelected.projectName}}
                </div>
            </div>
            <div class="infoWorkGroupItem">
                <div style="width: 100%; font-weight: bold; padding: 5px;">
                    Convenio
                </div>
                <div style="width: 100%; padding: 5px;">
                   {{workGroupSelected.agreementName}}
                </div>
            </div>
            <div class="infoWorkGroupItem">
                <div style="width: 100%; font-weight: bold; padding: 5px;">
                    Destino de costo
                </div>
                <div style="width: 100%; padding: 5px;">
                   {{workGroupSelected.costDestinationName}}
                </div>
            </div>
            <div class="infoWorkGroupItem">
                <div style="width: 100%; font-weight: bold; padding: 5px;">
                    Departamento
                </div>
                <div style="width: 100%; padding: 5px;">
                   {{workGroupSelected.departmentName}}
                </div>
            </div>
            <div class="infoWorkGroupItem">
                <div style="width: 100%; font-weight: bold; padding: 5px;">
                    Turno
                </div>
                <div style="width: 100%; padding: 5px;">
                   {{workGroupSelected.shiftName}}
                </div>
            </div>
        </div>
        <div style="width: 100%; background-color: white; margin-top: 30px; padding-top: 30px;">
            <div style="background-color: #264660; color: white; width: 95%; margin-left: 2.5%; position: relative; top: 30px; font-size: 16px; padding: 10px;">
                Empleados
            </div>
            <div style="width: 95%; margin-left: 2.5%; padding-top: 30px;">
                <ls-table *ngIf="tblEmployees"  [dataTable]="tableDataEmployees" (actionsEvent)="employeeWorkGroup($event)" [searchVal]="searchVal"></ls-table>
            </div>
        </div>
    </div>
</div>

<ng-template #editUser let-c="close" let-d="dismiss"  >
    <form novalidate  [formGroup]="formdata" >
     <div class="modal-header">
       <h4 class="modal-title titulos " style="font-weight: bold;" id="modal-basic-title">{{modalTitle}} </h4>
       <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
         <span >x</span>
       </button>
     </div>
     
     <div class="modal-body" style="font-weight: bold; width: 100%;">
        
         <div class="row d-flex justify-content-between">  
             <div class="col-md-6 align-self-start ">
                 <label for="firstName" class="is-required">Nombre</label>
                 <input class="Inpstyle " type="text"  formControlName="firstName"   placeholder="Inserta un Nombre"  >
             </div>
             <div class="col-md-6 align-self-end ">
                 <label for="lastname" class="is-required">Apellido</label>
                 <input class="Inpstyle " type="text"  formControlName="lastName"  placeholder="Apellido"   >
             </div>
         </div>
         <div class="row d-flex justify-content-between">
                 
             
             <div class="col-md-6 align-self-start ">
                 <label for="password" class="is-required">Contraseña</label>
                 <input class="Inpstyle " type="password"  formControlName="password" autocomplete="new-password" #password  placeholder="contraseña"   >
 
             </div>
             <div class="col-md-6 align-self-end ">
                 <label for="pin" class="is-required">Pin</label>
                 <input class="Inpstyle " type="number" maxlength="9999" minlength="4"  formControlName="pin" min="0"  autocomplete="new-pin"   placeholder="Pin"   >
             </div>
         </div>
         <div class="row d-flex justify-content-between">  
            <div class="col-md-6 align-self-start ">
                <label for="firstName" class="is-required">Nombre</label>
                <input class="Inpstyle " type="text"  formControlName="firstName"   placeholder="Inserta un Nombre"  >
            </div>
            <div class="col-md-6 align-self-end ">
                <label for="lastname" class="is-required">Apellido</label>
                <input class="Inpstyle " type="text"  formControlName="lastName"  placeholder="Apellido"   >
            </div>
        </div>
        <div class="row d-flex justify-content-between">
                
            
            <div class="col-md-6 align-self-start ">
                <label for="password" class="is-required">Contraseña</label>
                <input class="Inpstyle " type="password"  formControlName="password" autocomplete="new-password" #password  placeholder="contraseña"   >

            </div>
            <div class="col-md-6 align-self-end ">
                <label for="pin" class="is-required">Pin</label>
                <input class="Inpstyle " type="number" maxlength="9999" minlength="4"  formControlName="pin" min="0"  autocomplete="new-pin"   placeholder="Pin"   >
            </div>
        </div>
         <div class="row d-flex justify-content-between">
             <div class="col-md-12 align-self-start ">
                 <label for="profileName" class="is-required">Perfil</label>
 
                 <select    
                    formControlName="profiles"  class="Inpstyle">
                    <option disabled selected>Selecciona un perfil</option>
                     
                 </select>
                 <br>
             </div>
 
 
         </div>
         <div class="row d-flex justify-content-between">
                 
             <div class="col-md-12 align-self-start ">
                 <label for="proyectos" class="is-required">Proyectos</label>
                 <ng-select [multiple]="true"  id="projects"  formControlName="projects" placeholder="Seleccione Proyectos">
      
                     
                 </ng-select>
                 
             </div>
             <div class="col-md-12 align-self-end ">
                 <label for="department" class="is-required">Departamento</label>
                 <ng-select [multiple]="true"  id="departments"   formControlName="departments" placeholder="Seleccione Departamentos">
      
                     
                 </ng-select>
               
             </div>
             <div class="col-md-12 align-self-end ">
                 <label for="turno">Grupos de Trabajo</label>
                 <ng-select [multiple]="true"    formControlName="groups" placeholder="Seleccione Grupos de Trabajo">
      
                     
                 </ng-select>
               
             </div>
         </div>
     </div>
    </form>
        
        
 
     <div class="modal-footer">
         <button type="button" class="btn btn-light btn-outline-secondary" >Cancelar</button>
         <button type="button" class="btn btn-primary"  >Guardar</button>
     </div>
 
   </ng-template>
 